<template lang="pug">
div
  .sl-title.my-5 Delete Account
  .body-2 We respect your privacy and don't sell your data. Learn more about our <router-link to="/privacy">privacy policy</router-link> here.
  v-dialog(v-model="dialog", width="500")
    template(v-slot:activator="{ on }")
      v-btn.my-5(
        @click="handleDialog",
        color="error",
        x-small,
        outlined,
        v-on="on"
      ) Delete My Account
    v-card
      v-toolbar(dense, flat, dark, color="error") Delete Account
      form(@submit.prevent="submit")
        v-card-text
          div We've just send a 9-digit code to your email. Please enter the code to verify that you're the account's owner.
          v-text-field.mt-2(v-model="code", label="Code") Code
        v-card-actions
          v-btn(type="submit", color="secondary", :loading="loading", block) Delete My Account
</template>

<script>
export default {
  data() {
    return {
      code: "",
      dialog: false,
      loading: false,
    };
  },
  methods: {
    handleDialog() {
      this.axios.get("/users/generateDeleteCode");
    },
    async submit() {
      if (!this.code) return;
      this.loading = true;
      const params = { code: this.code };
      try {
        await this.axios.post("/users/deleteMyAccount", params);
        this.$auth.logout();
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>