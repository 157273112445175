const state = {
  giftProgram: null
}

const getters = {
  giftProgram: state => state.giftProgram,
}

const actions = {
  setGiftProgram: ({
    commit
  }, data) => {
    commit('setGiftProgram', data)
  }
}

const mutations = {
  setGiftProgram(state, data) {
    state.giftProgram = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}