/// Change the selected city
<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  template(v-slot:activator="{ on }")
    .text-center.py-3
      v-btn.text-capitalize(v-on="on", rounded, outlined, color="secondary") Select a City
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Select a City
    v-card-text
      div(v-for="(cgroup, cindex) in groupedByCountry", :key="cindex")
        .selector-state {{ cgroup.country | country }}
        div(v-for="(sgroup, sindex) in cgroup.states", :key="sindex")
          .selector-state {{ sgroup.state }}
          button.selector-city(
            v-for="(item, index) in sgroup.items",
            :key="index",
            @click="select(item)",
            :class="{ selected: selectedCity && item._id == selectedCity._id }"
          ) {{ item.city }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  filters: {
    country(val) {
      if (val == "US") return "United States";
      if (val == "CA") return "Canada";
      return val;
    },
  },
  computed: {
    ...mapGetters(["cities", "selectedCity"]),
    title() {
      if (this.selectedCity) {
        if (this.selectedCity.line1) return this.selectedCity.line1;
        else return this.selectedCity.city + ", " + this.selectedCity.state;
      } else {
        return "Select";
      }
    },
    groupedByCountry() {
      const result = _.chain(this.cities)
        .groupBy((o) => o.country)
        .map((o, k) => {
          const states = _.chain(o)
            .groupBy("state")
            .map((o, s) => {
              return {
                state: s,
                items: _.sortBy(o, (item) => -item.nbiz),
              };
            })
            .sortBy("country")
            .value();
          return {
            country: k,
            states: states,
          };
        })
        .value();
      return result;
    },
  },
  methods: {
    ...mapActions(["setSelectedCity", "setCities"]),
    select(item) {
      this.setSelectedCity(item);
      EventBus.$emit("set-selected-city");
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
    async load() {
      const res = await this.axios.post("/city/list", {});
      this.setCities(res.data);
      if (!this.selectedCity) {
        this.open();
      }
    },
  },
  mounted() {
    EventBus.$on("SelectCity", this.open);
    this.load();
  },
  destroyed() {
    EventBus.$off("SelectCity");
  },
};
</script>

<style scoped>
.selector-title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}
.selector-state {
  font-weight: 500;
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.selector-city {
  background-color: rgba(28, 31, 52, 0.3);
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}
.selector-city.selected {
  background-color: #FF6D32;
}
</style>
