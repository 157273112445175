<template lang="pug">
.news-body
  v-container
    .title.mt-5 Clients
    p Find our clients in New Mexico, Illinois, Texas, New Jersey, California, Missouri, and Ontario, Canada
    .title.mt-5 Investors
    .d-flex.flex-row.align-center
      v-icon.mr-3(@click="investorEmail()", color="primary") mdi-email
      span Contact us for investment opportunities
    v-btn.my-10(
      :href="flyer",
      target="_blank",
      color="primary",
      dark,
      depressed
    ) Download Flyer (20MB)
</template>

<script>
export default {
  data() {
    return {
      flyer:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/marketing%2FFlyers%2FSelflane%20Flyer.pdf?alt=media&token=13dbd54d-9298-4868-9c7f-69c2b93cfcaa",
    };
  },
  methods: {
    investorEmail() {
      window.location.href =
        "mailto:investors@selflane.com?subject=Investment%20for%20Selflane&body=Your Company and Introduction%0D%0A%0D%0A";
    },
  },
};
</script>

<style scoped>
.news-body {
  padding-top: 80px;
  padding-bottom: 80px;
}
a {
  text-decoration: none;
  font-weight: 500;
}
</style>
