<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  template(v-slot:activator="{ on }")
    v-icon(v-if="roundIcon", v-on="on", @click="add()", :loading="loading") mdi-plus
    v-btn.text-capitalize(
      v-else,
      outlined,
      small,
      v-on="on",
      :loading="loading",
      color="secondary",
      @click="add()"
    ) Add
      v-icon(small) mdi-plus
  v-card
    v-toolbar(dense, flat)
      .overline Add Phone
    v-divider
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          label="Phone Number",
          v-model="number",
          v-mask="\"(###) ###-####\"",
          :error-messages="phoneErrors",
          @blur="$v.number.$touch()",
          autofocus,
          ref="focus"
        )
        v-btn(block, :disabled="$v.$invalid", type="submit", color="secondary") save
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import _ from "underscore";

export default {
  props: ["roundIcon"],
  mixins: [validationMixin],
  validations: {
    number: {
      required,
      minLength: minLength(10),
    },
  },
  data() {
    return {
      number: "",
      loading: false,
      dialog: false,
    };
  },
  computed: {
    phoneErrors() {
      const errors = [];
      if (!this.$v.number.$dirty) return errors;
      !this.$v.number.required && errors.push("A number is required");
      !this.$v.number.minLength && errors.push("At least 10 digits");
      return errors;
    },
  },
  methods: {
    add() {
      this.phone = "";
    },
    submit() {
      const number = this.number.replace(/[^\d]/g, "");
      if (number.length != 10) return;
      let data = {
        number: this.number.replace(/[^\d]/g, ""),
      };
      if (this.phone && this.phone._id) {
        data._id = this.phone._id;
      }
      this.save(data);
    },
    save(phone) {
      this.dialog = false;
      const user = this.$auth.user();
      if (!user) return;
      if (user.phones) {
        const found = _.find(user.phones, (o) => o.number === phone.number);
        if (found) {
          this.setPhone(phone.number);
          return;
        }
      }
      this.loading = true;
      const options = {
        criteria: { _id: user._id },
        action: { $push: { phones: phone } },
      };
      this.axios.post("/users/update", options).then(
        (response) => {
          this.loading = false;
          this.dialog = false;
          this.$auth.user(response.data);
          this.$emit("saved", phone.number);
        },
        (err) => {
          this.loading = false;
          if (err.response) this.error = err.response.data;
        }
      );
    },
  },
};
</script>
