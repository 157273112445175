import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/Home/index.vue'

import BizHome from '@/components/Bizs/Home/index'
import BizMenuSelected from '@/components/Bizs/MenuSelected'
import BizCheckout from '@/components/Bizs/Checkout/index.vue'
import BizReserve from '@/components/Bizs/Reserve/index'
import BizMember from '@/components/Bizs/Member'

import ChainHome from '@/components/Chains/Home/index'
import POIHome from '@/components/POIs/Home'
import CityHome from '@/components/City/Home'

import promotionRoute from '@/components/Promotion/route'
import walletRoute from '@/pages/Wallet/route'
import userRoute from '@/components/User/route'
import userRoute2 from '@/pages/User/route'
import storeGiftCardsRoute from '@/pages/StoreGiftCards/route'

import GiftCards from '@/components/GiftCards/index.vue'

import InfluencerShop from '@/components/InfluencerShop/index.vue'
import eventRoute from '@/components/Event/route'

import companyRoute from '@/components/Company/route'
import redirectRoute from './redirectRoute'

import PubOrder from '@/pages/Pub/Order/index'
import Zest from '@/components/Zest/route'

Vue.use(Router)

const baseRoutes = [{
    path: '/',
    name: 'Home',
    component: Home
}, {
    path: '/bizs/:url',
    component: BizHome,
    props: true,
    children: [{
        path: '',
        component: BizMenuSelected
    }, {
        path: 'checkout',
        component: BizCheckout,
        meta: {
            auth: true
        }
    }, {
        path: 'reserve',
        component: BizReserve,
        meta: {
            auth: true
        }
    }, {
        path: 'member',
        component: BizMember,
    }]
}, {
    path: '/chains/:url',
    component: ChainHome,
    props: true,
}, {
    path: '/poi/:url',
    component: POIHome,
    props: true,
}, {
    path: '/city/:url',
    component: CityHome,
    props: true,
}, {
    path: '/shop/:url',
    component: InfluencerShop,
    props: true,
}, {
    path: '/gift-cards',
    component: GiftCards
}, {
    path: '/od/:id',
    component: PubOrder,
    props: true
}, {
    path: '*',
    redirect: '/'
}]

const routes = baseRoutes.concat(walletRoute, promotionRoute, userRoute, userRoute2, storeGiftCardsRoute, companyRoute, eventRoute, redirectRoute, Zest)

export default new Router({
    mode: 'history',
    linkActiveClass: 'is-active',
    scrollBehavior: () => ({
        y: 0
    }),
    routes: routes
})