<template lang="pug">
div
  v-progress-linear.my-0(indeterminate, v-if="loading")
  v-row(wrap)
    v-col(
      cols="6",
      sm="4",
      md="3",
      lg="2",
      v-for="biz in bizs",
      :key="biz._id"
    )
      Cell(:biz="biz")
</template>

<script>
import Cell from "./Cell/index";

export default {
  props: ["bizs", "loading"],
  components: { Cell },
};
</script>
