<template lang="pug">
v-row(wrap)
  v-col(cols='12' sm='6' md='4' lg='3' v-for='card in cards' :key='card._id')
    Card(:card='card')
</template>

<script>
import Card from "./Card";

export default {
  name: "GiftCardList",
  props: ["cards"],
  components: { Card }
};
</script>