<template lang="pug">
#type-btn(:class="{ selected: selected }", @click="select()")
  .d-flex.flex-row.align-center.justify-center
    v-icon.mr-3(color="#FF6D32", v-if="selected", small) mdi-check-circle
    span {{ name }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["name", "value"],
  computed: {
    ...mapGetters(["cartOrder", "biz"]),
    selected() {
      return this.value && this.cartOrder && this.cartOrder.type == this.value;
    },
  },
  methods: {
    ...mapActions(["prepareCartNeeded"]),
    select() {
      this.cartOrder.type = this.value;
      if (this.value === "delivery") {
        this.cartOrder.deliv = this.biz.orderType.delivery.deliv;
      }
      this.prepareCartNeeded();
    },
  },
};
</script>

<style scoped>
#type-btn {
  border-radius:16px;
  overflow: hidden;
  line-height: 32px;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  background-color: #b5b5b5;
  color: white;
  height: 100%;
}
#type-btn.selected {
  background-color: white;
  color: #FF6D32;
  border: 1px solid #FF6D32;
}
</style>
