import _ from 'underscore'
import moment from 'moment-timezone'

class BizUtils {
  // return the matched day schedule
  // schedule: {weekdays, specialdays}
  // time: moment
  // tz: timezone
  static getDaySchedule(schedule, time, tz) {
    let timezone = tz || moment.tz.guess()
    if (!schedule) return { status: false, schedule: [] }
    if (!time) time = moment().tz(timezone)

    const day = time.day()
    const date = time.format('YYYY/M/D')
    const special = _.find(schedule.specialdays, o => o.date === date)
    if (special) return special
    const weekday = _.find(schedule.weekdays, o => o.date === day)
    return weekday
  }

  /**
   * Return {status, error}
   * @param {*} orderType pickup, delivery
   * @param {*} needed unix time or LLL
   * @param {*} biz 
   * @param {*} Geo Service
   */
  static validateTypeTime(orderType, orderNeeded, biz, geoService) {
    const selectedType = _.propertyOf(biz.orderType)(orderType)
    if (!selectedType || selectedType.status !== true) {
      return {
        status: false,
        error: 'Selected type is not available for online order'
      }
    }
    // It's later than the defined lead time (beforehand)
    const timezone = biz?.address?.timezone || moment.tz.guess()
    const isDelivery = orderType == 'delivery'
    const needed = moment.tz(orderNeeded, timezone)
    let leadtime = biz.orderType?.pickup?.leadtime || 10
    if (isDelivery) leadtime += 20
    const beginGap = isDelivery ? 30 : 10
    const earlistTime = moment().tz(timezone).add(leadtime, 'minutes')
    if (needed.isBefore(earlistTime)) {
      return {
        status: false,
        error: 'Please choose a later time. Earliest time is '
          + earlistTime.format('h:mm A') + '.'
      }
    }
    const result = this.validateScheduleTime(biz.schedule, needed, beginGap)
    if (!result) {
      return {
        status: false,
        error: 'Business is closed at the selected time'
      }
    }
    const neededInMinutes = needed.hour() * 60 + needed.minute()
    if (isDelivery && biz.orderType.delivery && biz.orderType.delivery.deliv) {
      const dayDeliv = this.getDaySchedule(geoService.schedule, needed)
      if (!dayDeliv.status) {
        return {
          status: false,
          error: 'Delivery is closed on the selected date. Please select other options.'
        }
      } else {
        if (!this.validateSchedule(neededInMinutes, dayDeliv.schedule)) {
          return {
            status: false,
            error: 'Delivery is closed at the selected time. Please select another time or options.'
          }
        }
      }
    }

    return {
      status: true,
      error: ''
    }
  }
  // validate both in today and the day before
  // schedule is {weekdays, specialdays}
  // time is moment object
  static validateScheduleTime(schedule, time, beginGap = 0) {
    let minute = time.hour() * 60 + time.minute()
    let day = this.getDaySchedule(schedule, time)
    let validMinute = this.validateSchedule(minute, day.schedule, beginGap)
    if (validMinute && day.status) return true
    day = this.getDaySchedule(schedule, time.subtract(1, 'day'))
    minute += 24 * 60
    validMinute = this.validateSchedule(minute, day.schedule)
    return validMinute && day.status
  }

  // minute is in 0-1440
  // schedule is an array of [{range: [0, 1440]}]
  static validateSchedule(minute, schedule, beginGap = 0) {
    const found = _.find(schedule, o => {
      return (o.range.length == 2 && o.range[0] <= minute - beginGap && o.range[1] >= minute)
    })
    return found !== undefined
  }

  static geoDistance(pos1, pos2) {
    // ref http://www.movable-type.co.uk/scripts/latlong.html
    // use Equirectangular approximation
    const radius = 3959 // earth radius in miles
    const phi1 = toRadius(pos1.lat)
    const phi2 = toRadius(pos2.lat)
    const lamda1 = toRadius(pos1.lng)
    const lamda2 = toRadius(pos2.lng)
    const x = (lamda2 - lamda1) * Math.cos((phi1 + phi2) / 2)
    const y = phi2 - phi1
    const d = Math.sqrt(x * x + y * y) * radius

    function toRadius(input) {
      return input * Math.PI / 180
    }
    return d
  }

  static geoDistance2(pos1, pos2, unit = 'mi.') {
    // ref http://www.movable-type.co.uk/scripts/latlong.html
    // use Equirectangular approximation
    const radius = 6378000 // earth radius in meters
    const phi1 = toRadius(pos1.lat)
    const phi2 = toRadius(pos2.lat)
    const lamda1 = toRadius(pos1.lng)
    const lamda2 = toRadius(pos2.lng)
    const x = (lamda2 - lamda1) * Math.cos((phi1 + phi2) / 2)
    const y = phi2 - phi1
    let d = Math.sqrt(x * x + y * y) * radius

    function toRadius(input) {
      return input * Math.PI / 180
    }

    if (unit == "mi.") {
      d *= 0.000621371
    } else {
      d *= 0.001
    }

    return {
      value: Math.floor(d * 100) / 100,
      unit: unit
    }
  }

  static geoDistanceInMeter(pos1, pos2) {
    // ref http://www.movable-type.co.uk/scripts/latlong.html
    // use Equirectangular approximation
    const radius = 6378000 // earth radius in meters
    const phi1 = toRadius(pos1.lat)
    const phi2 = toRadius(pos2.lat)
    const lamda1 = toRadius(pos1.lng)
    const lamda2 = toRadius(pos2.lng)
    const x = (lamda2 - lamda1) * Math.cos((phi1 + phi2) / 2)
    const y = phi2 - phi1
    let d = Math.sqrt(x * x + y * y) * radius

    function toRadius(input) {
      return input * Math.PI / 180
    }

    return d
  }

  static getInfluencerPlan(plans, influencers, code) {
    let plan = null
    if (!plans || !influencers || !code || !code.length) return {
      influencer: null,
      plan: plan
    }
    const influencer = _.find(
      influencers,
      (o) => o.code.toLowerCase() == code.toLowerCase()
    );
    if (!influencer) {
      return {
        influencer: null,
        plan: plan
      }
    }
    plan = _.find(plans, (o) => o.influencer == influencer._id);
    if (!plan) plan = _.find(plans, (o) => o.is_open);
    return {
      influencer: influencer,
      plan: plan
    }
  }

  static insidePolygon(geo, polygon) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
    if (!geo || !geo.lat || !geo.lng || !polygon || !polygon.length) return false

    const x = geo.lat;
    const y = geo.lng;

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let xi = polygon[i].lat,
        yi = polygon[i].lng;
      let xj = polygon[j].lat,
        yj = polygon[j].lng;

      let intersect = ((yi > y) != (yj > y)) &&
        (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  }
}

export default BizUtils