<template lang="pug">
div
  v-row.sticky(dense, wrap)
    v-col(cols="6", sm="4", v-for="(item, index) in type_items", :key="index")
      TypeButton(:name="item.name", :value="item.value")
  #type-time-body
    Label(icon="mdi-clock", title="Time (approximate)")
    Time
    .text--secondary.body-2
      Schedule(:time="cartOrder.needed")
      div(v-if="leadtime")
        span {{ leadtime | leadtime }} lead time.
      .success--text(v-if="isCurbside") {{ biz.orderType.curbside.note }}
</template>

<script>
import { mapGetters } from "vuex";
import TypeButton from "./TypeButton";
import Time from "./Time";
import Schedule from "./Schedule";

export default {
  components: { TypeButton, Time, Schedule },
  computed: {
    ...mapGetters(["cartOrder", "biz"]),
    type_items() {
      let list = [];
      const data = this.biz?.orderType;
      if (!data) return list;
      if (data.pickup && data.pickup.status) {
        list.push({ name: "Pick up", value: "pickup" });
      }
      if (data.curbside && data.curbside.status) {
        list.push({ name: "Curbside", value: "curbside" });
      }
      if (data.delivery && data.delivery.status && !data.delivery.deliv) {
        list.push({ name: "Delivery", value: "delivery" });
      }
      return list;
    },
    isDelivery() {
      return this.cartOrder.type == "delivery";
    },
    isCurbside() {
      return this.cartOrder.type == "curbside";
    },
    leadtime() {
      let leadtime = this.biz?.orderType?.pickup?.leadtime || 10;
      if (this.isDelivery) return leadtime + 20;
      return leadtime;
    },
  },
};
</script>

<style scoped>
#type-time-body {
  background-color: white;
  border-radius: 12px;
  padding: 6px 12px;
  margin-top: 12px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 204px;
  z-index: 3;
  background: #F0F2F5;
  display: flex;
}
</style>
