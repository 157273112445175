// Show the associated chain for the business.
<template lang="pug">
v-dialog(v-model="dialog", width="450")
  v-card(v-if="chain", outlined)
    Head(:chain="chain")
    v-card-text We have multiple locations. Please select the closest for your order.
    v-list.py-0(dense)
      div(
        v-for="(item, index) in bizs",
        :key="item._id",
        :biz="item",
        :index="index"
      )
        v-divider(v-if="index != 0")
        Cell(:item="item", :distance="item.distance", @select="dialog = false")
</template>

<script>
import { mapGetters } from "vuex";
import Head from "./Head";
import Cell from "./Cell";
import _ from "underscore";
import BizUtils from "@/utils/biz.js";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Head, Cell },
  data() {
    return { dialog: false, chain: null, bizs: [], lastChainId: null };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    open(val) {
      if (!val) return;
      this.chain = val;
      this.dialog = true;
      if (this.lastChainId != this.chain._id) this.loadBizs();
    },
    async loadBizs() {
      if (!this.chain) return;
      this.lastChainId = this.chain._id;
      const params = {
        criteria: {
          _id: { $in: this.chain.bizs },
          "status.byAdmin": 1,
          "status.byUser": 1,
        },
        select: "name short_name address url status",
      };
      const res = await this.axios.post("/bizs/list", params);
      this.bizs = res.data;
      this.sortDistance();
    },
    sortDistance() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.bizs = _.chain(this.bizs)
          .map((item) => {
            const distance = BizUtils.geoDistanceInMeter(
              item.address.geometry,
              { lat: position.coords.latitude, lng: position.coords.longitude }
            );
            item.distance = distance;
            return item;
          })
          .sortBy("distance")
          .value();
      });
    },
  },
  mounted() {
    EventBus.$on("show-chain-dialog", this.open);
  },
  destroyed() {
    EventBus.$off("show-chain-dialog");
  },
};
</script>
