<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Send Gift Cards
        v-spacer
        Balance(:gfbalance="gfbalance")
      v-divider
      v-card-text
        .subtitle-2 Recipients
        v-simple-table(dense)
          thead
            tr
              th(style="width: 130px") Name
              th Email
              th(style="width: 100px") Value
          tbody
            tr(v-for="(item, index) in recipients", :key="index")
              td.pl-0
                v-text-field(v-model="item.name", single-line, hide-details)
              td.pl-0
                v-text-field(v-model="item.email", single-line, hide-details)
              td.px-0
                v-text-field(
                  v-model.number="item.value",
                  prefix="$",
                  single-line,
                  hide-details
                )
            tr.subtitle-2
              td Total: {{ count }}
              td
              td {{ total | currency }}
        v-text-field(label="Sender", v-model="sender")
        v-textarea.mt-5(
          label="Message",
          auto-grow,
          rows=3,
          v-model="message",
          outlined
        )
      v-card-actions
        v-col
          .pl-2.red--text(
            v-for="(error, index) in errors",
            :key="index + 'index'"
          ) {{ error }}
          v-btn(color="secondary", block, :loading="loading", type="submit") Send Gift Card
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Balance from "./Balance";
import _ from "underscore";

export default {
  components: { Balance },
  data() {
    return {
      dialog: false,
      loading: false,
      gfbalance: null,
      recipients: [],
      sender: "",
      message: "",
      currency: "usd",
      errors: [],
    };
  },
  computed: {
    count() {
      return this.recipients.length;
    },
    total() {
      return _.reduce(
        this.recipients,
        (memo, item) => {
          return memo + item.value;
        },
        0
      );
    },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.gfbalance = data;
      this.recipients = [
        {
          name: "",
          email: "",
          value: 10,
        },
      ];
      this.errors = [];
    },
    async submit() {
      /// validate
      const total = _.reduce(
        this.recipients,
        (memo, item) => {
          return memo + item.value * 100;
        },
        0
      );
      if (this.currency == "usd") {
        if (total > this.gfbalance.balance_usd) {
          this.errors = ["Not enough balance"];
          return;
        }
      } else if (this.currency == "cad") {
        if (total > this.gfbalance.balance_cad) {
          this.errors = ["Not enough balance"];
          return;
        }
      } else {
        this.errors = ["Invalid currency"];
        return;
      }
      this.loading = true;

      const params = {
        sender: this.sender,
        message: this.message,
        recipients: this.recipients,
        currency: this.currency,
      };
      try {
        await this.axios.post("/gfbalance/transfer/create", params);
        this.dialog = false;
      } catch (err) {
        this.errors = [err.response.data];
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("show-gfbalance-transfer", this.open);
  },
  destroyed() {
    EventBus.$off("show-gfbalance-transfer");
  },
};
</script>