<template lang="pug">
v-btn(@click="download()", color="secondary", outlined, rounded, small)
  span PDF
  v-icon(right, small) mdi-printer
</template>

<script>
import jsPDF from "jspdf";
import _ from "underscore";

export default {
  props: ["event", "ticket", "time_items", "ticket_item"],
  methods: {
    download() {
      if (!this.event || !this.ticket || !this.time_items) return;
      const currency = this.$options.filters.currency;
      const address = this.$options.filters.address;

      let doc = new jsPDF({ format: "letter" });
      let x = 10;
      let y = 13;
      let pageWidth = doc.internal.pageSize.width;

      // add title
      doc.setFontSize(12);
      doc.text("Event Ticket: " + this.event.title, x, y);
      nextLine(5);
      addLine(15);

      doc.text("Ticket Number: " + this.ticket.ticket_number, x, y);
      doc.setFontSize(10);
      nextLine(10);
      doc.text(this.ticket.user.name, x, y);
      nextLine(5);
      doc.text(
        this.ticket_item.name + " " + currency(this.ticket_item.price),
        x,
        y
      );
      nextLine(15);

      doc.setFontSize(12);
      doc.text("Date and Time", x, y);
      doc.setFontSize(10);
      nextLine(10);
      _.each(this.time_items, (o) => {
        doc.text(o, x, y);
        nextLine(5);
      });
      nextLine(10);

      doc.setFontSize(12);
      doc.text("Location", x, y);
      doc.setFontSize(10);
      nextLine(10);
      doc.text(this.event.address.name, x, y);
      nextLine(5);
      doc.text(address(this.event.address), x, y);
      nextLine(15);

      doc.setFontSize(12);
      doc.text("Purchased", x, y);
      doc.setFontSize(10);
      nextLine(10);
      doc.text(this.$options.filters.datetime(this.ticket.created), x, y);

      doc.save("event_ticket.pdf");

      function addLine(height) {
        doc.line(0, y, pageWidth, y);
        nextLine(height);
      }
      function nextLine(height) {
        if (height) y += height;
        else y += 10;
        x = 10;
      }
    },
  },
};
</script>
