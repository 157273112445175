<template lang="pug">
img(:src="src", v-if="src")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz"]),
    src() {
      const logo = this.biz?.logo;
      if (logo) return process.env.VUE_APP_MEDIAHOST + logo;
      return null;
    },
  },
};
</script>

<style scoped>
img {
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  border-radius: 12px;
  overflow: hidden;
  object-fit: cover;
  margin-right: 12px;
}
</style>
