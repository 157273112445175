<template lang="pug">
div
  v-row(wrap)
    v-col(
      cols="6",
      sm="4",
      md="3",
      lg="2",
      v-for="(item, index) in promotions",
      :key="index"
    )
      CouponCell(
        :promotion="item",
        :claims="claims",
        :bizs="bizs",
        :chains="chains",
        @claimed="loadClaims"
      )
  .caption Coupons are one time discounts issued by restaurants. They usually come with a spending requirement and an expiry date.
</template>

<script>
import _ from "underscore";
import CouponCell from "./CouponCell/index";

export default {
  components: { CouponCell },
  data() {
    return {
      loading: false,
      promotions: [],
      claims: [],
      bizs: [],
      chains: [],
    };
  },
  computed: {
    chainData() {
      return _.map(this.chains, (chain) => {
        return {
          chain: chain,
          promotions: _.filter(this.promotions, (o) => o.chain == chain._id),
        };
      });
    },
    bizData() {
      return _.map(this.bizs, (biz) => {
        return {
          biz: biz,
          promotions: _.filter(this.promotions, (o) => o.biz == biz._id),
        };
      });
    },
  },
  methods: {
    async loadChains() {
      const ids = _.pluck(this.promotions, "chain");
      this.chains = [];
      if (!ids || ids.length == 0) return;
      const params = { criteria: { _id: { $in: ids } }, select: "name logo" };
      const res = await this.axios.post("/chain/list", params);
      this.chains = res.data;
    },
    async loadBizs() {
      const bizIds = _.pluck(this.promotions, "biz");
      this.bizs = [];
      if (!bizIds || bizIds.length == 0) return;
      const params = {
        criteria: { _id: { $in: bizIds } },
        select: "name address logo",
      };
      const res = await this.axios.post("/bizs/list", params);
      this.bizs = res.data;
    },
    async load() {
      const now = Date.now();
      const params = { criteria: { expiry: { $gt: now } } };
      const res = await this.axios.post("/promotion/list", params);
      this.loading = false;
      this.promotions = res.data;
      this.loadBizs();
      this.loadChains();
      this.loadClaims();
    },
    async loadClaims() {
      this.claims = [];
      if (!this.$auth.check()) return;
      if (!this.promotions) return;
      const ids = _.pluck(this.promotions, "_id");
      const params = {
        criteria: { promotion: { $in: ids }, user: this.$auth.user()._id },
        select: "promotion applied",
      };
      const res = await this.axios.post("/promoclaim/list", params);
      this.claims = res.data;
    },
  },
  mounted() {
    this.load();
  },
};
</script>