import Main from './index.vue'
import Member from './member.vue'
import Gift from './Gift/index.vue'
import Coupon from './Coupon/index.vue'
import Event from './Event/index.vue'
import Unsubscribe from './Unsubscribe/index.vue'

export default [{
    path: '/user/wallet',
    component: Main,
    meta: {
        auth: true
    },
    children: [{
        path: '',
        redirect: 'member'
    }, {
        path: 'member',
        component: Member
    }, {
        path: 'gift',
        component: Gift
    }, {
        path: 'coupon',
        component: Coupon
    }, {
        path: 'event',
        component: Event
    }]
}, {
    path: '/unsubscribe/member/:member_id',
    component: Unsubscribe,
    props: true
}]