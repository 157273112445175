<template lang="pug">
v-btn(color="primary", text, block, @click="handler()", :loading="loading") Order This Again
</template>

<script>
import _ from "underscore";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["order"],
  data() {
    return {
      loading: false,
      modifiers: [],
    };
  },
  computed: { ...mapGetters(["cartUrl"]) },
  methods: {
    ...mapActions(["addDishToCart"]),
    async handler() {
      if (!this.order || !this.order.seller || !this.order.seller.id) return;
      const bizId = this.order.seller.id;
      // load modifiers
      const resMod = await this.axios.post("/bizModifiers/list", {
        criteria: { bizId: bizId, "modifier.ol": { $ne: false } },
      });
      this.modifiers = _.chain(resMod.data)
        .pluck("modifier")
        .each((o) => {
          o.choices = _.reject(o.choices, (c) => c.ol === false);
        })
        .reject((o) => !o.choices || o.choices.length === 0)
        .value();
      try {
        // load menu
        const res = await this.axios.post("/bizs/retrieve", {
          criteria: { _id: bizId },
        });
        this.reorder(res.data);
      } catch (err) {
        if (err && err.response) alert(err.response.data);
      }
    },
    reorder(biz) {
      const unitPrice = this.$options.filters.unitPrice;
      if (!this.order || !biz) return;
      _.each(this.order.dishes, (dish) => {
        // ensure dish still exist
        const found = _.find(biz.dishes, (o) => o._id === dish._id);
        if (found) {
          const unavailable = found.ooo || found.hide;
          if (!unavailable) {
            let clone = JSON.parse(JSON.stringify(found));
            clone.quantity = dish.quantity;
            this.matchModifiers(clone, dish);
            unitPrice(clone);
            this.addDishToCart({ biz: biz, dish: clone });
          }
        }
      });
      this.dialog = false;
      this.$router.push(this.cartUrl);
    },
    /**
     * Match selected choices with the current modifier setup and prices
     */
    matchModifiers(dish_prinstine, dish_used) {
      dish_prinstine.modifiers = [];
      const usedChoices = _.chain(dish_used.modifiers)
        .pluck("choices")
        .flatten()
        .value();
      const usedSubChoices = _.chain(dish_used.modifiers)
        .pluck("associatedChoices")
        .flatten()
        .value();
      _.each(dish_prinstine.modifierIDs, (id) => {
        let found = _.find(this.modifiers, (o) => o._id === id);
        if (found && found.choices) {
          found = JSON.parse(JSON.stringify(found));
          _.each(found.choices, (choice) => {
            const matched_choice = _.find(usedChoices, (used_choice) => {
              return used_choice.name == choice.name;
            });
            if (matched_choice) {
              choice.selected = matched_choice.selected;
            }
          });
          _.each(found.associatedChoices, (subchoice) => {
            const matched_choice = _.find(usedSubChoices, (used_choice) => {
              return used_choice.name == subchoice.name;
            });
            if (matched_choice) {
              subchoice.selected = matched_choice.selected;
              subchoice.halfhalf = matched_choice.halfhalf;
              subchoice.quantity = matched_choice.quantity;
            }
          });
          // ensure at least one choice is selected in required modifiers
          if (found.required) {
            const firstSelected = _.find(found.choices, (o) => {
              return o.selected;
            });
            if (!firstSelected && found.choices && found.choices.length) {
              found.choices[0].selected = true;
            }
          }
          dish_prinstine.modifiers.push(found);
        }
      });
    },
  },
};
</script>
