<template lang="pug">
.favor(@click.stop="toggleLike()")
  v-icon(:color="favColor", small) mdi-heart
  span.favor-count {{ biz.stats.fav }}
</template>

<script>
export default {
  name: "BizCellFavor",
  props: ["biz"],
  computed: {
    isFavored() {
      if (!this.$auth.check()) return false;
      const preference = this.$auth.user().preference;
      return preference.myfavs.indexOf(this.biz._id) !== -1;
    },
    favColor() {
      if (this.isFavored) return "red";
      return "white";
    },
  },
  methods: {
    toggleLike() {
      if (!this.$auth.check()) {
        this.$router.push("/login");
        return;
      }
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      let bizData = {
        criteria: { _id: bizId },
        action: { $inc: { "stats.fav": 1 } },
      };
      let userData = {
        criteria: { _id: userId },
        action: { $addToSet: { "preference.myfavs": bizId } },
      };
      if (this.isFavored) {
        bizData.action = { $inc: { "stats.fav": -1 } };
        userData.action = { $pull: { "preference.myfavs": bizId } };
      }
      this.updateBiz(bizData);
      this.updateUser(userData);
    },
    async updateBiz(params) {
      const res = await this.axios.post("/bizs/update", params);
      // eslint-disable-next-line vue/no-mutating-props
      this.biz.stats = res.data.stats;
    },
    async updateUser(params) {
      const res = await await this.axios.post("/users/update", params);
      this.$auth.user(res.data);
    },
  },
};
</script>

<style scoped>
.favor {
  min-width: 42px;
  background: #FF6D32;
  border-radius: 0 15px 15px 0;
  cursor: pointer;
  position: absolute;
  top: 0px;
  padding: 1px 10px 1px 6px;
}
.favor-count {
  margin-left: 3px;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
}
</style>
