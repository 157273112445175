<template lang="pug">
div
  Items
  Coupon
  GiftPayments
  CartTypeTime.my-3(v-if="hasDish")
  NamePhoneAddress.my-3
  Info
  PaymentBlock.mb-10
  .red--text(v-for="(item, index) in errors", :key="index") {{ item }}
  .checkout-bottom.my-3
    CartTotal
    v-btn.checkout-button(
      @click="handleSubmit()",
      :disabled="loading",
      :loading="loading",
      depressed,
      tile,
      color="primary",
      height="100%"
    ) SUBMIT
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import Items from "./Items/index";
import PaymentBlock from "./PaymentBlock/index";
import CartTypeTime from "./CartTypeTime";
import NamePhoneAddress from "./NamePhoneAddress/index";
import GiftPayments from "./GiftPayments";
import Coupon from "./Coupon/index";
import CartTotal from "./CartTotal";
import Info from "./Info";
import { EventBus } from "@/event-bus.js";

export default {
  components: {
    Items,
    PaymentBlock,
    CartTypeTime,
    NamePhoneAddress,
    GiftPayments,
    CartTotal,
    Coupon,
    Info,
  },
  data() {
    return {
      loading: false,
      errors: [],
      finishedOrder: false,
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fcheckout_shoppingcart_icon.svg?alt=media&token=abd99d75-093c-4cb1-b91a-79c5de61bdf0",
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartUrl", "biz", "deliv"]),
    cartDue() {
      if (!this.cartOrder || !this.cartOrder.payment) return 0;
      const payment = this.cartOrder.payment;
      const paid = _.reduce(
        payment.charges,
        (memo, item) => {
          return memo + item.amount;
        },
        0
      );
      return Math.round((payment.total - paid) * 100) / 100;
    },
    hasDish() {
      return (
        this.cartOrder &&
        this.cartOrder.dishes &&
        this.cartOrder.dishes.length > 0
      );
    },
    isDelivery() {
      return this.cartOrder && this.cartOrder.type === "delivery";
    },
  },
  methods: {
    ...mapActions(["prepareCheckout", "submitCart", "setCard", "setDeliv"]),
    handleSubmit() {
      if (this.cartDue === 0) {
        this.setCard(null);
      }
      if (this.isDelivery) {
        this.cartOrder.deliv = this.biz.orderType.delivery.deliv;
      }
      this.saveName();
      this.submit();
    },
    async submit() {
      this.loading = true;
      this.errors = [];
      try {
        await this.submitCart();
        EventBus.$emit("show-order-submit-success");
      } catch (err) {
        this.errors = [err];
      }
      this.loading = false;
    },
    async loadDelivPartner() {
      this.setDeliv(null);
      if (
        this.biz &&
        this.biz.orderType &&
        this.biz.orderType.delivery &&
        this.biz.orderType.delivery.status &&
        this.biz.orderType.delivery.deliv
      ) {
        const params = {
          criteria: { _id: this.biz.orderType.delivery.deliv },
          select: "name",
        };
        const res = await this.axios.post("/deliv/retrieve", params);
        if (res.data) this.setDeliv(res.data);
      }
    },
    async saveName() {
      // save preferred name if not existing yet
      const user = this.$auth.user();
      if (!user) return;
      // skip if exist
      if (user.name && user.name.preferred) return;
      // skip if not provided in the current order
      if (
        !this.cartOrder ||
        !this.cartOrder.customer ||
        !this.cartOrder.customer.name
      )
        return;
      const data = {
        criteria: { _id: user._id },
        action: { $set: { "name.preferred": this.cartOrder.customer.name } },
      };
      const res = await this.axios.post("/users/update", data);
      this.$auth.user(res.data);
    },
  },
  mounted() {
    // setup initial variables
    const user = this.$auth.user();
    if (user) {
      this.prepareCheckout(user);
      this.loadDelivPartner();
    }
  },
};
</script>

<style scoped>
.checkout-rounded-container {
  background-color: white;
  border-radius: 12px;
  padding: 12px;
}
.checkout-content {
  border: 2px solid #FF6D32;
  border-radius: 10px 10px 0 0;
  background: rgba(28, 31, 52, 0);
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.checkout-right {
  background: #fffbf6;
}
.checkout-bottom {
  display: flex;
  border-radius: 29px;
  overflow: hidden;
  font-size: 17px;
  font-weight: 500;
  color: #fdf9f4;
  height: 58px;
}
.checkout-button {
  flex: 1 0;
}
</style>
