function orderType(type) {
  switch (type) {
    case 'pickup':
      return 'Pick Up'
    case 'curbside':
      return 'Curbside'
    case 'delivery':
      return 'Delivery'
    case 'dinein':
      return 'Dine In'
    case 'gc':
      return 'gift card'
    default:
      return type
  }
}

export default orderType