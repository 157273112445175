<template lang="pug">
.bottom-left
  .saved-text(v-if="cartOrder.payment.deduction.total > 0") Saved: -{{ cartOrder.payment.deduction.total | currency }}
  div Total: {{ cartOrder.payment.total | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["cartOrder"]),
  },
};
</script>

<style scoped>
.bottom-left {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1c1f34;
}
.saved-text {
  font-size: 14px;
  font-weight: 400;
}
</style>
