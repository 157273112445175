<template lang="pug">
v-snackbar(v-model="snackbar", :color="color", top)
  span {{ text }}
  v-btn(text, @click="snackbar = false") Close
</template>
<script>
import { EventBus } from "./../../event-bus.js";

export default {
  data() {
    return {
      snackbar: false,
      text: "",
      color: "",
    };
  },
  methods: {
    load(text, color) {
      this.text = text;
      this.color = color;
      this.snackbar = true;
    },
  },
  mounted() {
    EventBus.$on("globalSnackbar", this.load);
  },
};
</script>
