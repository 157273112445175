import _ from 'underscore'

const state = {
  rewards: []
}

const getters = {
  rewards: state => state.rewards
}

const actions = {
  setRewards: ({
    commit
  }, rewards) => {
    commit('setRewards', rewards)
  },
  updateReward: ({
    commit
  }, reward) => {
    commit('updateReward', reward)
  },
  addReward: ({
    commit
  }, reward) => {
    commit('addReward', reward)
  }
}

const mutations = {
  setRewards(state, rewards) {
    state.rewards = rewards
  },
  updateReward(state, reward) {
    state.rewards = _.map(state.rewards, o => {
      if (o._id === reward._id) return reward
      return o
    })
  },
  addReward(state, reward) {
    state.rewards.unshift(reward)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}