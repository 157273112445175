<template lang="pug">
v-card(flat)
  v-card-text
    Head(:order="order")
    Customer(:order="order")
    Footer(:order="order")
</template>

<script>
import Head from "./Head";
import Customer from "./Customer";
import Footer from "./Footer/index";

export default {
  props: ["order"],
  components: { Head, Customer, Footer },
};
</script>
