<template lang="pug">
div
  Label(icon="mdi-cellphone", title="Phone")
  .d-flex.flex-row.align-center.justify-space-between
    v-menu
      template(v-slot:activator="{ on }")
        .d-flex.flex-row.align-center.mr-5.my-1(v-on="on", role="button")
          .font-weight-medium(v-if="cartOrder.customer.phone") {{ cartOrder.customer.phone | phone }}
          span(v-else) Select
          v-icon(right) mdi-menu-down
      v-card
        v-list.py-0(v-if="$auth.user().phones && $auth.user().phones.length")
          v-list-item(
            v-for="(item, index) in $auth.user().phones",
            :key="index",
            @click="setPhone(item.number)"
          )
            v-list-item-content {{ item.number | phone }}
            v-list-item-action
              v-icon(
                color="success",
                v-if="item.number == cartOrder.customer.phone"
              ) mdi-check
        v-card-text(v-else)
          CreatePhone(@saved="setPhone")
    CreatePhone(:roundIcon="true", @saved="setPhone")
</template>

<script>
import CreatePhone from "@/components/User/Profile/CreatePhone";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartPhone",
  components: { CreatePhone },
  computed: {
    ...mapGetters(["cartOrder"]),
  },
  methods: {
    ...mapActions(["setPhone"]),
  },
};
</script>
