<template lang="pug">
div
  v-container
    a.event-title(@click="gohome") Events
  router-view
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.path == "/event";
    },
  },
  methods: {
    gohome() {
      if (this.isHome) return;
      this.$router.push("/event");
    },
  },
};
</script>

<style scoped>
.event-title {
  font-size: 28px;
}
</style>