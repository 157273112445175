<template lang="pug">
div
  v-row(wrap)
    v-col(
      cols="12",
      sm="6",
      md="4",
      lg="3",
      v-for="(member, index) in memberAccounts",
      :key="index"
    )
      Card(:member="member")
  EmptyView(v-if="isEmpty", text="No Membership Yet")
</template>

<script>
import Card from "./Card";
import { mapGetters } from "vuex";

export default {
  components: { Card },
  computed: {
    ...mapGetters(["memberAccounts"]),
    isEmpty() {
      return !this.memberAccounts || this.memberAccounts.length == 0;
    },
  },
};
</script>