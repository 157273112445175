import _ from 'underscore'

const state = {
  selectedCountry: '',
  cities: [],
  recentAddresses: [],
  selectedCity: null
}

const getters = {
  selectedCountry: state => state.selectedCountry,
  cities: state => state.cities,
  recentAddresses: state => state.recentAddresses,
  selectedCity: state => state.selectedCity
}

const actions = {
  setCities: ({
    commit
  }, data) => {
    commit('setCities', data)
  },
  setSelectedCity: ({
    commit
  }, data) => {
    commit('setSelectedCity', data)
  },
  setSelectedCountry: ({
    commit
  }, data) => {
    commit('setSelectedCountry', data)
  },
  addRecentAddress: ({
    commit
  }, data) => {
    commit('addRecentAddress', data)
  }
}

const mutations = {
  setCities(state, data) {
    state.cities = data
  },
  setSelectedCity(state, data) {
    state.selectedCity = data
    this.dispatch('addRecentAddress', data)
    if (data.country) {
      state.selectedCountry = data.country
    }
  },
  setSelectedCountry(state, data) {
    state.selectedCountry = data
  },
  addRecentAddress(state, data) {
    const found = _.find(state.recentAddresses, o => {
      return o.line1 == data.line1 && o.city == data.city
    })
    if (!found) {
      state.recentAddresses.unshift(data)
      state.recentAddresses = state.recentAddresses.slice(0, 3)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}