<template lang="pug">
.items
  // dishes
  .item-row(
    v-for="(dish, index) in cartOrder.dishes",
    :key="index + 'dish'",
    @click="edit(dish, index)"
  )
    .name-block
      span {{ dish.name }}
        span.ml-3.red--text(v-if="dish.pctOff > 0") {{ dish.pctOff }}% off {{ (-dish.pctOff * dish.unitPrice * dish.quantity) / 100 | currency }}
      .sl-secondary-text {{ dish.modifiers | modifiers }}
    .quantity &#215;{{ dish.quantity }}
    .price {{ dish.unitPrice | currency }}
    v-btn(icon, small, @click.stop="removeDishAt(index)")
      v-icon(small) mdi-delete
  // gift cards
  .item-row(v-for="(gift, index) in cartOrder.gifts", :key="index + 'gift'")
    .name-block
      span eGift Card
    .price {{ gift.amount | currency }}
    v-btn(icon, small, @click.stop="removeGiftAt(index)")
      v-icon(small) mdi-delete
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  computed: {
    ...mapGetters(["cartOrder", "cartPromotion"]),
  },
  methods: {
    ...mapActions(["removeDishAt", "removeGiftAt"]),
    edit(dish, index) {
      const editDish = JSON.parse(JSON.stringify(dish));
      const editDishID = index;
      EventBus.$emit("show-dish", editDish, editDishID);
    },
  },
};
</script>

<style scoped>
.items {
  font-size: 15px;
}
.item-row {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}
.name-block {
  flex-grow: 1;
  margin-right: 8px;
}
.price {
  font-weight: 500;
}
.quantity {
  margin-right: 8px;
}
</style>