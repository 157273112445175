<template lang="pug">
#button-container.d-flex.flex-row
  Like
  v-btn.ml-2.text-capitalize(
    rounded,
    depressed,
    small,
    color="#E4E6EB",
    @click="showChain()",
    v-if="chain"
  )
    v-icon(small, left) mdi-store
    span Locations
  Reserve.ml-2
  Member.ml-2
  Share.ml-2
</template>

<script>
import Like from "./Like";
import Reserve from "./Reserve";
import Member from "./Member";
import Share from "./Share";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Like, Reserve, Member, Share },
  computed: { ...mapGetters(["chain"]) },
  methods: {
    showChain() {
      EventBus.$emit("show-chain-dialog", this.chain);
    },
  },
};
</script>

<style scoped>
#button-container {
  overflow-x: scroll;
}
</style>
