import _ from 'underscore'

const state = {
  pricings: []
}

const getters = {
  pricings: state => state.pricings
}

const actions = {
  setPricings: ({
    commit
  }, pricings) => {
    commit('setPricings', pricings)
  },
  updatePricing: ({
    commit
  }, pricing) => {
    commit('updatePricing', pricing)
  },
  addPricing: ({
    commit
  }, pricing) => {
    commit('addPricing', pricing)
  },
  removePricing: ({
    commit
  }, pricing) => {
    commit('removePricing', pricing)
  }
}

const mutations = {
  setPricings(state, pricings) {
    state.pricings = pricings
  },
  updatePricing(state, pricing) {
    state.pricings = _.map(state.pricings, o => {
      if (o._id === pricing._id) return pricing
      return o
    })
  },
  addPricing(state, pricing) {
    state.pricings.push(pricing)
  },
  removePricing(state, pricing) {
    state.pricings = _.reject(state.pricings, o => {
      return (o._id === pricing._id)
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}