<template lang="pug">
v-dialog(width="220", v-model="dialog")
  .sl-plain-card(v-if="qrcode")
    .text-center
      .sl-title {{ title }}
      .sl-dash-divider.mb-3
      QrcodeVue(:value="qrcode", size="180", level="H")
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { EventBus } from "@/event-bus.js";

export default {
  components: { QrcodeVue },
  data() {
    return {
      title: "",
      dialog: false,
      qrcode: null,
    };
  },
  methods: {
    show(qrcode, title) {
      this.qrcode = qrcode;
      this.title = title;
      this.dialog = true;
    },
  },
  mounted() {
    EventBus.$on("show-qrcode", this.show);
  },
  destroyed() {
    EventBus.$off("show-qrcode");
  },
};
</script>
