// Map of chain businesses. Dynamically load selected location

<template lang="pug">
#map(ref="map")
</template>

<script>
import _ from "underscore";

export default {
  props: ["items", "selected"],
  data() {
    return {
      selectedMarker: null,
      markers: [],
    };
  },
  watch: {
    selected: {
      handler(val, oldVal) {
        // skip same business
        if (val && oldVal && val._id == oldVal._id) return;
        this.draw();
      },
      deep: true,
    },
  },
  methods: {
    initMap() {
      // init center is Albuquerque, NM
      const center = { lat: 35.0844, lng: -106.6504 };
      // only allow zoom control in the map
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 12,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      });
    },
    draw() {
      if (!this.selected) return;
      // reset map markers
      if (this.selectedMarker) {
        this.selectedMarker.setMap(null);
      }
      _.each(this.markers, (o) => o.setMap(null));
      this.markers = [];
      // draw selected marker
      const selected_icon =
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fmap-marker-check.png?alt=media&token=71667548-600a-45d7-900f-99cf7bcee059";
      if (
        this.selected &&
        this.selected.address &&
        this.selected.address.geometry
      ) {
        // eslint-disable-next-line no-undef
        this.selectedMarker = new google.maps.Marker({
          position: this.selected.address.geometry,
          icon: selected_icon,
          clickable: false,
        });
        this.selectedMarker.setMap(this.map);
        this.map.panTo(this.selectedMarker.getPosition());
      }
      // draw other locations
      const icon =
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fmap-marker.png?alt=media&token=9061e17f-3234-45b8-9311-e99f7247d63d";
      _.each(this.items, (o) => {
        // skip the selected item
        if (this.selected && this.selected._id == o._id) return;
        if (o.address && o.address.geometry) {
          // eslint-disable-next-line no-undef
          const marker = new google.maps.Marker({
            position: o.address.geometry,
            icon: icon,
            clickable: false,
          });
          this.markers.push(marker);
        }
      });
      _.each(this.markers, (o) => o.setMap(this.map));
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>

<style scoped>
#map {
  min-height: 600px;
  width: 100%;
  border-radius: 6px;
  background: gray;
}
</style>
