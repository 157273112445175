// Chain home page

<template lang="pug">
div(v-if="chain")
  vue-headful(:title="title", :keywords="keywords")
  v-img(
    :src="parallax",
    width="100%",
    max-height="500",
    cover,
    v-if="parallax"
  )
  v-container(fluid)
    v-row.my-3(dense, wrap)
      v-col(cols="12", sm="3")
        Logo(:logo="chain.logo")
      v-col(cols="12", sm="9")
        h1.title {{ chain.name }}
        .mt-3 {{ chain.shortd }}
    v-row(dense, wrap)
      v-col(cols="12", sm="4", md="3")
        List(:groups="groups", @hover="hoverItem")
      v-col.hidden-xs-only(cols="12", sm="8", md="9")
        Map(:items="bizs", :selected="selected")
</template>

<script>
import _ from "underscore";
import BizUtils from "@/utils/biz.js";
import Logo from "./Logo";
import List from "./List";
import Map from "./Map";

export default {
  components: { Logo, List, Map },
  props: ["url"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      chain: null,
      bizs: [],
      loading: false,
      selected: null,
    };
  },
  computed: {
    title() {
      if (this.chain) return this.chain.name + " | Selflane";
      return "Selflane";
    },
    keywords() {
      if (this.chain) {
        return (
          this.chain.name +
          ",Selflane,Restaurants,Online Food,Food Delivery,Online Reservation,Point of Sale"
        );
      }
      return "Selflane,Restaurants,Online Food,Food Delivery,Online Reservation,Point of Sale";
    },
    // Group business by state and city
    // Sort by distance
    groups() {
      return _.chain(this.bizs)
        .sortBy((o) => o.distance)
        .groupBy((o) => o.address.state)
        .map((o, k) => {
          const cities = _.chain(o)
            .groupBy((item) => item.address.city)
            .map((items, city) => {
              return {
                city: city,
                items: items,
              };
            })
            .value();
          return {
            state: k,
            cities: cities,
          };
        })
        .value();
    },
    parallax() {
      if (this.chain && this.chain.parallax && this.chain.parallax.length) {
        return this.mediaHost + this.chain.parallax;
      }
      return null;
    },
  },
  watch: {
    url(val, oldVal) {
      if (val && val != oldVal) {
        this.load();
      }
    },
  },
  methods: {
    hoverItem(item) {
      this.selected = item;
    },
    async load() {
      this.chain = null;
      this.loading = true;
      const params = { criteria: { url: this.url } };
      const res = await this.axios.post("/chain/retrieve", params);
      this.chain = res.data;
      this.loadBizs();
    },
    async loadBizs() {
      if (!this.chain) return;
      const options = {
        criteria: {
          "status.byAdmin": 1,
          "status.byUser": 1,
          _id: { $in: this.chain.bizs },
        },
        select: "name short_name url address industry",
      };
      const res = await this.axios.post("/bizs/list", options);
      this.bizs = res.data;
      this.selectFirst();
      this.calcDistance();
    },
    calcDistance() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.bizs = _.chain(this.bizs)
          .map((item) => {
            const distance = BizUtils.geoDistanceInMeter(
              item.address.geometry,
              {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }
            );
            item.distance = distance;
            return item;
          })
          .sortBy("distance")
          .value();
        this.selectFirst();
      });
    },
    // Select the first biz in the list
    selectFirst() {
      if (this.bizs && this.bizs.length) {
        this.selected = this.bizs[0];
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>
