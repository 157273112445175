<template lang="pug">
div
  vue-headful(
    title="Selflane",
    keywords="Selflane,Restaurants,Online Food,Food Delivery,Online Reservation,Point of Sale"
  )
  div(v-if="isNormal")
    Banner
    SearchAddress.my-10
    CouponIsland
    PromotedChains
    POIs
  Restaurants
  BizsBanner(v-if="isNormal")
</template>

<script>
import Banner from "./Banner/index";
import SearchAddress from "@/components/City/SearchAddress/index";
import BizsBanner from "./BizsBanner";
import Restaurants from "./Restaurants/index";
import PromotedChains from "@/components/Chains/Promote/index";
import POIs from "@/components/POIs/Promote";
import CouponIsland from "@/components/Promotion/Views/Home/index.vue";

export default {
  components: {
    Banner,
    SearchAddress,
    BizsBanner,
    Restaurants,
    PromotedChains,
    POIs,
    CouponIsland,
  },
  data() {
    return {
      mode: process.env.VUE_APP_MODE,
    };
  },
  computed: {
    isNormal() {
      return this.mode == "normal";
    },
  },
};
</script>
