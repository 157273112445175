<template lang="pug">
div
  v-list-item
    v-list-item-content
      v-list-item-subtitle Preferred Name
      v-list-item-title {{ preferred }}
    v-list-item-action
      v-btn(icon, @click="edit()", :loading="loading")
        v-icon(small) mdi-pencil
  v-dialog(v-model="dialog", width="300", @keydown.esc="dialog = false")
    v-card
      v-card-title
      v-card-text
        v-form(@submit.prevent="submit")
          v-text-field(
            label="Preferred Name",
            hint="Name used at restaurant",
            persistent-hint,
            v-model.trim="name",
            :error-messages="nameErrors",
            :counter="15",
            @blur="$v.name.$touch()",
            autofocus,
            ref="focus"
          )
          v-btn(
            block,
            :disabled="$v.$invalid",
            type="submit",
            color="secondary",
            :loading="loading"
          ) save
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: { name: { required, maxLength: maxLength(15) } },
  data() {
    return {
      dialog: false,
      name: "",
      loading: false,
    };
  },
  computed: {
    preferred() {
      const user = this.$auth.user();
      if (!user) return "";
      return user.name.preferred;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Required");
      !this.$v.name.maxLength && errors.push("No more than 15 charactors");
      return errors;
    },
  },
  methods: {
    edit() {
      this.dialog = true;
      this.name = this.preferred;
      this.$v.$reset();
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    },
    submit() {
      const user = this.$auth.user();
      if (!user) return;
      this.loading = true;
      const data = {
        criteria: { _id: user._id },
        action: { $set: { "name.preferred": this.name } },
      };
      this.axios
        .post("/users/update", data)
        .then((response) => {
          this.dialog = false;
          this.loading = false;
          this.$auth.user(response.data);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            alert(err.response.data);
          }
        });
    },
  },
};
</script>
