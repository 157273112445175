<template lang="pug">
div
  Payment.mb-2
  .checkout-rounded-container
    Influencer
    Tip.mb-5(v-if="hasDish")
    Card
</template>

<script>
import { mapGetters } from "vuex";
import Payment from "./Payment/index";
import Tip from "./Tip";
import Card from "./Card";
import Influencer from "./Influencer";

export default {
  components: { Influencer, Payment, Tip, Card },
  computed: {
    ...mapGetters(["cartOrder"]),
    hasDish() {
      return (
        this.cartOrder &&
        this.cartOrder.dishes &&
        this.cartOrder.dishes.length > 0
      );
    },
  },
};
</script>

<style scoped>
.checkout-rounded-container {
  background-color: white;
  border-radius: 12px;
  padding: 12px;
}
</style>