<template lang="pug">
div(v-if="message", :class="{ customer: isCustomer }")
  .caption.my-1(v-if="isCustomer") {{ message.name }} {{ message.created | time }}
  .caption.my-1(v-else) {{ message.created | time }} {{ message.name }}
  div(:class="message_style") {{ message.content }}
</template>

<script>
export default {
  props: ["message"],
  computed: {
    isCustomer() {
      return this.message.channel === "customer";
    },
    message_style() {
      if (this.isCustomer) return "message-content-customer";
      return "message-content-reply";
    },
  },
};
</script>

<style scoped>
.customer {
  text-align: end;
}
.message-content-customer {
  display: inline-block;
  background-color: green;
  color: white;
  border-radius: 10px 10px 0 10px;
  padding: 5px 10px;
}
.message-content-reply {
  display: inline-block;
  background-color: white;
  color: black;
  border-radius: 0 10px 10px 10px;
  padding: 5px 10px;
}
</style>