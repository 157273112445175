<template lang="pug">
v-btn(
  color="primary",
  text,
  small,
  v-if="valid",
  @click="handleEdit",
  :loading="loading"
) Review
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["order"],
  computed: {
    valid() {
      return (
        this.order && this.order.status >= 0 && this.order.needed < Date.now()
      );
    },
  },
  data() {
    return { loading: false };
  },
  methods: {
    async handleEdit() {
      if (!this.order) return;
      this.loading = true;
      const params = { criteria: { order: this.order._id } };
      let review;
      try {
        const res = await this.axios.post("/reviews/retrieve", params);
        review = res.data;
      } catch (err) {
        //
      }
      this.loading = false;
      if (review) {
        EventBus.$emit("edit-review", review);
      } else {
        this.createReview();
      }
    },
    createReview() {
      if (!this.order) return;
      const user = this.$auth.user();
      if (!user) return;
      const review = {
        author: {
          id: user._id,
          name: user.name.preferred || user.name.first || "",
        },
        biz: this.order.seller.id,
        order: this.order._id,
        title: "",
        stars: 5,
        content: "",
      };
      EventBus.$emit("edit-review", review);
    },
  },
};
</script>