<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Point Shop
    v-simple-table
      thead
        tr
          th Name
          th Points
          th.text-right Value
      tbody
        tr(v-for="(item, index) in items", :key="index")
          td {{ item.name }}
          td {{ item.price }}
          td
        tr(v-for="(item, index) in vouchers", :key="index + 'voucher'")
          td {{ item.name }}
          td {{ item.pts }}
          td.text-right {{ item.amount | currency }}
    v-card-text
      .caption Items you can redeem in the business with points. Please note that the point redeem is for in-store only; you can't redeem them online.
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      items: [],
      vouchers: [],
    };
  },
  methods: {
    load(bizId) {
      if (!bizId) return;
      this.dialog = true;
      const options = {
        criteria: { biz: bizId },
      };
      this.axios.post("/pointShop/retrieve", options).then((response) => {
        if (response.data) this.items = response.data.items;
      });
      this.axios.post("/pointVoucher/list", options).then((response) => {
        this.vouchers = response.data;
      });
    },
  },
  mounted() {
    EventBus.$on("show-point-shop", this.load);
  },
  destroyed() {
    EventBus.$off("show-point-shop");
  },
};
</script>