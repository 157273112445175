<template lang="pug">
.biz-cell(@click="toBiz()", role="button", v-if="biz")
  ImgBlock(:biz="biz")
  InfoBlock(:biz="biz")
</template>

<script>
import ImgBlock from "./ImgBlock";
import InfoBlock from "./InfoBlock";

export default {
  name: "BizCell",
  props: ["biz"],
  components: { ImgBlock, InfoBlock },
  methods: {
    toBiz() {
      this.$router.push("/bizs/" + this.biz.url);
    },
  },
};
</script>

<style scoped>
.biz-cell {
  overflow: hidden;
}
</style>
