import _ from 'underscore'
import moment from 'moment-timezone'

const state = {
  biz: null,
  recentBizs: [],
  bizs: []
}

const getters = {
  biz: state => state.biz,
  timezone: state => state.biz?.address?.timezone || moment.tz.guess(),
  recentBizs: state => state.recentBizs,
  bizs: state => state.bizs
}

const actions = {
  setBiz: ({
    commit
  }, data) => {
    commit('setBiz', data)
  },
  setBizs: ({
    commit
  }, bizs) => {
    commit("setBizs", bizs);
  },
}

const mutations = {
  setBiz(state, biz) {
    state.biz = biz
    if (!biz) return
    let recentBizs = _.reject(state.recentBizs, o => o._id == biz._id)
    if (recentBizs) {
      recentBizs.unshift(biz)
      state.recentBizs = recentBizs.slice(0, 5)
    } else {
      state.recentBizs = [biz]
    }
  },
  setBizs(state, bizs) {
    state.bizs = _.sortBy(bizs, 'name');
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}