<template lang="pug">
.d-flex.flex-row.align-center
  v-icon.mr-2(small, color="primary") {{ icon }}
  .body-2 {{ title }}
  slot(name="content")
</template>

<script>
export default {
  props: ["icon", "title"],
};
</script>