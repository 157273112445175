<template lang="pug">
div(v-if="city")
  vue-headful(:title="title", :keywords="keywords")
  v-container
    v-row(align="center")
      v-btn(icon, to="/")
        v-icon.fa.fa-chevron-circle-left(color="secondary")
      .title {{ this.city.city }}, {{ this.city.state }}
    v-divider.mb-5
    BizList(:bizs="bizs", :loading="loading")
</template>

<script>
import BizList from "./../Bizs/List";
import BizUtils from "./../../utils/biz.js";
import _ from "underscore";

export default {
  components: { BizList },
  props: ["url"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      city: null,
      loading: false,
      bizs: [],
    };
  },
  computed: {
    title() {
      if (this.city) return this.city.city + " | Selflane";
      return "Selflane";
    },
    keywords() {
      if (this.city) {
        return (
          this.city.city +
          "," +
          this.city.state +
          ",Selflane,Restaurants,Online Food,Food Delivery,Online Reservation,Point of Sale"
        );
      }
      return "Selflane,Restaurants,Online Food,Food Delivery,Online Reservation,Point of Sale";
    },
  },
  watch: {
    url(val, oldVal) {
      if (val && val != oldVal) this.load();
    },
  },
  methods: {
    async load() {
      this.city = null;
      this.loading = true;
      const params = { criteria: { url: this.url } };
      const res = await this.axios.post("/city/retrieve", params);
      this.city = res.data;
      this.loadBizs();
      this.loading = false;
    },
    loadBizs() {
      if (!this.city) return;
      const params = {
        criteria: {
          "status.byAdmin": 1,
          "status.byUser": 1,
          "address.city": this.city.city,
          "address.state": this.city.state,
        },
        select: "name logo url address cates schedule stats orderType",
      };
      this.loading = true;
      this.axios
        .post("/bizs/list", params)
        .then((response) => {
          // get distance
          if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                this.loading = false;
                const data = _.map(response.data, (o) => {
                  o.distance = BizUtils.geoDistance(o.address.geometry, {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                  return o;
                });
                this.bizs = _.sortBy(data, "distance");
              },
              () => {
                this.loading = false;
                this.bizs = response.data;
              }
            );
          } else {
            this.loading = false;
            this.bizs = response.data;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) alert(err.response.data);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
