<template lang="pug">
div
  Label(icon="mdi-map-marker", title="Address")
  #address-box.d-flex.flex-row.align-center.justify-space-between
    v-menu
      template(v-slot:activator="{ on }")
        .d-flex.flex-row.align-center.mr-5.my-1(v-on="on", role="button")
          .font-weight-medium(v-if="cartOrder.customer.address")
            div {{ cartOrder.customer.address | addressStreet }}
            div {{ cartOrder.customer.address | cityState }}
          span(v-else) Select
          v-icon(right) mdi-menu-down
      v-card
        v-list.py-0(
          v-if="$auth.user().addresses && $auth.user().addresses.length",
          two-line
        )
          v-list-item(
            v-for="(item, index) in $auth.user().addresses",
            :key="index",
            @click="setAddress(item)"
          )
            v-list-item-content
              v-list-item-title {{ item | addressStreet }}
              v-list-item-subtitle {{ item | cityState }}
            v-list-item-action
              v-btn(icon, color="error", @click="handleRemove(item._id)")
                v-icon(small) mdi-delete
    CreateAddress(:roundIcon="true", @saved="setAddress")
  div(v-if="cartOrder.customer.address")
    .body-2.mb-2 Delivery Instruction
    v-text-field(
      v-model="cartOrder.customer.address.note",
      dense,
      placeholder="Delivery Instruction",
      hide-details
    )
  Status
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateAddress from "@/components/User/Profile/CreateAddress";
import Status from "./Status";

export default {
  name: "CartAddress",
  components: { CreateAddress, Status },
  computed: { ...mapGetters(["cartOrder"]) },
  methods: {
    ...mapActions(["setAddress"]),
    handleRemove(id) {
      confirm("Do you want to remove this address?") && this.remove(id);
    },
    async remove(id) {
      const user = this.$auth.user();
      if (!user) return;
      const params = {
        criteria: { _id: user._id },
        action: { $pull: { addresses: { _id: id } } },
      };
      this.loading = true;
      const res = await this.axios.post("/users/update", params);
      this.loading = false;
      this.$auth.user(res.data);
      this.setAddress();
    },
  },
};
</script>

<style scoped>
#address-box {
  overflow-x: scroll;
}
</style>
