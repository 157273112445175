/// Promotion banner on Selflane Homepage /// Dedicated to Chains

<template lang="pug">
.white
  v-container(v-if="chains && chains.length")
    .d-flex.flex-row.align-center.justify-space-between
      .sl-title Featured Chains
    v-slide-group.mt-2(:show-arrows="show_arrow", ref="chains")
      v-slide-item(v-for="(item, index) in chains", :key="index")
        Cell(:chain="item")
</template>

<script>
import Cell from "./Cell";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  components: { Cell },
  data() {
    return {
      chains: [],
      show_arrow: true,
    };
  },
  computed: {
    ...mapGetters(["selectedCity"]),
  },
  methods: {
    async load() {
      if (!this.selectedCity) return;
      this.chains = [];
      const options = {
        criteria: {
          level: 1,
          country: this.selectedCity.country,
          states: this.selectedCity.state,
        },
        select: "logo url name",
      };
      const res = await this.axios.post("/chain/list", options);
      this.chains = _.sortBy(res.data, "name");
    },
  },
  mounted() {
    this.load();
    EventBus.$on("set-selected-city", this.load);
  },
  destroyed() {
    EventBus.$off("set-selected-city");
  },
};
</script>
