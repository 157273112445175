<template lang="pug">
span {{ time | datetime("ddd", timezone) }} {{ day | dayschedule }}
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import BizUtils from "@/utils/biz.js";

export default {
  // time is unix timestamp
  props: ["time"],
  computed: {
    ...mapGetters(["biz", "timezone"]),
    day() {
      return BizUtils.getDaySchedule(
        this.biz.schedule,
        moment(this.time).tz(this.timezone),
        this.timezone
      );
    },
  },
};
</script>
