<template lang="pug">
.d-flex.flex-row.justify-space-between.ma-3
  .text-center(v-for="(item, index) in items", :key="index")
    Cell(
      :item="item",
      :needed="needed",
      :timezone="timezone",
      @click="selectDay(item.day)",
      role="button"
    )
</template>

<script>
import Cell from "./Cell";
import moment from "moment-timezone";
import _ from "underscore";

export default {
  props: ["needed", "timezone"],
  components: { Cell },
  computed: {
    items() {
      return _.chain(_.range(0, 7))
        .map((o) => {
          const m = moment().tz(this.timezone).add(o, "days");
          return {
            ddd: m.format("ddd"),
            dayofmonth: m.format("D"),
            day: m.format("YYYY-MM-DD"),
          };
        })
        .value();
    },
  },
  methods: {
    selectDay(day) {
      this.$emit("select", day);
    },
  },
};
</script>