<template lang='pug'>
v-card
  v-toolbar(flat, dense)
    a.subtitle-2(v-if="biz", @click="toURL(biz.url)") {{ biz.name }}
    v-spacer
    v-btn.text-notransform(@click="showGiftCard()", outlined, small, rounded, color="secondary") Send
  v-divider
  v-card-text
    .text-center
      .title {{ card.balance | currency }}
      img.mr-3(
        :src="icon",
        @click.stop="showQRCode()",
        role="button",
        v-if="card.balance > 0"
      )
      .caption(v-if="card.to && card.to.length") Sent to {{ card.to }}
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  props: ["card"],
  data() {
    return {
      icon:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fqrcode_square.svg?alt=media&token=183eaa78-2ea7-4f7c-84ea-39333837b4b8",
    };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
    biz() {
      return _.find(this.bizAccounts, (o) => o._id == this.card.issuer);
    },
  },
  methods: {
    toURL(url) {
      if (!url) return;
      this.$router.push("/bizs/" + url);
    },
    showQRCode() {
      if (!this.card) return;
      EventBus.$emit(
        "show-qrcode",
        "sl:gc:" + this.card._id,
        "Gift Card QR Code"
      );
    },
    showGiftCard() {
      if (!this.card) return;
      EventBus.$emit("show-gift-card", this.card._id);
    },
  },
};
</script>
