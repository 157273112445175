<template lang="pug">
v-bottom-sheet(v-model="sheet", scrollable, inset, max-width="450")
  v-card(max-height="500", color="#eceefb")
    v-toolbar(flat, dense, dark, color="navy")
      .sl-title.mr-2 Total: {{ cartOrder.payment.total | currency }}
      .saved-text(v-if="cartOrder.payment.deduction.total > 0") Saved: -{{ cartOrder.payment.deduction.total | currency }}
      v-spacer
      v-btn(icon, small, @click="handleReset()")
        v-icon(small) mdi-delete
    v-card-text
      CartItems
    v-card-actions
      v-row(dense)
        v-col(cols="6")
          v-btn.text-capitalize(
            @click="toMenu",
            color="navy",
            rounded,
            outlined,
            block,
            large
          ) Go To Menu
        v-col(cols="6")
          v-btn.text-capitalize(
            @click="checkout",
            color="#FF6D32",
            dark,
            rounded,
            block,
            large
          ) Checkout
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CartItems from "@/components/Nav/TopBar/Cart/Items";
import { EventBus } from "@/event-bus.js";

export default {
  components: { CartItems, EventBus },
  data() {
    return {
      sheet: false,
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartCount", "cartUrl", "biz"]),
  },
  methods: {
    ...mapActions(["resetCart"]),
    show() {
      this.sheet = true;
    },
    close() {
      this.sheet = false;
    },
    handleReset() {
      confirm("Would you like to clear all items in your cart?") &&
        this.resetCart() &&
        this.close();
    },
    checkout() {
      if (this.biz && this.biz.status && this.biz.status.byAdmin != 1) {
        confirm(
          "This restaurant is not public. Please consider ordering from other public restaurants."
        ) && this.handleNopublic();
        return;
      }
      this.close();
      const url = this.cartUrl + "/checkout";
      this.$router.push(url);
    },
    toMenu() {
      const currentURL = this.$router.history.current.path;
      if (currentURL != this.cartUrl) this.$router.push(this.cartUrl);
      this.sheet = false;
    },
    handleNopublic() {
      this.$router.push("/");
    },
  },
  mounted() {
    EventBus.$on("show-cart-sheet", this.show);
  },
  destroyed() {
    EventBus.$off("show-cart-sheet");
  },
};
</script>

<style scoped>
.saved-text {
  font-size: 14px;
  font-weight: 400;
}
</style>