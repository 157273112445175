<template lang="pug">
v-card(v-if="$auth.check() && orders.length && !cartCount", shaped)
  v-toolbar(flat, dense, color="navy", dark)
    .sl-title Quick reorder from history
  v-list.py-0(v-for="(order, index) in orders", :key="index", dense)
    v-list-item(@click="show(order)")
      v-list-item-content
        v-list-item-title {{ order.seller.name }}
        v-list-item-subtitle {{ order._id | objectIdDate }}
    v-divider(v-if="index !== orders.length - 1")
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      orders: [],
    };
  },
  computed: {
    ...mapGetters(["biz", "cartCount"]),
  },
  methods: {
    async load() {
      if (!this.$auth.check() || !this.biz) return;
      const id = this.$auth.user()._id;
      const options = {
        criteria: { "customer.id": id, "seller.id": this.biz._id },
        select: "seller needed",
        limit: 3,
      };
      const res = await this.axios.post("/orders/list", options);
      this.orders = res.data;
    },
    show(item) {
      if (item && item._id) {
        EventBus.$emit("show-order-detail", item._id);
      }
    },
  },
  mounted() {
    this.load();
  },
  destroyed() {
    this.orders = [];
  },
};
</script>
