<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between.my-5
    .sl-title Phones
    v-btn.text-capitalize(
      outlined,
      small,
      @click="create()",
      color="secondary"
    ) Add
      v-icon(small) mdi-plus
  v-card(flat, v-if="phones && phones.length")
    v-list.py-0(dense)
      v-list-item(
        v-for="(item, index) in phones",
        :key="index",
        @click="edit(item)"
      )
        v-list-item-content
          v-list-item-title {{ item.number | phone }}
        v-list-item-action
          v-btn(icon, @click.stop="handleRemove(item._id)")
            v-icon(color="grey", small) mdi-delete
  v-dialog(v-model="dialog", max-width="300px", @keydown.esc="dialog = false")
    PhoneForm(:phone="phone", @save="save")
</template>

<script>
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      phone: {},
    };
  },
  computed: {
    phones() {
      if (this.$auth.user()) {
        return this.$auth.user().phones;
      } else return [];
    },
  },
  methods: {
    create() {
      this.phone = { number: "", countryCode: "1" };
      this.dialog = true;
    },
    edit(phone) {
      this.phone = JSON.parse(JSON.stringify(phone));
      this.dialog = true;
    },
    handleRemove(id) {
      confirm("Remove this phone?") && this.remove(id);
    },
    remove(id) {
      const user = this.$auth.user();
      if (!user) return;
      const params = {
        criteria: { _id: user._id },
        action: { $pull: { phones: { _id: id } } },
      };
      this.update(params);
    },
    save(data) {
      this.dialog = false;
      const user = this.$auth.user();
      if (!user) return;
      let params = {};
      if (data._id) {
        params = {
          criteria: { _id: user._id, "phones._id": data._id },
          action: { $set: { "phones.$": data } },
        };
      } else {
        const found = _.find(user.phones, (o) => o.number == data.number);
        if (found) {
          alert("Existing phone number");
          return;
        }
        params = {
          criteria: { _id: user._id },
          action: { $push: { phones: data } },
        };
      }
      this.update(params);
    },
    update(params) {
      this.loading = true;
      this.axios
        .post("/users/update", params)
        .then((response) => {
          this.dialog = false;
          this.loading = false;
          this.$auth.user(response.data);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            alert(err.response.data);
          }
        });
    },
  },
};
</script>
