<template lang="pug">
v-dialog(v-model="dialog", max-width="300")
  .sl-plain-card(v-if="order")
    .sl-title Delivery Status
    span(v-if="!order.deliv") This restaurant delivers itself. We don't have the tracking information. You can call the restaurant for questions.
    div(v-if="delivOrder")
      .body-2.success--text(v-if="delivOrder.bizmsg") Biz annoucement: {{ delivOrder.bizmsg }}
      div(v-if="delivOrder.pickupTime") Pick up: {{ delivOrder.pickupTime | time }}
      div(v-if="delivOrder.deliverTime") Deliver: {{ delivOrder.deliverTime | time }}
      .body-2(v-for="(item, index) in delivOrder.messages") {{ item.time | time }}: {{ item.content }}
      .sl-dash-divider.my-3
      div
        .sl-title Driver
        span(v-if="driverAccepted") {{ driverName }} {{ driverPhone | phone }}
      .sl-dash-divider.my-3
      .caption Delivery is handled by a local company.
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  name: "DelivOrderDetail",
  data() {
    return {
      order: null,
      delivOrder: null,
      dialog: false,
      loading: false,
    };
  },
  computed: {
    driverAccepted() {
      return this.delivOrder.driver && this.delivOrder.driver.accept > 0;
    },
    driverName() {
      if (
        this.delivOrder &&
        this.delivOrder.driver &&
        this.delivOrder.driver.name
      ) {
        let items = this.delivOrder.driver.name.split(" ");
        if (items && items.length) return items[0];
        else return "";
      } else return "Not Assigned Yet";
    },
    driverPhone() {
      if (
        this.delivOrder &&
        this.delivOrder.driver &&
        this.delivOrder.driver.phone
      ) {
        const now = Date.now();
        const diff = now - this.delivOrder.needed;
        // hide if beyond 24 hours
        if (diff > 86400000) return null;
        else return this.delivOrder.driver.phone;
      } else return null;
    },
  },
  methods: {
    load(order) {
      this.order = order;
      if (!order) return;
      this.dialog = true;
      this.delivOrder = null;
      if (!order.deliv) return;
      const options = { criteria: { order: order._id } };
      this.axios
        .post("/delivOrders/retrieve", options)
        .then((response) => {
          this.delivOrder = response.data;
        })
        .catch((err) => {
          if (err.response) alert(err.response.data);
        });
    },
  },
  mounted() {
    EventBus.$on("show-deliv-order-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-deliv-order-detail");
  },
};
</script>
