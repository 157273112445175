function weekday(value, short) {
  if (typeof (value) !== 'number') return value || ''
  let text = ""
  switch (value) {
    case 0: text = 'Sunday'
      break
    case 1: text = 'Monday'
      break
    case 2: text = 'Tuesday'
      break
    case 3: text = 'Wednesday'
      break
    case 4: text = 'Thursday'
      break
    case 5: text = 'Friday'
      break
    case 6: text = 'Saturday'
      break
    default: text = value
  }
  if (short && typeof text == 'string') return text.substring(0, 3)
  return text
}

export default weekday
