<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  template(v-slot:activator="{ on }")
    v-icon(v-if="roundIcon", icon, v-on="on", :loading="loading") mdi-plus
    v-btn.text-capitalize(
      v-else,
      outlined,
      small,
      v-on="on",
      :loading="loading",
      color="secondary"
    ) Add
      v-icon(small) mdi-plus
  v-card
    v-form(@submit.prevent="submit")
      v-toolbar(flat)
        .subtitle-2 Add Credit/Debit Card
        v-spacer
        v-btn(
          type="submit",
          :loading="loading",
          :disabled="!complete",
          color="secondary"
        ) Save
      v-divider.mb-5
      v-card-text
        StripeElements(
          :stripe-key="stripeKey",
          :instance-options="instanceOptions",
          :elements-options="elementsOptions",
          #default="{ elements }",
          ref="elms"
        )
          StripeElement(
            type="card",
            :elements="elements",
            :options="cardOptions",
            ref="card",
            @change="complete = $event.complete",
            @ready="handleReady"
          )
        .caption.mt-5
          span.mr-1 Secured by
          a(href="http://www.stripe.com", target="_blank") Stripe
          span .
</template>

<script>
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";
import { mapActions } from "vuex";

export default {
  props: ["roundIcon"],
  components: { StripeElements, StripeElement },
  data() {
    return {
      dialog: false,
      complete: false,
      stripeKey: process.env.VUE_APP_STRIPE,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: "",
        },
        classes: {
          base: "stripe-card",
          complete: "stripe-card.complete",
        },
        // https://stripe.com/docs/stripe.js#element-options
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions(["addStripeSource"]),
    handleReady() {
      const cardComponent = this.$refs.card;
      if (!cardComponent) return;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;
      if (cardElement) cardElement.focus();
    },
    submit() {
      this.loading = true;
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      // ref in template
      const groupComponent = this.$refs.elms;
      const cardComponent = this.$refs.card;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;

      groupComponent.instance.createToken(cardElement).then((data) => {
        this.loading = false;
        if (data && data.token && data.token.id) {
          this.createSource(data.token.id);
          cardElement.clear();
        } else if (data.message) {
          alert(data.message);
        }
      });
    },
    async createSource(token) {
      const user = this.$auth.user();
      if (!user || !token) return;
      this.loading = true;
      const params = { userId: user._id, token: token };
      try {
        const res = await this.axios.post("/stripe/sources/create", params);
        this.dialog = false;
        this.addStripeSource(res.data);
        this.$emit("saved", res.data);
      } catch (err) {
        this.loading = false;
        if (err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.stripe-card {
  width: 100%;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: white;
  padding: 10px 12px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.stripe-card.complete {
  border-color: green;
}
</style>
