<template lang="pug">
div(v-if="cartOrder")
  #cart-time(@click="open")
    .d-flex.flex-row.align-center
      .time-slot(:class="{ 'red--text': !isValid }") {{ cartOrder.needed | datetime("lll", timezone) }}
      .time-slot.blue--text.text--darken-3(v-if="isFuture") Future
      v-spacer
      v-icon mdi-chevron-right
  v-dialog(v-model="dialog", max-width="450")
    v-card(v-if="needed")
      v-toolbar(flat)
        .time-slot {{ needed | datetime("MMMM YYYY", timezone) }}
        v-spacer
        v-btn.text-capitalize(
          outlined,
          rounded,
          color="secondary",
          @click="save()"
        ) Save
      DateSelector(:needed="needed", :timezone="timezone", @select="selectDay")
      .d-flex.flex-row.justify-center.align-center.mx-10.my-10
        Selector(v-model="needed_hh", :items="hours")
        span.mx-2 :
        Selector(v-model="needed_mm", :items="minutes")
        span.mx-2 :
        Selector(v-model="needed_ampm", :items="ampm")
      v-card-text
        Label(icon="mdi-calendar")
          template(v-slot:content)
            Schedule(:time="needed")
        v-divider.my-2
        .caption Pick up order should be <b>{{ pickup_cutoff }}'</b> after opening hours
        .caption Delivery order should be <b>{{ delivery_cutoff }}'</b> after opening hours
        .caption Based on merchant timezone: <b>{{ timezone }}</b>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import _ from "underscore";
import DateSelector from "./DateSelector/index";
import Schedule from "./Schedule";
import BizUtils from "@/utils/biz.js";
import Selector from "./Selector";

export default {
  components: { DateSelector, Schedule, Selector },
  data() {
    return {
      dialog: false,
      needed: null, // a temporary unix time
      hours: _.range(1, 13),
      minutes: _.map(_.range(0, 60), (o) => {
        return String(o).padStart(2, "0");
      }),
      ampm: ["AM", "PM"],
      needed_hh: null,
      needed_mm: null,
      needed_ampm: null,
      pickup_cutoff: 10,
      delivery_cutoff: 30,
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartGeoService", "biz", "timezone"]),
    // is future date
    isFuture() {
      const needed = this.cartOrder?.needed;
      if (!needed) return false;
      const end = moment().tz(this.timezone).endOf("day").valueOf();
      return needed > end;
    },
    isValid() {
      if (!this.biz || !this.cartOrder) return false;
      const time = moment.tz(this.cartOrder.needed, this.timezone);
      const diff = time.diff(moment().tz(this.timezone), "minutes");
      if (diff < 10) return false;
      const schedule = BizUtils.getDaySchedule(this.biz.schedule, time);
      if (!schedule || !schedule.status) return false;
      const neededInMinutes = time.hour() * 60 + time.minute();
      const found = _.find(schedule.schedule, (o) => {
        if (
          o.range.length === 2 &&
          o.range[0] + 9 < neededInMinutes &&
          o.range[1] > neededInMinutes
        )
          return true;
        return false;
      });
      return found !== undefined;
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.needed = this.cartOrder.needed;
      const items = moment
        .tz(this.needed, this.timezone)
        .format("h:mm:A")
        .split(":");
      this.needed_hh = parseInt(items[0]);
      this.needed_mm = items[1];
      this.needed_ampm = items[2];
    },
    selectDay(day) {
      const hhmm = moment.tz(this.needed, this.timezone).format("hh:mm A");
      const combined = day + " " + hhmm;
      this.needed =
        moment.tz(combined, "YYYY-MM-DD hh:mm A", this.timezone).unix() * 1000;
    },
    save() {
      const formatted =
        moment.tz(this.needed, this.timezone).format("YYYY-MM-DD") +
        " " +
        this.needed_hh +
        ":" +
        this.needed_mm +
        " " +
        this.needed_ampm;
      this.needed = moment
        .tz(formatted, "YYYY-MM-DD hh:mm A", this.timezone)
        .valueOf();
      const result = BizUtils.validateTypeTime(
        this.cartOrder.type,
        this.needed,
        this.biz,
        this.cartGeoService
      );
      if (!result.status) {
        alert(result.error);
        return;
      }
      this.cartOrder.needed = this.needed;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
#cart-time {
  cursor: pointer;
}
.time-slot {
  font-size: 17px;
  font-weight: 500;
  line-height: 52px;
  margin-right: 12px;
  white-space: nowrap
}
</style>
