<template lang="pug">
.checkout-rounded-container
  Name
  Phone.mt-3
  Address.mt-3(v-if="showAddress")
  Note.mt-3(v-if="hasDish")
  Map.mt-3
</template>

<script>
import Name from "./Name";
import Phone from "./Phone";
import Address from "./Address/index";
import Note from "./Note";
import Map from "./Map";
import { mapGetters } from "vuex";

export default {
  components: { Name, Phone, Address, Note, Map },
  computed: {
    ...mapGetters(["cartOrder"]),
    hasDish() {
      return (
        this.cartOrder &&
        this.cartOrder.dishes &&
        this.cartOrder.dishes.length > 0
      );
    },
    showAddress() {
      return this.hasDish && this.cartOrder.type == "delivery";
    },
  },
};
</script>
