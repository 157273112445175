<template lang="pug">
div
  TopBar(:influencer="influencer")
  Groups
</template>

<script>
import TopBar from "./TopBar";
import Groups from "./Groups/index";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["url"],
  components: { TopBar, Groups },
  data() {
    return {
      influencer: null,
    };
  },
  computed: {
    ...mapGetters(["influencerShop"]),
  },
  methods: {
    ...mapActions(["setBizs", "setInfluencerShop"]),
    async loadInfluencer() {
      const params = {
        criteria: { code: { $regex: "^" + this.url + "$", $options: "i" } },
      };
      const res = await this.axios.post(
        "/influencer/accounts/retrieve",
        params
      );
      this.influencer = res.data;
      this.loadShop();
    },
    async loadShop() {
      if (!this.influencer) return;
      const params = {
        criteria: { influencer: this.influencer._id },
      };
      const res = await this.axios.post("/influencer/shops/retrieve", params);
      this.setInfluencerShop(res.data);
      this.loadBizs();
    },
    async loadBizs() {
      if (!this.influencerShop) return;
      const bizs = _.chain(this.influencerShop.groups)
        .pluck("bizs")
        .flatten()
        .compact()
        .value();
      const options = {
        criteria: { _id: { $in: bizs } },
        select: "name url logo",
      };
      const res = await this.axios.post("/bizs/list", options);
      this.setBizs(res.data);
    },
  },
  mounted() {
    this.loadInfluencer();
  },
};
</script>
