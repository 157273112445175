import Home from './Views/Home/index.vue'
import Coupon from './Views/Coupon/index.vue'

export default [{
    path: '/couponisland',
    component: Home,
}, {
    path: '/couponisland/:code',
    component: Coupon,
    props: true
}]