<template lang="pug">
div
  .d-flex.flex-row.justify-space-between
    .title {{ order.seller.name }}
    v-btn(color="primary", icon, @click.stop="toURL(order.seller.url)")
      v-icon mdi-link
  .detail {{ order.seller.address | address(order.seller.industry) }}
  .sl-dash-divider.my-4
  .detail.mb-2
    div \#{{ order.orderNumber }} |
      span.ml-1 {{ order.type | orderType }}
      span.ml-1.red--text(v-if="order.status == -1") {{ order.status | orderStatus }}
    .d-flex.flex-row
      v-icon.mr-2(small) mdi-clock-outline
      span {{ order.needed | datetime("MMM D, YYYY h:mm A", timezone) }} (estimated, {{ timezone }})
</template>

<script>
import moment from "moment-timezone";
export default {
  props: ["order"],
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone || moment.tz.guess();
    },
  },
  methods: {
    toURL(url) {
      if (!url) return;
      this.$router.push("/bizs/" + url);
    },
  },
};
</script>

<style scoped>
.title {
  color: black;
  font-weight: 500;
  font-size: 18px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.detail {
  font-size: 14px;
  color: rgba(28, 31, 52, 0.6);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price {
  font-size: 18px;
  font-weight: 500;
  margin-right: 14px;
}
</style>
