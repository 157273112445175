function leadtime(input) {
  const num = Number(input)
  const days = Math.floor(num / 1440)
  const hours = Math.floor(num % 1440 / 60)
  const minutes = Math.floor(num % 60)
  const list = []
  if (days > 1) {
    list.push(days + ' days')
  } else if (days > 0) {
    list.push(days + ' day')
  }
  if (hours > 1) {
    list.push(hours + 'h')
  } else if (hours > 0) {
    list.push(hours + 'h')
  }
  if (minutes > 1) {
    list.push(minutes + '\'')
  } else if (minutes > 0) {
    list.push(minutes + '\'')
  }
  if (list.length > 0) return list.join(' ')
  else return 'now'
}

export default leadtime