<template lang="pug">
.checkout-box(v-if="cartOrder && cartCount > 0")
  img.checkout-bucket(:src="bucketIcon")
  .selected-box
    .px-3
      .d-flex.flex-row.align-center
        .sl-title My Cart
        v-spacer
        v-btn(icon, small, @click="handleReset()")
          v-icon(small) mdi-delete
      CartItems
  Actions
</template>

<script>
import CartItems from "@/components/Nav/TopBar/Cart/Items";
import Actions from "./Actions";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartSelected",
  components: { CartItems, Actions },
  data() {
    return {
      dialog: false,
      bucketIcon:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fcheckout_shoppingcart_icon.svg?alt=media&token=abd99d75-093c-4cb1-b91a-79c5de61bdf0",
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartCount", "biz"]),
  },
  methods: {
    ...mapActions(["resetCart"]),
    handleReset() {
      confirm("Would you like to clear all items in your cart?") &&
        this.resetCart();
    },
  },
};
</script>

<style scoped>
.checkout-box {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 4;
}
.checkout-bucket {
  position: absolute;
  bottom: 40px;
  left: 12px;
}
.selected-box {
  overflow: hidden;
  border-radius: 6px;
  background: linear-gradient(to bottom right, #f6f8fe, #eceefb);
  padding-bottom: 30px;
  border: 1px solid #eceefb;
}
</style>