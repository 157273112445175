function address(address, industry, showPostalCode = false) {
  if (!address) return ''
  const lines = []
  if (address.line1 && industry != 4) {
    lines.push(address.line1.trim())
  }
  if (address.line2 && industry != 4) {
    lines.push(address.line2.trim())
  }
  const list = [lines.join(' ')]
  if (address.city) list.push(address.city.trim())
  if (address.state) list.push(address.state)
  let text = list.join(', ')
  if (address.name) {
    text = '(' + address.name + ') ' + text
  }
  if (showPostalCode && address.postal_code) {
    text += ' ' + address.postal_code
  }
  return text
}

export default address
