import _ from 'underscore'

function unitPrice(dish) {
  if (!dish) return 0;
  let price = dish.price + (dish.onlineUpcharge || 0);
  _.each(dish.modifiers, o => { price += modPrice(o); });
  dish.unitPrice = price;
  return price;
}

function modPrice(modifier) {
  const selectedChoices = _.filter(modifier.choices, 'selected')
  if (!selectedChoices) return 0

  let total = _.reduce(selectedChoices, (memo, item) => {
    return memo + (item.price || 0) * (item.quantity || 1);
  }, 0);

  // first selected choice
  const firstSelected = _.first(selectedChoices);
  if (!firstSelected || !modifier.associatedChoices) return total;

  let totalSubchoice = 0;
  let free = 0
  const firstDefaultSubChoice = _.find(modifier.associatedChoices, o => o.isDefault)
  if (firstSelected.freeQuantity > 0 && !firstDefaultSubChoice) {
    free = firstSelected.freeQuantity
  }
  _.chain(modifier.associatedChoices)
    .filter(choice => choice.isDefault || choice.selected)
    .each(choice => {
      let unitPrice =
        choice.useBoostedPrice === true ?
          firstSelected.associatedChoiceBoostedPrice || 0 :
          firstSelected.associatedChoiceUnitPrice || 0;
      let balanced = balancedQuantity(choice)
      const extra = balanced - choice.freeMax
      if (extra > 0) {
        totalSubchoice += extra * unitPrice
        balanced -= extra
      }
      if (free > 0) {
        free -= balanced
        if (free < 0) {
          totalSubchoice += unitPrice * (-free)
        }
      } else {
        totalSubchoice += unitPrice * balanced
      }
    })
  if (totalSubchoice > 0) {
    total += totalSubchoice
  }
  return total

  /// weight quantity by half/half
  function weightedQuantity(subChoice) {
    if (!subChoice.selected) {
      return 0
    }
    if (subChoice.halfhalf == 1) {
      return subChoice.quantity
    } else {
      return 0.5 * subChoice.quantity
    }
  }

  /// balance quantity depending on if it is a default choice
  function balancedQuantity(subChoice) {
    const weighted = weightedQuantity(subChoice)
    if (subChoice.isDefault && !subChoice.selected) { // not selected but could be substitude
      return -1
    } else if (!subChoice.selected) { // not selected
      return 0
    } else if (subChoice.isDefault && subChoice.selected) {
      return weighted - 1
    } else {
      return weighted
    }
  }
}

export default unitPrice