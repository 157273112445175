<template lang="pug">
v-container
  v-btn(to="/couponisland", outlined, color="secondary", small)
    v-icon(left, small) mdi-chevron-left
    span Coupon Island
  v-col(align="center", v-if="promotion")
    .title {{ promotion.title }}
    .body-1 {{ promotion.desc }}
    .green--text.headline
      span(v-if="promotion.type == 'pct'") {{ -promotion.pct }}%
      span(v-else) {{ -promotion.fix | currency }}
    div(v-if="promotion.chain") Apply to Entire Chain
    div Total Coupons: {{ promotion.total }}
    div Minimum Purchase: {{ promotion.minimum | currency }}
      div Expiry: {{ promotion.expiry | date }}
    v-btn(@click.stop="claim", v-if="claimed == 0", color="secondary") Claim Me
    .text-center(v-else)
      v-chip(dark, color="success", v-if="claimed == 1") Claimed
      v-chip(dark, color="success", outlined, v-if="claimed == 2") Applied
  v-card.my-3.py-3(v-if="chain", :to="toChain(chain.url)", role="button")
    v-img(:src="mediaHost + promotion.logo", max-height="70", contain)
    .text-center
      .subtitle-2 {{ promotion.name }}
  v-row(justify="center", v-if="biz")
    v-col(cols="12", sm="6", md="4")
      BizCell(:biz="biz")
</template>

<script>
import BizCell from "@/components/Bizs/Cell/index.vue";

export default {
  props: ["code"],
  components: { BizCell },
  data() {
    return {
      promotion: null,
      chain: null,
      biz: null,
      claimed: 0,
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  computed: {
    url() {
      return "https://selflane.com/couponisland/" + this.code;
    },
  },
  methods: {
    load() {
      if (!this.code) return;
      const params = { criteria: { code: this.code } };
      this.axios.post("/promotion/retrieve", params).then((response) => {
        this.promotion = response.data;
        this.loadClaim();
        if (this.promotion && this.promotion.chain)
          this.loadChain(this.promotion.chain);
        else if (this.promotion && this.promotion.biz)
          this.loadBiz(this.promotion.biz);
      });
    },
    async loadChain(chainId) {
      const params = { criteria: { _id: chainId } };
      const res = await this.axios.post("/chain/retrieve", params);
      this.chain = res.data;
    },
    async loadBiz(bizId) {
      const params = { criteria: { _id: bizId } };
      const res = await this.axios.post("/bizs/retrieve", params);
      this.biz = res.data;
    },
    toChain(url) {
      return "/chains/" + url;
    },
    async loadClaim() {
      if (!this.promotion) return;
      if (!this.$auth.check()) return;

      const params = {
        criteria: {
          promotion: this.promotion._id,
          user: this.$auth.user()._id,
        },
      };
      const res = await this.axios.post("/promoclaim/retrieve", params);
      if (res && res.data) {
        if (res.data.applied) this.claimed = 2;
        else this.claimed = 1;
      }
    },
    async claim() {
      if (!this.promotion) return;
      if (!this.$auth.check()) {
        this.$router.push("/login");
        return;
      }
      const params = {
        userId: this.$auth.user()._id,
        promoId: this.promotion._id,
      };
      try {
        await this.axios.post("/promotion/claim", params);
        this.loadClaim();
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>
