/**
 * Convert unix timestamp h:mm A format
 */
import moment from 'moment'

function time(input) {
  if (!input) return ''
  const valid = typeof (input) == 'number' || typeof (input) == 'string' || input instanceof Date
  if (!valid) return input
  return moment(input).format('h:mm A')
}

export default time