<template lang="pug">
v-dialog(v-model="dialog", width="400px", @keydown.esc="dialog = false")
  template(v-slot:activator="{ on }")
    .d-flex.flex-row.align-center.my-2
      .subtitle-2 Today: {{ today | dayschedule }}
      v-btn(icon, small, v-on="on")
        v-icon(small) mdi-calendar-minus
  v-card
    v-toolbar(flat, dense)
      .overline Hours
    v-divider
    v-alert(
      v-model="schedule.temporary_close && schedule.temporary_close.status",
      type="error",
      dense
    ) Temporary Close. {{ schedule.temporary_close.reason }}
    v-simple-table(v-if="schedule", dense)
      tbody
        tr(v-for="(item, i) in schedule.weekdays", :key="i + 'Week'")
          td {{ item.date | weekday }}
          td {{ item | dayschedule }}
        tr(v-for="(item, i) in schedule.specialdays", :key="i + 'special'")
          td {{ item.date }}
          td {{ item | dayschedule }}
</template>

<script>
import BizUtils from "@/utils/biz";

export default {
  props: ["schedule"],
  data() {
    return {
      dialog: false,
      dateIcon:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Frestaurant_date_icon.svg?alt=media&token=3e76a83c-2d6c-4db1-ad66-a55ff10c9ae5",
    };
  },
  computed: {
    today() {
      return BizUtils.getDaySchedule(this.schedule);
    },
  },
};
</script>
