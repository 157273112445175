<template lang="pug">
.sticky.event-container
  v-row(wrap, no-gutters)
    v-col(cols="12", md="8")
    v-col(cols="12", md="4")
      .container-right.d-flex.flex-row.align-center 
        .price {{ prices }}
        v-spacer
        v-btn.px-10(depressed, color="primary", @click="handlePurchase") Tickets
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz", "event"]),
    prices() {
      const prices = _.chain(this.event.items).pluck("price").sortBy().value();
      if (!prices || !prices.length) return "";
      if (prices.length == 1) return "$" + prices[0];
      return "$" + prices[0] + " - $" + _.last(prices);
    },
  },
  methods: {
    handlePurchase() {
      if (!this.event) return;
      this.$router.push("/event/" + this.event._id + "/purchase");
    },
  },
};
</script>

<style scoped>
.price  {
  font-size: 15px;
  font-weight: 500;
  padding-right: 30px;
  vertical-align: baseline;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 4;
  background: #F0F2F5;
  display: flex;
}
.container-right {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>