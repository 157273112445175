<template lang="pug">
v-dialog(v-model="dialog", max-width="300px", @keydown.esc="dialog = false")
  template(v-slot:activator="{ on }")
    v-btn(icon, small, color="secondary", v-on="on")
      v-icon(small) mdi-pencil
  v-card
    v-card-title
    v-card-text
      v-form(@submit.prevent="save")
        v-text-field(label="Name", v-model="name")
        v-btn(block, color="secondary", type="submit", :loading="loading") save
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      name: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async save() {
      if (this.name == this.order.customer.name) {
        alert("Same name");
        return;
      }
      this.loading = true;
      const params = {
        orderId: this.order._id,
        name: this.name,
        portal: "customer",
      };
      try {
        const res = await this.axios.post("/orders/editName", params);
        this.updateOrder(res.data);
        this.dialog = false;
      } catch (err) {
        alert(err.response && err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>