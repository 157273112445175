<template lang="pug">
.d-flex.flex-row.justify-space-between
  div
    QRCode(:order="order")
    Tracking.ml-3(:order="order")
  div
    Conversation(:order="order")
    ReviewBtn(:order="order")
    DetailBtn(:order="order")
</template>

<script>
import QRCode from "./QRCode";
import Tracking from "./Tracking";
import ReviewBtn from "./ReviewBtn";
import DetailBtn from "./DetailBtn";
import Conversation from "./Conversation/index";

export default {
  props: ["order"],
  components: { QRCode, Tracking, ReviewBtn, DetailBtn, Conversation },
};
</script>