<template lang="pug">
.biz-body
  vue-headful(:title="title", :keywords="keywords")
  div(v-if="!isCheckout")
    LinearProgress(:on="loading")
    Info(@showMenu="showMenu = true")
  div(v-if="biz")
    router-view#bizMainCon
    v-container(v-if="isMainPath")
      Reviews
      SuggestedBiz(v-if="isNormal")
  CartButton(v-if="!isCheckout")
  NotFound(v-if="notFound")
  ChainDialog
  v-bottom-sheet(v-model="sheet")
    v-sheet.text-center
      v-btn.my-3(@click="showCoupon()", color="secondary", rounded) Show Coupon
      .pa-3.subtitle-2 Don't forget to claim and apply coupon in checkout.
</template>

<script>
import LinearProgress from "@/components/basic/LinearProgress";
import Info from "./Info/index";
import CartCheckout from "./../Checkout/index";
import Reviews from "./Reviews";
import SuggestedBiz from "./SuggestedBiz";
import CartButton from "./CartButton";
import NotFound from "./NotFound";
import ChainDialog from "./ChainDialog";
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  components: {
    LinearProgress,
    Info,
    CartCheckout,
    Reviews,
    SuggestedBiz,
    CartButton,
    NotFound,
    ChainDialog,
  },
  props: ["url"],
  data() {
    return {
      mode: process.env.VUE_APP_MODE,
      showMenu: true,
      loading: false,
      notFound: false,
      sheet: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "chain", "cities", "promotions", "cartBiz"]),
    isNormal() {
      return this.mode == "normal";
    },
    city() {
      if (this.biz && this.biz.address && this.biz.address.city)
        return this.biz.address.city;
      return "";
    },
    title() {
      let items = [];
      if (this.biz) {
        items.push(this.biz.name);
      }
      if (this.city && this.city.length) {
        items.push(this.city);
      }
      if (this.isNormal) {
        items.push("Selflane");
      }
      return items.join(" | ");
    },
    keywords() {
      let keywords = "Selflane,Online Order,Food Delivery,Online Reservation";
      if (this.biz) return this.biz.name + "," + this.city + "," + keywords;
      return keywords;
    },
    isMainPath() {
      const path = this.$route.path;
      let result = true;
      if (
        path.indexOf("/reserve") !== -1 ||
        path.indexOf("/checkout") !== -1 ||
        path.indexOf("/member") !== -1
      )
        result = false;
      return result;
    },
    isCheckout() {
      const path = this.$route.path;
      return path.indexOf("/checkout") !== -1;
    },
  },
  watch: {
    url(val, oldVal) {
      if (val && val != oldVal) {
        this.load();
      }
    },
  },
  methods: {
    ...mapActions([
      "setBiz",
      "setCartBiz",
      "setBizService",
      "setSelectedCity",
      "selectedCity",
      "setCartGeoService",
      "updateCartPayment",
      "setWeeklySpecials",
      "setReviews",
      "setChain",
      "setPromotions",
      "setClaims",
    ]),
    async load() {
      this.notFound = false;
      this.setBiz(null);
      this.loading = true;
      try {
        const params = { criteria: { url: this.url } };
        const res = await this.axios.post("/bizs/retrieve", params);
        this.setBiz(res.data);
        this.refreshCartBiz();
      } catch (err) {
        this.notFound = true;
      }
      this.loading = false;
      if (!this.biz) return;
      this.updateSelectedCity();
      this.loadBizService();
      this.loadGeoService();
      this.loadWeeklySpecials();
      this.loadReviews();
      this.loadChain();
    },
    async loadBizService() {
      this.setBizService(null);
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id }, select: "member pos" };
      try {
        const res = await this.axios.post("/bizService/retrieve", params);
        this.setBizService(res.data);
      } catch (err) {
        // do nothing
      }
    },
    refreshCartBiz() {
      if (!this.biz) return;
      if (this.biz._id == this.cartBiz._id) this.setCartBiz(this.biz);
    },
    /**
     * If the user has never set up a default address, set the city of the loaded business as the default address
     */
    updateSelectedCity() {
      if (!this.biz) return;
      if (this.selectedCity) return;
      const address = this.biz.address;
      const found = _.find(this.cities, (o) => {
        return (
          o.city.trim() == address.city.trim() &&
          o.state.trim() == address.state.trim()
        );
      });
      if (found) this.setSelectedCity(found);
    },
    async loadGeoService() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      try {
        const res = await this.axios.post("/bizs/getGeoService", params);
        this.setCartGeoService(res.data);
        this.updateCartPayment();
      } catch (err) {
        //
      }
    },
    async loadWeeklySpecials() {
      this.setWeeklySpecials([]);
      if (!this.biz) return;
      const params = {
        criteria: { biz: this.biz._id, end: { $gt: Date.now() } },
      };
      const res = await this.axios.post("/discount/weeklySpecial/list", params);
      this.setWeeklySpecials(res.data);
    },
    async loadReviews() {
      this.setReviews([]);
      if (!this.biz) return;
      const params = {
        criteria: { biz: this.biz._id, status: { $ne: -1 } },
        limit: 50,
      };
      const res = await this.axios.post("/reviews/list", params);
      const sortedReviews = _.chain(res.data)
        .sortBy((o) => -o.created)
        .sortBy((o) => -o.stars)
        .sortBy((o) => -o.liked.length)
        .value();
      this.setReviews(sortedReviews);
    },
    async loadChain() {
      this.setChain(null);
      if (!this.biz) return;
      const params = { criteria: { bizs: this.biz._id } };
      const res = await this.axios.post("/chain/retrieve", params);
      this.setChain(res.data);
      this.loadCoupons();
    },
    async loadCoupons() {
      this.setPromotions([]);
      if (!this.biz) return;
      const now = Date.now();
      let params;
      if (this.chain) {
        params = {
          criteria: {
            $or: [{ biz: this.biz._id }, { chain: this.chain._id }],
            expiry: { $gt: now },
          },
        };
      } else {
        params = { criteria: { biz: this.biz._id, expiry: { $gt: now } } };
      }
      const res = await this.axios.post("/promotion/list", params);
      this.setPromotions(res.data);
      if (this.promotions && this.promotions.length > 0 && this.isMainPath) {
        this.sheet = true;
        this.loadClaims();
      }
    },
    async loadClaims() {
      if (!this.$auth.check()) return;
      const ids = _.pluck(this.promotions, "_id");
      const params = {
        criteria: { promotion: { $in: ids }, user: this.$auth.user()._id },
        select: "promotion applied",
      };
      const res = await this.axios.post("/promoclaim/list", params);
      this.setClaims(res.data);
    },
    showCoupon() {
      this.$vuetify.goTo("#coupon-table");
      this.sheet = false;
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.biz-body {
  background-color: #F0F2F5;
}
  
</style>