<template lang="pug">
v-btn.text-capitalize(
  depressed,
  rounded,
  small,
  color="#E4E6EB",
  v-if="hasReserve",
  @click="reserve"
)
  v-icon(small, left) mdi-table-chair
  span Reserve
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz"]),
    hasReserve() {
      return (
        this.biz && this.biz.orderType && this.biz.orderType.reservation.status
      );
    },
  },
  methods: {
    reserve() {
      this.$router.push("/bizs/" + this.biz.url + "/reserve");
      this.$vuetify.goTo("#bizMainCon");
    },
  },
};
</script>

