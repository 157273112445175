<template lang="pug">
.pos-notice
  v-dialog(v-model="dialog", width="500")
    template(v-slot:activator="{ on }")
      v-btn.text-capitalize(
        v-on="on",
        rounded,
        depressed,
        color="#E4E6EB",
        small
      ) We Use Selflane POS
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Selflane POS Store
      v-card-text
        div You are looking at the same menus as we use in the store. Your online orders print to our kitchen right away. Selflane is commission-free, unlike other services that take profit from restaurants.
        v-row(v-if="hiddenMenu || hiddenCourse", dense, justify="center")
          v-col.text-center(cols="6", v-if="hiddenMenu")
            .subtitle-2 In-store Only Menus
            div {{ hiddenMenu }}
          v-col.text-center(cols="6", v-if="hiddenCourse")
            .subtitle-2 In-store Only Courses
            div {{ hiddenCourse }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    hiddenMenu() {
      const names = _.chain(this.biz.menus)
        .filter((o) => o.controlled)
        .pluck("name")
        .value();
      return names.join(", ");
    },
    hiddenCourse() {
      const names = _.chain(this.biz.courses)
        .filter((o) => o.controlled)
        .pluck("name")
        .value();
      return names.join(", ");
    },
  },
};
</script>

<style scoped>
.pos-notice {
  position: absolute;
  right: 16px;
  top: 16px;
}
</style>
