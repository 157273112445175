<template lang="pug">
div
  .d-flex.flex-row.justify-space-between(@click="dialog = true", role="button")
    div
      .body-2 Kitchen Instruction
      .font-weight-medium {{ cartOrder.note }}
    v-icon mdi-plus
  v-dialog(v-model="dialog", width="450", @keydown.esc="dialog = false")
    v-card
      v-card-title
      v-card-text
        v-form(@submit.prevent="dialog = false")
          v-text-field(
            v-model="cartOrder.note",
            label="Note",
            :counter="50",
            clearable,
            @change="setNote(cartOrder.note)"
          )
        span.grey--text.text--darken-1 Please write a concise note if necessary. The note would be printed to the restaurant's kitchen printer when equipped.
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["cartOrder"]),
  },
  methods: {
    ...mapActions(["setNote"]),
  },
};
</script>
