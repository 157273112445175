<template lang="pug">
.img-block
  .img-container
    v-img(:src="pic", alt="...", v-if="pic", aspect-ratio="1")
  Favor(:biz="biz")
</template>

<script>
import Favor from "./Favor";

export default {
  name: "BizCellImgBlock",
  props: ["biz"],
  components: { Favor },
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  computed: {
    pic() {
      if (this.biz.logo && this.biz.logo.length)
        return this.mediaHost + this.biz.logo;
      return "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Fdefault-restaurant-icon.jpg?alt=media&token=70a961a3-537e-476e-8eda-a5c382462a9c";
    },
  },
};
</script>

<style scoped>
.img-block {
  position: relative;
}
.img-container {
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100px;
  border-radius: 18px;
  overflow: hidden;
}
</style>
