<template lang="pug">
div(v-if='bizs && bizs.length').my-3.white
  v-container
    .sl-title New to Selflane
    BizList(:bizs='bizs')
</template>

<script>
import { mapGetters } from "vuex";
import BizList from "@/components/Bizs/ListRow";
import _ from "underscore";
import moment from "moment";

export default {
  components: { BizList },
  computed: {
    ...mapGetters(["bizAccounts"]),
    bizs() {
      const three_month =
        moment()
          .subtract(3, "months")
          .unix() * 1000;
      return _.chain(this.bizAccounts)
        .filter(o => {
          if (o && o.online_start > three_month) return true;
        })
        .sortBy(o => {
          return o.online_start;
        })
        .value()
        .reverse();
    },
  },
};
</script>
