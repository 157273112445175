<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Search Business
    v-card-text.pb-0
      v-form(@submit.prevent="search()")
        v-text-field(
          v-model="searchText",
          prepend-inner-icon="mdi-store",
          @click:append="search()",
          label="Enter Name",
          clearable,
          :hint="hint",
          color="secondary"
        )
      .error--text {{ error }}
    v-list(two-line, dense)
      v-list-item(
        v-for="(item, index) in bizs",
        :key="index",
        @click="goto(item)"
      )
        v-list-item-avatar
          img(:src="mediaHost + item.logo")
        v-list-item-content
          v-list-item-title {{ item.name }}
          v-list-item-subtitle {{ item.address | address(item.industry) }}
        v-list-item-action
          v-icon mdi-chevron-right
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      searchText: "",
      error: "",
      bizs: [],
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      hint: "Please enter at least 3 letters.",
    };
  },
  methods: {
    open() {
      this.error = "";
      this.dialog = true;
    },
    async search() {
      const searchText = this.searchText.trim();
      if (!searchText || searchText.length < 3) {
        this.error = "Please enter with at least 3 characters";
        return;
      }
      this.error = "";
      const params = {
        criteria: {
          name: { $regex: this.searchText, $options: "i" },
          "status.byAdmin": 1,
          "status.byUser": 1,
        },
        select: "name short_name logo url address industry",
      };
      try {
        const res = await this.axios.post("/bizs/list", params);
        this.bizs = res.data;
        if (!this.bizs || !this.bizs.length)
          this.error = "No matching business is found";
      } catch (err) {
        if (err && err.response) this.error = err.response.data;
      }
    },
    goto(biz) {
      this.dialog = false;
      const target_url = "/bizs/" + biz.url;
      if (this.$route.path == target_url) return;
      this.$router.push(target_url);
    },
  },
  mounted() {
    EventBus.$on("search-biz", this.open);
  },
  destroyed() {
    EventBus.$off("search-biz");
  },
};
</script>
