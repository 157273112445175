<template lang="pug">
.choice-btn(:class="{ 'choice-selected': choice.selected }")
  .d-flex.flex-row.align-end.unselectable
    .d-flex.flex-row.px-3(@click="$emit('click')", role="button")
      .text-truncate {{ choice.name }}
      .price.ml-1(v-if="choice.price > 0") +{{ choice.price | currency }}
    v-select.select-style(
      v-model="choice.quantity",
      :items="items",
      dense,
      dark,
      :hide-details="true",
      v-if="choice.selected && choice.maximum > 1",
      @change="selectQuantity"
    )
</template>

<script>
import _ from "underscore";
export default {
  props: ["choice"],
  data() {
    return {
      items: _.map(_.range(1, this.choice.maximum + 1), (o) => ({
        text: "x" + o,
        value: o,
      })),
    };
  },
  methods: {
    selectQuantity() {
      this.$emit("selectQuantity", this.choice.quantity);
    },
  },
};
</script>

<style scoped>
.price {
  font-size: 9px;
}
.select-style {
  width: 52px;
  font-size: 13px;
  font-weight: 500;
  padding: 0;
  margin-left: 10px;
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>