<template lang="pug">
v-container
  .page-title.my-10 Latest News
  v-row.mb-10
    v-col(
      cols="12",
      sm="6",
      md="4",
      v-for="(item, index) in press_reports",
      :key="index"
    )
      v-card(outlined, height="100%")
        v-toolbar(flat, dense)
          .font-weight-medium {{ item.channel }}
        v-divider
        v-img(:src="item.picture", height="240px", contain)
        v-card-text.body-1
          .font-weight-medium {{ item.title }}
          .my-2 {{ item.date | datetime('MMM D, YYYY') }}
          .my-2 {{ item.content }}
          a(
            :href="item.external_link",
            target="_blank",
            v-if="item.external_link"
          ) Read More
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["press_reports"]),
  },
  methods: {
    ...mapActions(["setPressReports"]),
    async load() {
      const params = {
        criteria: {},
      };
      const res = await this.axios.post("/press/report/list", params);
      this.setPressReports(res.data);
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.page-title {
  font-size: 38px;
  font-weight: 500;
  text-align: center;
}

a {
  text-decoration: none;
  font-weight: 500;
}
</style>
