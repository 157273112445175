<template lang="pug">
v-container
  v-card(outlined)
    v-card-text
      Balance
      v-divider
      .subtitle-2.my-3 Select Amount
      .my-3.d-flex.flex-row.flex-wrap 
        v-btn.mr-3.grey(
          v-for="(item, index) in amount_items",
          :key="index",
          @click="setAmount(item)",
          dark,
          :class="{ secondary: item == amount }"
        ) {{ item | currency }}
      Payment.my-3(@select="setPayment")
      .text-center
        v-btn(
          color="secondary",
          width="200",
          :disabled="!amount || !payment",
          :loading="loading",
          @click="submit()"
        ) Load {{ amount | currency }}
  Notice.my-3
</template>

<script>
import Balance from "./Balance";
import Payment from "./Payment";
import Notice from "./Notice";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Balance, Payment, Notice },
  data() {
    return {
      amount: 0,
      amount_items: [50, 100, 200, 500],
      payment: null,
      loading: false,
    };
  },
  methods: {
    setAmount(value) {
      this.amount = value;
    },
    setPayment(data) {
      this.payment = data;
    },
    async submit() {
      if (!this.amount || !this.payment) return;
      this.loading = true;
      const params = {
        amount: this.amount,
        currency: "usd",
        payment: this.payment,
      };
      try {
        await this.axios.post("/gfbalance/purchase/create", params);
        EventBus.$emit("reload-gfbalance");
        this.amount = 0;
        this.payment = null;
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>