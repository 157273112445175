<template lang="pug">
.links-body.navy
  v-container
    .selflane-title SELFLANE
    .press-title.my-8 Press & Media
    .text-center
      v-btn.primary.navy--text(large, @click="pressEmail") press@selflane.com
</template>

<script>
export default {
  methods: {
    pressEmail() {
      window.location.href =
        "mailto:press@selflane.com?subject=News%20for%20Selflane";
    },
  },
};
</script>

<style scoped>
.selflane-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
}
.press-title {
  font-size: 46px;
  font-weight: 800;
  text-align: center;
}
.links-body {
  color: white;
  padding-top: 80px;
  padding-bottom: 80px;
}
</style>
