import _ from 'underscore'

const state = {
  influencers: [],
  influencerCode: null,
  influencerShop: state => state.influencerShop,
  influencerPlans: []
};

const getters = {
  influencers: state => state.influencers,
  influencerCode: state => state.influencerCode,
  influencerShop: state => state.influencerShop,
  influencerPlans: state => state.influencerPlans
};

const actions = {
  setInfluencers: ({
    commit
  }, data) => {
    commit("setInfluencers", data);
  },
  setInfluencerCode: ({
    commit
  }, data) => {
    commit("setInfluencerCode", data);
  },
  setInfluencerShop: ({
    commit
  }, data) => {
    commit('setInfluencerShop', data)
  },
  // plans
  setInfluencerPlans: ({
    commit
  }, data) => {
    commit('setInfluencerPlans', data)
  },
  updateInfluencerPlan: ({
    commit
  }, data) => {
    commit('updateInfluencerPlan', data)
  },
  addInfluencerPlan: ({
    commit
  }, data) => {
    commit('addInfluencerPlan', data)
  },
  removeInfluencerPlan: ({
    commit
  }, data) => {
    commit('removeInfluencerPlan', data)
  },
};

const mutations = {
  setInfluencers(state, data) {
    state.influencers = data;
  },
  setInfluencerCode(state, data) {
    state.influencerCode = data;
  },
  setInfluencerShop(state, item) {
    state.influencerShop = item
  },
  // plans
  setInfluencerPlans(state, data) {
    state.influencerPlans = data
  },
  updateInfluencerPlan(state, data) {
    state.influencerPlans = _.map(state.influencerPlans, o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addInfluencerPlan(state, data) {
    state.influencerPlans.push(data)
  },
  removeInfluencerPlan(state, data) {
    state.influencerPlans = _.reject(state.influencerPlans, o => {
      return (o._id === data._id)
    })
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};