<template lang="pug">
div
  TopBar(:menus="menus")
  div(v-for="group in menus", :key="group.menu._id")
    MenuTitle(:menu="group.menu")
    CourseSection(
      v-for="cItem in group.courses",
      :key="cItem.course._id",
      :id="'course_' + cItem.course._id + group.menu._id",
      :data="cItem",
      :modifiers="modifiers"
    )
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import TopBar from "./TopBar";
import MenuTitle from "./MenuTitle";
import CourseSection from "./CourseSection/index";

export default {
  components: { TopBar, MenuTitle, CourseSection },
  data() {
    return {
      modifiers: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    bizId() {
      return this.biz && this.biz._id;
    },
    menus() {
      const dayschedule = this.$options.filters.dayschedule;
      const weekdayList = this.$options.filters.weekdayList;
      if (!this.biz || !this.biz.menus || !this.biz.courses || !this.biz.dishes)
        return [];
      /// reject dish 1) zero price 2) out of order 3) by weight 4) hide online
      const dishes = _.reject(
        this.biz.dishes,
        (o) => o.price === 0 || o.ooo || (o.uom && o.uom.length) || o.hide
      );

      const courses = this.biz.courses;

      function getObjById(id, list) {
        return _.find(list, (item) => item._id === id);
      }
      return _.chain(this.biz.menus)
        .reject((menu) => menu.ooo || menu.controlled)
        .map((menu) => {
          if (menu && menu.selectedTime) {
            menu.time =
              weekdayList(menu.date, true) +
              " " +
              dayschedule({ status: true, schedule: menu.schedule });
          }
          return {
            menu: menu,
            courses: _.chain(dishes)
              .filter((dish) => _.contains(dish.menus, menu._id))
              .reject((dish) => !dish.course || dish.course.length === 0)
              .groupBy("course")
              .map((group, courseId) => {
                return { course: getObjById(courseId, courses), dishes: group };
              })
              .reject(
                (item) =>
                  !item.course || item.course.ooo || item.course.controlled
              )
              .sortBy((item) => item.course.sortId)
              .value(),
          };
        })
        .reject((o) => !o.courses || !o.courses.length)
        .value();
    },
  },
  watch: {
    bizId(oldVal, val) {
      if (oldVal != val) this.loadModifiers();
    },
  },
  methods: {
    async loadModifiers() {
      if (!this.bizId) return;
      const params = {
        criteria: { bizId: this.bizId, "modifier.ol": { $ne: false } },
      };
      const res = await this.axios.post("/bizModifiers/list", params);
      this.modifiers = _.chain(res.data)
        .pluck("modifier")
        .each((o) => {
          o.choices = _.reject(o.choices, (c) => c.ol === false);
        })
        .reject((o) => !o.choices || o.choices.length === 0)
        .value();
    },
  },
  mounted() {
    this.loadModifiers();
  },
};
</script>
