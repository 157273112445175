<template lang="pug">
.section-container.pb-3
  .event-title.mb-3 {{ event.title }}
  .biz-name.mb-3 {{ biz.name }}
  .section-title Date and time
  .section-description(v-for="(item, index) in time_items", :key="index") {{ item }}
  .mt-5
  .section-title Location
  .section-description {{ event.address.name }}
  .section-description {{ event.address | address }}
  .mt-5
  .section-title Refund Policy
  .section-description No Refunds
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz", "event"]),
    begin_date() {
      return moment(this.event.event_time.begin).format("ddd, MMM DD, YYYY");
    },
    begin_time() {
      return moment(this.event.event_time.begin).format("h:mm A");
    },
    end_date() {
      return moment(this.event.event_time.end).format("ddd, MMM DD, YYYY");
    },
    end_time() {
      return moment(this.event.event_time.end).format("h:mm A");
    },
    time_items() {
      if (this.begin_date == this.end_date)
        return [
          this.begin_date + " " + this.begin_time + " - " + this.end_time,
        ];
      return [
        this.begin_date + " " + this.begin_time,
        "to",
        this.end_date + " " + this.end_time,
      ];
    },
  },
};
</script>

<style scoped>
.section-container {
  border-bottom: 1px solid #DBDAE3;
}
.event-title {
  font-size: 18px;
  font-weight: 600;
  color: #413E56;
}
.biz-name {
  font-size: 15px;
  font-weight: 500;
  color: #8F90A0;
}
.section-title {
  font-size: 15px;
  font-weight: 600;
  color: #402F5A;
}
.section-description {
  font-size: 14px;
  font-weight: 400;
  color: #402F5A;
}
</style>