<template lang="pug">
.head
  .title {{ order.seller.name }}
  .detail
    div {{ order.seller.phone | phone }}
    div {{ order.seller.address | address(order.seller.industry) }}
      v-chip.ml-2(v-if="order.seller.industry", small, outlined) {{ order.seller.industry | bizIndustry }}
  .sl-dash-divider.my-3
  .detail
    .d-flex.flex-row
      v-icon.mr-2(small) mdi-receipt
      div \#{{ order.orderNumber }} | {{ ordererType }} |
        span.ml-1.text-capitalize {{ order.type | orderType }} |
        span.ml-1(:class="{ 'red--text': order.status == -1 }") {{ order.status | orderStatus }}
    .d-flex.flex-row
      v-icon.mr-2(small) mdi-clock-check-outline
      span {{ order.needed | datetime("MMM D, YYYY h:mm A", timezone) }} (estimated, {{ timezone }})
    .d-flex.flex-row
      v-icon.mr-2(small) mdi-account
      span {{ order.customer.name }} {{ order.customer.phone | phone }}
    .d-flex.flex-row.align-start(v-if="order.customer.address")
      v-icon.mr-2(small) mdi-map-marker
      div
        div {{ order.customer.address | addressStreet }}
        div {{ order.customer.address | cityState }}
        .success--text {{ order.customer.address.note }}
</template>

<script>
import moment from "moment-timezone";

export default {
  props: ["order"],
  computed: {
    ordererType() {
      if (this.order && this.order.orderer) {
        if (this.order.orderer.type == "0") return "Online";
        if (this.order.orderer.type == "2") return "Third Party";
      }
      return "In Store";
    },
    timezone() {
      return this.order?.seller?.address?.timezone || moment.tz.guess();
    },
  },
};
</script>

<style scoped>
.head {
  padding: 30px 40px 20px 40px;
}
.title {
  font-weight: 500;
  font-size: 18px;
}
.detail {
  font-size: 14px;
  color: rgba(28, 31, 52, 0.6);
}
</style>
