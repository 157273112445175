<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="promotion")
    v-toolbar(flat, dense)
      .subtitle-2 {{ promotion.title }}
      v-spacer
      ClaimAction(:item="promotion")
    v-card-text.text-center
      div {{ promotion.desc }}
      .green--text.headline
        span(v-if="promotion.type == 'pct'") {{ -promotion.pct }}%
        span(v-else) {{ -promotion.fix | currency }}
      div(v-if="promotion.chain") Apply to Entire Chain
      div Total Coupons: {{ promotion.total }}
      div Minimum Purchase: {{ promotion.minimum | currency }}
      div Expires on {{ promotion.expiry | date }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import ClaimAction from "./ClaimAction";

export default {
  components: { ClaimAction },
  data() {
    return {
      dialog: false,
      promotion: null,
    };
  },
  methods: {
    async open(data) {
      if (!data) return;
      this.promotion = data;
      this.dialog = true;
    },
  },
  mounted() {
    EventBus.$on("show-coupon", this.open);
  },
  destroyed() {
    EventBus.$off("show-coupon");
  },
};
</script>
