<template lang="pug">
div(v-if="item")
  v-btn.text-notransform(
    @click.stop="claim()",
    small,
    color="#E4E6EB",
    depressed,
    rounded,
    v-if="item.user_claim == 0",
    :loading="loading"
  ) Claim Me
  .subtitle-2.success--text(v-else) Claimed
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["addClaim"]),
    async claim() {
      if (!this.$auth.check()) {
        this.$router.push("/login");
        return;
      }
      const params = {
        userId: this.$auth.user()._id,
        promoId: this.item._id,
      };
      this.loading = true;
      try {
        const res = await this.axios.post("/promotion/claim", params);
        this.addClaim(res.data);
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>
