import Company from './Selflane/index.vue'
import Press from './Press/index.vue'
import Privacy from './Privacy/index.vue'
import Terms from './Terms/index.vue'

export default [{
    path: '/company',
    name: 'Company',
    component: Company
}, {
    path: '/press',
    name: 'Press',
    component: Press
}, {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
}, {
    path: '/terms',
    component: Terms
}, {
    path: '/terms-of-service',
    name: 'Terms',
    component: Terms
}]