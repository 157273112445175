import Main from './index.vue'
import Home from './Home'
import Entry from './Entry/index.vue'
import Purchase from './Purchase/index.vue'
import Order from './Order/index.vue'
import CountDown from './CountDown/index.vue'

export default [{
    path: '/event',
    component: Main,
    children: [{
        path: '',
        component: Home,
    }, {
        path: ':id',
        component: Entry,
        props: true
    }, {
        path: ':id/purchase',
        component: Purchase,
        props: true,
        meta: {
            auth: true
        }
    }]
}, {
    path: '/evorder/:id',
    component: Order,
    props: true
}, {
    path: '/event_count/:id',
    component: CountDown,
    props: true
}]