<template lang="pug">
.cell(@click="toChain(chain.url)")
  .img-container
    v-img(:src="mediaHost + chain.logo", height="100%", contain)
  .name {{ chain.name }}
</template>

<script>
export default {
  props: ["chain"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  methods: {
    toChain(url) {
      this.$router.push("/chains/" + url);
    },
  },
};
</script>

<style scoped>
.cell {
  overflow: hidden;
  width: 140px;
  margin-right: 20px;
  cursor: pointer;
}

.img-container {
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100px;
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
}

.name {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
  color: #1c1f34;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.desc {
  font-size: 14px;
  color: rgba(28, 31, 52, 0.6);
  line-height: 21px;
  margin-top: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
