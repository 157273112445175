<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between.flex-wrap
    .sl-title Favorites
  v-divider.my-2
  BizList(:bizs="bizs", :loading="loading")
</template>

<script>
import BizList from "@/components/Bizs/List";

export default {
  components: { BizList },
  data() {
    return {
      bizs: [],
      loading: false,
    };
  },
  methods: {
    async load() {
      if (!this.$auth.user()) return;
      const myfavs = this.$auth.user().preference.myfavs;
      const params = {
        criteria: {
          _id: { $in: myfavs },
          "status.byAdmin": 1,
          "status.byUser": 1,
        },
        select: "name logo url address cates schedule stats",
      };
      this.loading = true;
      const res = await this.axios.post("/bizs/list", params);
      this.bizs = res.data;
      this.loading = false;
    },
  },
  mounted() {
    this.load();
  },
};
</script>
