<template lang='pug'>
.payment
  .my-2
    .item
      .item-name Subtotal
      .item-detail {{ order.payment.dish | currency }}
    .item
      .item-name
        span(v-if="service_fee_value == 0") Tax
        span(v-else) Tax and fees
      .item-detail {{ taxNService | currency }}
    .item(v-if="order.payment.gift > 0")
      .item-name Gift
      .item-detail {{ order.payment.gift | currency }}
    .item(v-if="deliveryFee > 0")
      .item-name.pr-5
        span(v-if="order.deliv") Delivery fee
        span(v-else) Fee
      .item-detail {{ deliveryFee | currency }}
    .item(v-if="distanceSurcharge > 0")
      .item-name.pr-5
        span(v-if="order.deliv") Distance Surcharge
        span(v-else) Fee
      .item-detail {{ distanceSurcharge | currency }}
    .item(v-if="tip > 0")
      .item-name.pr-5 Tip
      .item-detail {{ tip | currency }}
    .item.success--text(
      v-for="(item, index) in order.payment.deduction.items",
      :key="index",
      v-if="order.payment.deduction"
    )
      .item-name.pr-5 {{ item.name }}
      .item-detail {{ -item.value | currency }}
    .item
      .item-name.body-2 Total
      .item-detail.font-weight-bold {{ order.payment.total | currency }}
  .sl-dash-divider
  .body-2.my-2
    div Payments
    div(v-if="order.splitChecks && order.splitChecks.length > 0")
      div(v-for="(item, index) in order.splitChecks", :key="index")
        span.body-2 Check \#{{ index + 1 }}: {{ item.payment.total | currency }}
        div(v-if="item.payment.charge.amount > 0") Online
          span(v-if="item.payment.card") (...{{ item.payment.card.last4 }})
          span : {{ item.charge.amount | currency }}
        div(v-for="(c, cindex) in item.payment.charges", :key="cindex") {{ c.method }} {{ c.last4 }}: {{ c.amount | currency }}
    div(v-else)
      div(v-if="order.payment.charge.amount > 0") Online
        span.ml-1(v-if="order.payment.card") (...{{ order.payment.card.last4 }})
        span : {{ order.payment.charge.amount | currency }}
      div(v-for="(c, cindex) in order.payment.charges", :key="cindex") {{ c.method }} {{ c.last4 }}: {{ c.amount | currency }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["order"],
  computed: {
    service_fee_value() {
      if (!this.order) return 0;
      const service_fee = _.find(
        this.order.payment.selflane.items,
        (o) => o.name == "service_fee"
      );
      if (service_fee) return service_fee.value;
      return 0;
    },
    taxNService() {
      if (!this.order) return 0;
      return this.order.payment.tax + this.service_fee_value;
    },
    deliveryFee() {
      if (!this.order) return 0;
      const found = _.find(
        this.order.payment.selflane.items,
        (o) => o.name == "delivery_fee"
      );
      if (found) return found.value;
      return this.order.payment.fee;
    },
    distanceSurcharge() {
      if (!this.order) return 0;
      const found = _.find(
        this.order.payment.selflane.items,
        (o) => o.name == "distance_surcharge"
      );
      if (found) return found.value;
      return 0;
    },
    tip() {
      if (!this.order) return 0;
      const found = _.find(
        this.order.payment.selflane.items,
        (o) => o.name == "delivery_tip"
      );
      if (found) return found.value;
      return this.order.payment.tip;
    },
  },
};
</script>

<style scoped>
.payment {
  padding-left: 40px;
  padding-right: 40px;
}
.item {
  display: flex;
  align-items: center;
}
.item-name {
  font-size: 15px;
  flex-grow: 1;
}
.item-detail {
  font-size: 15px;
}
</style>