<template lang="pug">
.sticky.menu-top-bar
  v-menu.left-btn(offset-y)
    template(v-slot:activator="{ on }") 
      v-btn(icon, v-on="on")
        v-icon(small) mdi-format-list-bulleted
    v-list(dense, max-height="300")
      v-list-item.white(
        v-for="(item, index) in items",
        :key="index + 'menu'",
        v-scroll-to="{el: item.id, offset: -106}",
        @click="handleClick(index)"
      ) {{ item.name }}
  v-slide-group.right-group(
    :show-arrows="show_arrow",
    center-active,
    v-model="selectedIndex"
  )
    v-slide-item(v-for="(item, index) in items", :key="index")
      button.button(
        v-scroll-to="{el: item.id, offset: -106}",
        :class="{ active: index == selectedIndex }",
        @click="handleClick(index)"
      ) {{ item.name }}
</template>

<script>
import _ from "underscore";
export default {
  props: ["menus"],
  data() {
    return { selectedIndex: 0, show_arrow: true };
  },
  computed: {
    items() {
      const data = _.chain(this.menus)
        .map((menu) => {
          return _.map(menu.courses, (course) => {
            return {
              name: course.course.name,
              id: "#course_" + course.course._id + menu.menu._id,
            };
          });
        })
        .flatten()
        .value();
      return data;
    },
  },
  methods: {
    handleClick(index) {
      this.selectedIndex = index;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 4;
  background: #F0F2F5;
  border-bottom: 1px solid #E7E7E7;
  margin-bottom: 12px;
}
.menu-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.button {
  font-size: 16px;
  font-weight: 400;
  color: #494949;
  padding-top: 11px;
  padding-bottom: 6px;
  margin-right:22px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 5px solid #F0F2F5;
}
.active {
  font-weight: 500;
  color: #202020;
  border-bottom: 5px solid #202020;
}
.left-btn {
  flex: 0 0 30px;
  overflow: hidden;
}
.right-group {
  flex: 1;
  overflow: hidden;
}
</style>