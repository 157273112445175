<template lang="pug">
div(style="width: 100px")
  v-select(:items="years", v-model="selected", hide-details, outlined, dense)
</template>

<script>
import _ from "underscore";

export default {
  name: "YearSelector",
  data() {
    return {
      selected: new Date().getFullYear(),
      years: _.range(new Date().getFullYear(), 2015),
    };
  },
  watch: {
    selected() {
      this.send();
    },
  },
  methods: {
    send() {
      this.$emit("select", this.selected);
    },
  },
  mounted() {
    // send in initiating
    this.send();
  },
};
</script>
