<template lang="pug">
div(v-if="biz")
  .d-flex.flex-row.align-center.my-2(v-if="phone")
    img.mr-3(:src="phoneIcon")
    .subtitle-2 {{ phone | phone }}
  .d-flex.flex-row.align-center.my-2
    img.mr-3(:src="addressIcon")
    .subtitle-2 {{ biz.address | address(biz.industry) }}
    .caption.ml-2(v-if="biz.industry != 0", small, outlined) \#{{ biz.industry | bizIndustry }}
    .caption.text--secondary.ml-2(v-if="distance") {{ distance | readableDistance(unit) }}
</template>

<script>
import BizUtils from "@/utils/biz.js";
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      distance: null,
      phoneIcon:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Frestaurant_phone_icon.svg?alt=media&token=6705ba38-f516-4bf7-86d1-77492ccbdab1",
      addressIcon:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Frestaurant_add_icon.svg?alt=media&token=34aee89e-4e4f-491e-9b83-e120f7181a0d",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    phone() {
      if (!this.biz) return null;
      const first = _.first(this.biz.phones);
      if (first) return first.number;
      else return null;
    },
    unit() {
      if (this.biz && this.biz.address && this.biz.address.country == "CA") {
        return "km";
      }
      return "mi.";
    },
  },
  methods: {
    calcDistance() {
      if (!this.biz || !this.biz.address) return;
      const address = this.biz.address;
      navigator.geolocation.getCurrentPosition((position) => {
        this.distance = BizUtils.geoDistanceInMeter(address.geometry, {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    },
  },
  mounted() {
    this.calcDistance();
  },
};
</script>
