<template lang="pug">
div
  Accounts
  Activities
  PointShop
  Coupons
  .text-center.my-5
    Subscription
</template>

<script>
import Accounts from "@/components/Member/Accounts";
import Subscription from "@/components/Member/Subscription";
import Activities from "@/components/Member/Activities";
import PointShop from "@/components/Member/PointShop";
import Coupons from "@/components/Member/Coupons/index";
import { mapActions } from "vuex";
import _ from "underscore";

export default {
  components: { Accounts, Activities, PointShop, Coupons, Subscription },
  methods: {
    ...mapActions(["setMemberAccounts", "setBizAccounts"]),
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      const options = { criteria: { user: user._id } };
      const res = await this.axios.post("/member/list", options);
      this.setMemberAccounts(res.data);
      this.loadBizAccounts(res.data);
    },
    async loadBizAccounts(members) {
      const ids = _.chain(members).pluck("biz").uniq().value();
      const params = {
        criteria: { _id: { $in: ids } },
        select: "name phones address url",
      };
      const res = await this.axios.post("/bizs/list", params);
      this.setBizAccounts(res.data);
    },
  },
  mounted() {
    this.load();
  },
};
</script>