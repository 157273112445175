<template lang="pug">
v-btn(icon, @click.stop="handleRemove()")
  v-icon(color="grey", small) mdi-delete
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["source"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["removeStripeSource"]),
    handleRemove() {
      confirm("Remove this card?") && this.remove();
    },
    remove() {
      if (!this.source) return;
      const user = this.$auth.user();
      const sourceId = this.source.id;
      if (!user) return;
      if (!sourceId) return;
      this.loading = true;
      this.axios
        .post("/stripe/sources/delete", {
          customerId: user.payments.customer,
          sourceId: sourceId,
        })
        .then(
          () => {
            this.loading = false;
            this.removeStripeSource(this.source);
          },
          (err) => {
            this.loading = false;
            if (err.response) alert(err.response.data);
          }
        );
    },
  },
};
</script>
