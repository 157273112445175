<template lang="pug">
.success--text.subtitle-2(v-if="hasDelivery") {{ text }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
export default {
  computed: {
    ...mapGetters(["biz", "cartGeoService"]),
    deliveryFee() {
      if (this.biz.orderType.delivery.deliv && this.cartGeoService) {
        return this.cartGeoService.delivery.fee;
      }
      return this.biz.orderType.delivery.price;
    },
    hasDelivery() {
      return (
        this.biz &&
        this.biz.orderType &&
        this.biz.orderType.delivery &&
        this.biz.orderType.delivery.status == true
      );
    },
    text() {
      let list = [];
      const price = this.deliveryFee;
      if (price == 0) return "Free Delivery";
      else list.push("Delivery: $" + price);
      if (!this.biz.delivTiers) return list.join(" ");
      _.each(this.biz.delivTiers, (o) => {
        let remain = Math.round((price - o.deduction) * 100) / 100;
        if (remain > 0) list.push("At $" + o.threshold + ": $" + remain);
        else list.push("At $" + o.threshold + ": Free delivery");
      });
      return list.join("; ");
    },
  },
};
</script>
