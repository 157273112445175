<template lang="pug">
div
  div
    .font-weight-medium.my-2 {{ data.course.name }}
      span.ml-1(v-if="isAlcohol") (Alcohol)
    .body-2.text--secondary.my-2(v-if="data.course.description") {{ data.course.description }}
  v-row(wrap, dense)
    v-col(
      cols="12",
      sm="6",
      v-for="(dish, index) in data.dishes",
      :key="dish._id"
    )
      DishCell(:dish="dish", @select="select(dish)")
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus.js";
import DishCell from "./DishCell";

export default {
  props: ["data", "modifiers"],
  components: { DishCell },
  computed: {
    isAlcohol() {
      return (
        this.data && this.data.course && this.data.course.classification == 3
      );
    },
  },
  methods: {
    select(dish) {
      dish.modifiers = [];
      _.chain(dish.modifierIDs)
        .uniq()
        .each((id) => {
          const found = _.find(this.modifiers, (o) => o._id === id);
          if (found && found.choices && found.choices.length)
            dish.modifiers.push(found);
        });
      const selectedDish = JSON.parse(JSON.stringify(dish));
      selectedDish.quantity = 1;
      EventBus.$emit("show-dish", selectedDish);
    },
  },
};
</script>
