<template lang="pug">
v-container
  v-row(wrap)
    v-col(cols="12", md="3", v-for="(item, index) in items", :key="index")
      Cell(:item="item")
</template>

<script>
import Cell from "./Cell";
import _ from "underscore";
import { mapActions } from "vuex";

export default {
  components: { Cell },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    ...mapActions(["setBizAccounts"]),
    async load() {
      const now = Date.now();
      const params = {
        criteria: {
          "sale_time.begin": { $lte: now },
          "sale_time.end": { $gte: now },
        },
      };
      const res = await this.axios.post("/event/entries/list", params);
      this.items = res.data;
      this.loadBizs();
    },
    async loadBizs() {
      if (!this.items) return;
      const ids = _.pluck(this.items, "biz_id");
      const params = {
        criteria: { _id: { $in: ids }, select: "name address" },
      };
      try {
        const res = await this.axios.post("/bizs/list", params);
        this.setBizAccounts(res.data);
      } catch (err) {
        //
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>