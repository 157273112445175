<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between.my-5
    .sl-title Addresses
    v-btn.text-capitalize(
      outlined,
      small,
      @click="create()",
      color="secondary"
    ) Add
      v-icon(small) mdi-plus
  v-card(flat, v-if="addresses && addresses.length")
    Cell(
      v-for="(item, index) in addresses",
      :key="index",
      :address="item",
      @edit="edit(item)",
      @remove="handleRemove(item._id)"
    )
  v-dialog(v-model="dialog", max-width="500", @keydown.esc="dialog = false")
    GoogleAddressForm(:address="address", @save="save")
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  data() {
    return {
      dialog: false,
      address: {},
      loading: false,
    };
  },
  computed: {
    addresses() {
      if (this.$auth.user()) {
        return this.$auth.user().addresses;
      } else return [];
    },
  },
  methods: {
    create() {
      this.address = {};
      this.dialog = true;
    },
    edit(data) {
      this.address = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    handleRemove(id) {
      confirm("Remove this address?") && this.remove(id);
    },
    remove(id) {
      const user = this.$auth.user();
      if (!user) return;
      const data = {
        criteria: { _id: user._id },
        action: { $pull: { addresses: { _id: id } } },
      };
      this.update(data);
    },
    save(address) {
      this.dialog = false;
      const user = this.$auth.user();
      if (!user) return;
      if (this.address._id) {
        const data = {
          criteria: { _id: user._id, "addresses._id": this.address._id },
          action: { $set: { "addresses.$": address } },
        };
        this.update(data);
      } else {
        const data = {
          criteria: { _id: user._id },
          action: { $push: { addresses: address } },
        };
        this.update(data);
      }
    },
    update(params) {
      this.loading = true;
      this.axios
        .post("/users/update", params)
        .then((response) => {
          this.dialog = false;
          this.loading = false;
          this.$auth.user(response.data);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            alert(err.response.data);
          }
        });
    },
  },
};
</script>
