import _ from 'underscore'

const state = {
  stripeSources: []
}

const getters = {
  stripeSources: state => state.stripeSources
}

const actions = {
  setStripeSources: ({
    commit
  }, stripeSources) => {
    commit('setStripeSources', stripeSources)
  },
  addStripeSource: ({
    commit
  }, data) => {
    commit('addStripeSource', data)
  },
  updateStripeSource: ({
    commit
  }, data) => {
    commit('updateStripeSource', data)
  },
  removeStripeSource: ({
    commit
  }, data) => {
    commit('removeStripeSource', data)
  }
}

const mutations = {
  setStripeSources(state, stripeSources) {
    state.stripeSources = stripeSources
  },
  addStripeSource(state, data) {
    state.stripeSources.push(data)
  },
  updateStripeSource(state, data) {
    state.stripeSources = _.map(state.stripeSources, o => {
      if (o.id === data.id) return data
      return o
    })
  },
  removeStripeSource(state, data) {
    state.stripeSources = _.reject(state.stripeSources, o => {
      return (o.id === data.id)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}