<template lang="pug">
.time-day-container(v-on="$listeners")
  .body-2.text--secondary {{ item.ddd }}
  .time-day(:class="{ selected: isSelected, today: isToday }") {{ item.dayofmonth }}
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  name: "TimeDayCell",
  props: ["item", "needed", "timezone"],
  computed: {
    ...mapGetters(["cartOrder", "biz"]),
    isSelected() {
      return (
        moment.tz(this.needed, this.timezone).format("YYYY-MM-DD") ==
        this.item.day
      );
    },
    isToday() {
      return moment().tz(this.timezone).format("YYYY-MM-DD") == this.item.day;
    },
  },
};
</script>

<style scoped>
.time-day-container {
  text-align: center;
}
.time-day {
  margin-top: 8px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
  font-size: 17px;
  font-weight: 500;
}
.time-day.selected {
  background-color: #FF6D32;
  color: white;
}
.time-day.today {
  border: 1px #FF6D32 solid;
}
</style>
