<template lang="pug">
div
  v-icon.ml-1(
    v-for="(tag, index) in tags",
    :key="index",
    small,
    :color="tag.color",
    :title="tag.title"
  ) {{ tag.icon }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["item"],
  data() {
    return {
      tagItems: [
        {
          icon: "mdi-barley-off",
          color: "green",
          value: "gf",
          title: "Gluten Free",
        },
        { icon: "mdi-sprout", color: "green", value: "vegan", title: "Vegan" },
        {
          icon: "mdi-chili-mild",
          color: "red",
          value: "spicy-mild",
          title: "Spicy Mild",
        },
        {
          icon: "mdi-chili-medium",
          color: "red",
          value: "spicy-medium",
          title: "Spicy Medium",
        },
        {
          icon: "mdi-chili-hot",
          color: "red",
          value: "spicy-hot",
          title: "Spicy Hot",
        },
      ],
    };
  },
  computed: {
    tags() {
      if (!this.item || !this.item.tags) return "";
      const data = _.chain(this.item.tags)
        .map((o) => {
          return _.find(this.tagItems, (item) => item.value == o);
        })
        .compact()
        .value();
      return data;
    },
  },
};
</script>
