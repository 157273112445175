<template lang="pug">
div.my-3.white(v-if="bizs && bizs.length")
  v-container
    .sl-title Virtual Kitchen
    BizList(:bizs="bizs")
</template>

<script>
import { mapGetters } from "vuex";
import BizList from "@/components/Bizs/ListRow";
import _ from "underscore";

export default {
  components: { BizList },
  computed: {
    ...mapGetters(["bizAccounts"]),
    bizs() {
      return _.filter(this.bizAccounts, (o) => {
        return o.industry == 4;
      });
    },
  },
};
</script>
