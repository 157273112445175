<template lang="pug">
v-container
  v-card(v-if="event && biz", outlined)
    v-card-text
      v-btn.mb-3(icon, @click="handleReturn")
        v-icon mdi-arrow-left-circle
      Info
      Items
      PaymentBlock.mb-5
      v-row.mb-5
        v-col(cols="6")
          Name
        v-col(cols="6")
          Phone
      .d-flex.flex-row
        v-btn.px-10(
          @click="handleSubmit()",
          :loading="loading",
          depressed,
          color="primary",
          :disabled="!validCart"
        ) SUBMIT
        v-spacer
        v-btn(@click="resetEventCart()", icon)
          v-icon mdi-delete
  v-dialog(v-model="dialog", persistent, max-width="450")
    v-card
      v-toolbar(color="success", flat, dark)
        .subtitle-2 Thanks You!
        v-spacer
        v-btn.text-capitalize(outlined, to="/user/wallet/event", rounded) Go to My Tickets
      v-card-text
        div You're purchased {{ total_purchased_tickets }} tickets.
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Info from "./Info";
import Items from "./Items";
import PaymentBlock from "./PaymentBlock";
import Name from "./Name";
import Phone from "./Phone";
import _ from "underscore";

export default {
  props: ["id"],
  components: { Info, Items, PaymentBlock, Name, Phone },
  data() {
    return {
      loading: false,
      dialog: false,
      order: null,
    };
  },
  computed: {
    ...mapGetters(["biz", "event", "eventCart"]),
    total_purchased_tickets() {
      if (!this.order) return 0;
      return _.reduce(
        this.order.items,
        (memo, item) => {
          return memo + item.quantity;
        },
        0
      );
    },
    validCart() {
      return (
        this.eventCart &&
        this.eventCart.event_id &&
        this.eventCart.event_items &&
        this.eventCart.event_items.length > 0 &&
        this.eventCart.user_name &&
        this.eventCart.user_name.length > 0 &&
        this.eventCart.user_phone &&
        this.eventCart.user_phone.length > 7
      );
    },
  },
  methods: {
    ...mapActions(["setBiz", "setEvent", "resetEventCart"]),
    async load() {
      if (!this.id) return;
      const params = { event_id: this.id };
      try {
        const res = await this.axios.post("/event/entries/retrieve", params);
        this.setEvent(res.data);
      } catch (err) {
        //
      }
      this.loadBiz();
    },
    async loadBiz() {
      if (!this.event) return;
      const params = { criteria: { _id: this.event.biz_id } };
      try {
        const res = await this.axios.post("/bizs/retrieve", params);
        this.setBiz(res.data);
      } catch (err) {
        //
      }
    },
    async handleSubmit() {
      this.loading = true;
      try {
        const res = await this.axios.post(
          "/event/orders/create",
          this.eventCart
        );
        this.order = res.data;
        this.resetEventCart();
        this.dialog = true;
      } catch (err) {
        if (err && err.response) alert(err.response.data);
      }
      this.loading = false;
    },
    handleReturn() {
      let path = this.$route.path.replace("/purchase", "");
      this.$router.push(path);
    },
  },
  mounted() {
    this.load();
  },
};
</script>
