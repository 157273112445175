<template lang="pug">
.items-container
  div(v-for="(dish, index) in order.dishes", :key="index + 'dish'")
    .item
      v-flex
        .item-name {{ dish.name }}
          span.ml-4.red--text(v-if="dish.pctOff > 0") {{ dish.pctOff }}% off
        .item-detail {{ dish.modifiers | modifiers }}
      div {{ dish.unitPrice | currency }} &#215; {{ dish.quantity }}
    .sl-dash-divider
  div(v-for="(item, index) in order.gifts", :key="index + 'gift'")
    .item
      v-flex
        .item-name Gift Card
      div {{ item.amount | currency }}
    .sl-dash-divider
  div(v-for="(item, index) in order.vouchers", :key="index + 'voucher'")
    .item
      v-flex
        .item-name {{ item.name }}
      .red--text {{ -item.amount | currency }}
    .sl-dash-divider
</template>

<script>
export default {
  props: ["order"],
};
</script>

<style scoped>
.items-container {
  padding-left: 40px;
  padding-right: 40px;
}
.item {
  display: flex;
  align-items: center;
  min-height: 54px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.item-name {
  font-size: 15px;
}
.item-detail {
  font-size: 14px;
  color: rgba(28, 31, 52, 0.6);
}
</style>
