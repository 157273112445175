<template lang="pug">
.coupon.elevation-3(v-if='promotion')
  CouponTop(:promotion='promotion' :bizs='bizs' :chains='chains')
  v-divider
  CouponBottom(:promotion='promotion' :claims='claims' @claimed='$emit("claimed")')
</template>

<script>
import CouponTop from "./Top";
import CouponBottom from "./Bottom";

export default {
  props: ["promotion", "claims", "bizs", "chains"],
  components: { CouponTop, CouponBottom }
};
</script>

<style scoped>
.coupon {
  border-radius: 20px;
  background-color: #31375d;
  color: white;
  overflow: hidden;
}
</style>
