<template lang="pug">
.d-flex.flex-row.my-3
  #app-links.mr-3
    a(
      v-for="(item, index) in appLinks",
      :key="index",
      :href="item.url",
      target="_blank"
    )
      img(:src="item.icon")
  v-spacer
  v-btn.text-capitalize(
    rounded,
    light,
    depressed,
    href="https://bizs.selflane.com/",
    target="_blank"
  ) How to Join?
</template>

<script>
export default {
  data() {
    return {
      appLinks: [
        {
          url: "https://itunes.apple.com/us/app/selflane/id1142403428?mt=8",
          icon:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fappstore_icon%402x.png?alt=media&token=5332b552-31ac-4bc1-9c5b-800ca64e5d57",
        },
        // {
        //   url: "",
        //   icon:
        //     "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fgoogle_play_icon%402x.png?alt=media&token=e8544f36-a37b-45d3-8525-1fccab4db73a"
        // }
      ],
    };
  },
};
</script>

<style scoped>
#app-links img {
  width: 135px;
  height: 40px;
}
</style>
