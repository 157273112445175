<template lang="pug">
.main-container(:style="bkg_style")
  RaisedCountUp(v-if="event")
  TimeCountDown
  Message
  QRCode
</template>

<script>
import TimeCountDown from "./TimeCountDown";
import RaisedCountUp from "./RaisedCountUp";
import Message from "./Message.vue";
import QRCode from "./QRCode";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["id"],
  components: { TimeCountDown, RaisedCountUp, Message, QRCode },
  computed: {
    ...mapGetters(["event"]),
    bkg_style() {
      let count_down_bkg = this.event && this.event.count_down_bkg;
      if (!count_down_bkg) return;
      return (
        "background-image: url('https://media.selflane.com/" +
        count_down_bkg +
        "')"
      );
    },
  },
  watch: {
    url(val, oldVal) {
      if (val && val != oldVal) {
        this.load();
      }
    },
  },
  methods: {
    ...mapActions(["setEvent"]),
    async load() {
      if (!this.id) return;
      const params = { event_id: this.id };
      try {
        const res = await this.axios.post("/event/entries/retrieve", params);
        this.setEvent(res.data);
      } catch (err) {
        //
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.main-container {
  position: relative; 
  height: 100%;
}
.main-container::before {
  content: "";
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.9;
}
</style>