<template lang="pug">
.item-row
  .item-text
    .subtitle-2 {{ address | addressStreet }}
    .body-2 {{ address | cityState }}
    .body-2 {{ address.note }}
  div
    v-btn(icon, @click.stop="edit()")
      v-icon(color="grey", small) mdi-pencil
    v-btn(icon, @click.stop="remove()")
      v-icon(color="grey", small) mdi-delete
</template>

<script>
export default {
  props: ["address"],
  methods: {
    edit() {
      this.$emit("edit");
    },
    remove() {
      this.$emit("remove");
    },
  },
};
</script>

<style scoped>
.item-row {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 12px;
}
.item-text {
  flex-grow: 1;
  padding-right: 8px;  
}
</style>
