<template lang="pug">
v-badge(overlap, color="#FF6D32", offset-x="20", offset-y="23")
  template(v-slot:badge)
    span(v-if="hasCart") {{ cartCount }}
  v-btn(icon, @click="showCart()", color="primary")
    v-icon mdi-cart
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "NavBarCart",
  computed: {
    ...mapGetters(["cartOrder", "cartCount"]),
    hasCart() {
      return this.cartCount && this.cartCount > 0;
    },
  },
  methods: {
    showCart() {
      if (this.hasCart) EventBus.$emit("show-cart-sheet");
    },
  },
};
</script>
