<template lang="pug">
Cell(v-on="$listeners")
  template(slot="name-block")
    span {{ name }}
    .sl-secondary-text {{ dish.modifiers | modifiers }}
  template(slot="price-block")
    div {{ dish.unitPrice | currency }}
    .red--text(v-if="dish.pctOff > 0") -{{ ((dish.pctOff * dish.unitPrice * dish.quantity) / 100) | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Cell from "./Cell";

export default {
  props: ["dish"],
  components: { Cell },
  computed: {
    ...mapGetters(["biz"]),
    isAlcohol() {
      if (!this.dish || !this.biz || !this.dish.course) return false;
      const course = _.find(this.biz.courses, (o) => o._id == this.dish.course);
      if (course) return course.classification == 3;
      return false;
    },
    name() {
      return this.dish.name + (this.isAlcohol ? " (Alcohol)" : "");
    },
  },
};
</script>
