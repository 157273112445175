<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  template(v-slot:activator="{ on }")
    v-icon(v-if="roundIcon", v-on="on", @click="add()", :loading="loading") mdi-plus
    v-btn.text-capitalize(
      v-else,
      outlined,
      small,
      v-on="on",
      :loading="loading",
      color="secondary"
    ) Add
      v-icon(small) mdi-plus
  GoogleAddressForm(@save="save", :address="address")
</template>
<script>
import _ from "underscore";

export default {
  name: "CreateAddress",
  props: ["roundIcon"],
  data() {
    return {
      dialog: false,
      address: {},
      loading: false,
    };
  },
  methods: {
    add() {
      this.address = {};
      this.dialog = true;
    },
    async save(address) {
      this.loading = true;
      const user = this.$auth.user();
      if (!user) return;
      const params = {
        criteria: { _id: user._id },
        action: { $push: { addresses: address } },
      };
      try {
        const res = await this.axios.post("/users/update", params);
        this.dialog = false;
        this.$auth.user(res.data);
        this.$emit("saved", _.last(res.data.addresses));
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>
