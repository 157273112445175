<template lang="pug">
.company-body
  vue-headful(
    title="Press & Media",
    keywords="Selflane,Restaurants Software,News"
  )
  Head
  News
</template>

<script>
import Head from "./Head";
import News from "./News";

export default {
  components: { Head, News },
};
</script>

<style scoped>
.company-body {
  background-color: white;
  height: 100%;
}
</style>
