<template lang="pug">
div(v-if="orders && orders.length > 0")
  .subtitle-2.my-3 Past Orders
  Cell.mb-3(:order="item", v-for="item in items", :key="item._id")
  .text-center.my-5
    v-pagination(:length="length", v-model="page", v-if="length", circle)
</template>

<script>
import Cell from "./../Cell/index";
import _ from "underscore";

export default {
  props: ["orders"],
  components: { Cell },
  data() {
    return {
      page: 1,
      pageMax: 6,
    };
  },
  computed: {
    items() {
      const start = (this.page - 1) * this.pageMax;
      return _.chain(this.orders).rest(start).first(this.pageMax).value();
    },
    length() {
      return Math.ceil(this.orders.length / this.pageMax);
    },
  },
};
</script>
