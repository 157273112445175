<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card(v-if="source")
    v-toolbar(flat, dense)
      .subtitle-2 Update Credit Card {{ source.brand }} ending in {{ source.last4 }}
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(label="Name on Card", v-model="name")
        v-row
          v-col(cols="6")
            v-select(label="Expiry Month", v-model="mm", :items="month_items")
          v-col(cols="6")
            v-select(label="Expiry Year", v-model="yyyy", :items="year_items")
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      source: null,
      name: "",
      yyyy: new Date().getFullYear() + 1,
      mm: 1,
      month_items: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      year_items: _.range(
        new Date().getFullYear(),
        new Date().getFullYear() + 11
      ),
    };
  },
  methods: {
    ...mapActions(["updateStripeSource"]),
    open(data) {
      if (!data) return;
      this.source = data;
      this.dialog = true;
    },
    async submit() {
      if (!this.source) return;
      this.loading = true;
      const params = {
        customerId: this.source.customer,
        sourceId: this.source.id,
        action: {
          name: this.name,
          exp_month: this.mm,
          exp_year: this.yyyy,
        },
      };
      try {
        const res = await this.axios.post("/stripe/sources/update", params);
        this.updateStripeSource(res.data);
        this.dialog = false;
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("edit-stripe-source", this.open);
  },
  destroyed() {
    EventBus.$off("edit-stripe-source");
  },
};
</script>