<template lang="pug">
v-dialog(v-model="dialog", max-width="390")
  template(v-slot:activator="{ on }")
    v-btn.text-capitalize(outlined, small, v-on="on", color="secondary") Change
  v-card
    v-form(@submit.prevent="reset")
      v-toolbar(flat, dense)
        .subtitle-2 Change Password
      v-divider.mb-3
      v-card-text
        .d-flex.flex-row.align-center 
          .subtitle-2 Step 1
          v-spacer
          v-btn(
            small,
            rounded,
            color="secondary",
            :loading="loadRequest",
            @click="requestCode"
          ) Request a Code
        v-alert.my-3(type="success", v-if="codeRequested", dense) Please find the code in your email.
        .d-flex.flex-row.align-center.my-3
          .subtitle-2 Step 2
          v-spacer
          span Enter the code and new password
        v-text-field(
          label="Code",
          v-model="code",
          v-mask="\"### ### ###\"",
          required,
          persistent-hint,
          hint="Found in email",
          prepend-icon="code"
        ) 
        v-text-field(
          type="password",
          label="New Password",
          v-model="password",
          required,
          @blur="$v.password.$touch()",
          prepend-icon="lock_outline"
        ) 
        v-btn(
          type="submit",
          block,
          :loading="loading",
          :disabled="!valid",
          color="secondary"
        ) Save
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      codeRequested: false,
      loadRequest: false,
      code: "",
      password: "",
      loading: false,
    };
  },
  computed: {
    valid() {
      return (
        this.code && this.code.length && this.password && this.password.length
      );
    },
  },
  methods: {
    async requestCode() {
      const user = this.$auth.user();
      if (!user || !user.email) this.error = null;
      const options = { criteria: { email: user.email } };
      this.loadRequest = true;
      try {
        await this.axios.post("/users/forgetPWD", options);
        this.codeRequested = true;
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.loadRequest = false;
    },
    async reset() {
      const user = this.$auth.user();
      if (!user || !user.email) this.error = null;
      const options = {
        criteria: { email: user.email },
        code: this.code.replace(/[^\d]/g, ""),
        password: this.password,
      };
      this.loading = true;
      try {
        await this.axios.post("/users/resetPWD", options);
        alert("Your password is reset");
        this.dialog = false;
        this.code = "";
        this.password = "";
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>
