<template lang="pug">
div(v-if="cards && cards.length && !cartPromotion")
  v-list.mb-3.pa-0(dense, dark, color="success")
    v-list-item(v-for="item in cartOrder.payment.charges", :key="item.transId")
      v-list-item-content Applied eGift Card {{ item.amount | currency }}
      v-list-item-action
        v-btn(icon, small, @click="removeGC(item.transId)")
          v-icon mdi-close
  .d-flex.flex-row.align-center.mb-3
    v-icon.mr-3 mdi-gift
    .subtitle-2 Pay with Gift Card
  v-row.card-box(wrap, dense)
    v-col(cols="4", md="3", v-for="item in cards", :key="item._id")
      v-btn.mr-3.mb-1(
        @click="applyGC(item)",
        block,
        outlined,
        color="secondary"
      ) {{ item.balance | currency }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  data() {
    return { cards: [] };
  },
  computed: {
    ...mapGetters(["cartOrder", "cartPromotion"]),
  },
  methods: {
    ...mapActions(["applyGC", "removeGC"]),
    load() {
      if (!this.$auth.user()) {
        this.cards = [];
        return;
      }
      const order = this.cartOrder;
      if (
        !order ||
        !order.seller ||
        !order.seller.id ||
        (order.gifts && order.gifts.length > 0)
      ) {
        this.cards = [];
        return;
      }
      const options = {
        criteria: {
          acceptors: { $elemMatch: { $eq: order.seller.id } },
          owner: this.$auth.user()._id,
          balance: { $gt: 0 },
        },
        select: "balance to",
      };
      this.axios
        .post("/giftCard/list", options)
        .then((response) => {
          this.cards = _.reject(response.data, (o) => o.to && o.to.length > 0);
        })
        .catch((err) => {
          this.cards = [];
          if (err.response) alert(err.response.data);
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.card-box {
  max-height: 100px;
  overflow-y: scroll;
}
</style>
