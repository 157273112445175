<template lang="pug">
v-dialog(v-model="dialog", width="450")
  template(v-slot:activator="{ on }")
    v-btn.ml-2(rounded, depressed, small, color="#E4E6EB", v-on="on")
      v-icon(small) mdi-share
  v-card
    v-card-title
    v-card-text.text-center
      .subtitle-2 Share Me
      .my-3
        ShareNetwork.ma-3(
          network="facebook",
          :url="url",
          :title="title",
          hashtags="#selflane"
        )
          i.fab.fah.fa-facebook.fa-2x(style="color: #3b5998")
        ShareNetwork.ma-3(
          network="twitter",
          :url="url",
          :title="title",
          hashtags="#selflane"
        )
          i.fab.fah.fa-twitter.fa-2x(style="color: #38a1f3")
      span Share this restaurant with your friends. Let them explore the food you enjoy.
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    url() {
      if (!this.biz) return "";
      return "https://selflane.com/bizs/" + this.biz.url;
    },
    title() {
      if (!this.biz) return "";
      return this.biz.name;
    },
  },
};
</script>
