<template lang="pug">
.coupon-bottom
  v-btn(
    block,
    @click.stop="claim",
    outlined,
    rounded,
    color="primary",
    small,
    dark,
    v-if="!claimed"
  ) Claim Me
  v-btn(v-else, block, rounded, depressed, color="primary", small, dark) Claimed
</template>

<script>
import _ from "underscore";

export default {
  props: ["promotion", "claims"],
  computed: {
    claimed() {
      /// 0: not claimed, 1: claimed, 2: applied
      if (!this.promotion || !this.claims) return 0;
      const found = _.find(
        this.claims,
        (o) => o.promotion == this.promotion._id
      );
      if (found) {
        if (found.applied > 0) return "Applied";
        else return "Claimed";
      }
      return null;
    },
  },
  methods: {
    claim() {
      if (!this.promotion) return;
      if (!this.$auth.check()) {
        this.$router.push("/login");
        return;
      }
      const data = {
        userId: this.$auth.user()._id,
        promoId: this.promotion._id,
      };
      this.axios
        .post("/promotion/claim", data)
        .then(() => {
          this.$emit("claimed");
        })
        .catch((err) => {
          if (err.response) alert(err.response.data);
          this.$emit("claimed");
        });
    },
  },
};
</script>

<style scoped>
.coupon-bottom {
  width: 96px;
  height: 33px;
}
</style>
