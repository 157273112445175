<template lang="pug">
v-container(v-if="promotions && promotions.length")
  .sl-title Coupon Island
  v-slide-group(:show-arrows="show_arrow", ref="coupon_island")
    v-slide-item(v-for="(item, index) in promotions", :key="index")
      CouponCell(:promotion="item", :claims="claims", @claimed="loadClaims")
</template>

<script>
import _ from "underscore";
import CouponCell from "./CouponCell/index";
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  components: { CouponCell },
  data() {
    return {
      loading: false,
      promotions: [],
      claims: [],
      show_arrow: true,
    };
  },
  computed: {
    ...mapGetters(["selectedCity"]),
  },
  methods: {
    async load() {
      if (!this.selectedCity) return;
      const now = Date.now();
      const options = {
        criteria: {
          expiry: { $gt: now },
          country: this.selectedCity.country,
          states: this.selectedCity.state,
        },
      };
      const res = await this.axios.post("/promotion/list", options);
      this.loading = false;
      this.promotions = _.chain(res.data)
        .reject((o) => o.claimed >= o.total)
        .shuffle()
        .value();
      this.loadClaims();
    },
    async loadClaims() {
      this.claims = [];
      if (!this.$auth.check()) return;
      if (!this.promotions) return;
      const ids = _.pluck(this.promotions, "_id");
      const options = {
        criteria: {
          promotion: { $in: ids },
          user: this.$auth.user()._id,
        },
        select: "promotion applied",
      };
      const res = await this.axios.post("/promoclaim/list", options);
      this.claims = res.data;
    },
    toLeft() {
      const width = this.$refs.promotions.clientWidth;
      this.$refs.promotions.scrollLeft -= width;
    },
    toRight() {
      const width = this.$refs.promotions.clientWidth;
      this.$refs.promotions.scrollLeft += width;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("set-selected-city", this.load);
  },
  destroyed() {
    EventBus.$off("set-selected-city");
  },
};
</script>
