<template lang="pug">
.body-2.my-3
  div(v-if="hasGift") Gift cards are not refundable but can be used for online or in-store purchase at designated restaurant.
  .text-center.my-3(v-if="hasDish")
    div You have chosen type
    .text-center(v-if="isPickup")
      .title.primary--text Pick Up
      div Please show up around your selected time.
    .text-center(v-if="isCurbside")
      .title.primary--text Curbside
      div Please show up around your selected time.
    div(v-if="isDelivery")
      .title.primary--text Delivery
      div(v-if="deliv") The delivery service is handled by {{ deliv.name }}
      div(v-else) The delivery service is handled by restaurant's own team.
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["cartOrder", "deliv"]),
    hasDish() {
      return (
        this.cartOrder &&
        this.cartOrder.dishes &&
        this.cartOrder.dishes.length > 0
      );
    },
    hasGift() {
      return (
        this.cartOrder &&
        this.cartOrder.gifts &&
        this.cartOrder.gifts.length > 0
      );
    },
    isPickup() {
      return this.cartOrder && this.cartOrder.type === "pickup";
    },
    isCurbside() {
      return this.cartOrder && this.cartOrder.type === "curbside";
    },
    isDelivery() {
      return this.cartOrder && this.cartOrder.type === "delivery";
    },
  },
};
</script>