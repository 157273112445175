<template lang="pug">
.my-5(v-if="review")
  .review-title {{ review.title }}
  .text--secondary.body-2.mb-2 Wrote on {{ review._id | objectIdDate }}, ordered on {{ review.order | objectIdDate }}
  div(v-html="review.content")
  .review-reply(v-if="review.replyt")
    div {{ review.reply }}
    .text--secondary.body-2 Replied {{ review.replyt | date }}
  .d-flex.flex-row.align-center
    v-rating(v-model="review.stars", readonly, small, dense)
    v-spacer
    v-tooltip(bottom)
      template(v-slot:activator="{ on }")
        v-btn(
          @click="toggleLike(review)",
          text,
          small,
          v-on="on",
          color="grey"
        )
          v-icon(:color="favColor(review.liked)", small, left) mdi-thumb-up
          span.red--text.text--darken-2 {{ review.liked.length }}
      span like
    div(v-if="!disableEdit")
      v-btn(text, small, color="primary", @click="edit") Edit
      v-btn(text, small, color="primary", @click="showOrder") View Order
</template>

<script>
import _ from "underscore";
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  props: ["review", "disableEdit"],
  methods: {
    ...mapActions(["updateReview"]),
    edit() {
      if (!this.review) return;
      EventBus.$emit("edit-review", this.review);
    },
    showOrder() {
      if (!this.review) return;
      EventBus.$emit("show-order-detail", this.review.order);
    },
    favColor(liked) {
      if (this.isLiked(liked)) return "red darken-2";
      else return "gray";
    },
    toggleLike(review) {
      if (!this.$auth.user()) return;
      const userId = this.$auth.user()._id;
      let options;
      if (this.isLiked(review.liked)) {
        options = {
          criteria: { _id: review._id },
          action: { $pull: { liked: userId } },
        };
      } else {
        options = {
          criteria: { _id: review._id },
          action: { $addToSet: { liked: userId } },
        };
      }
      this.axios.post("/reviews/update", options).then((response) => {
        this.updateReview(response.data);
      });
    },
    isLiked(liked) {
      if (!this.$auth.user()) return false;
      const userId = this.$auth.user()._id;
      const found = _.find(liked, (o) => {
        return o === userId;
      });
      if (found) return true;
      return false;
    },
    shareURL(review) {
      if (review && review.biz) {
        return "https://selflane.com/bizs/" + review.biz.url;
      } else {
        return "https://selflane.com";
      }
    },
  },
};
</script>

<style scoped>
.review-title {
  font-size: 16px;
  font-weight: 500;
}
.review-reply {
  position: relative;
  background: linear-gradient(to bottom right, #f6f8fe, #eceefb);
  padding: 10px;
  margin-top: 16px;
  border-radius: 6px;
}
.review-reply:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20px;
  width: 0;
  height: 0;
  border-bottom: solid 10px #f5f5f8;
  border-left: solid 5px transparent;
  border-right: solid 10px transparent;
}
</style>
