<template lang="pug">
.message-box(v-if="conversation")
  Message(:message="item", v-for="item in messages", :key="item._id")
</template>

<script>
import Message from "./Message";

export default {
  props: ["conversation"],
  components: { Message },
  computed: {
    messages() {
      const result = JSON.parse(
        JSON.stringify(this.conversation && this.conversation.messages)
      );
      if (!result) return [];
      return result.reverse();
    },
  },
};
</script>

<style scoped>
.message-box {
  height: 300px;
  overflow-y: scroll;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: flex;
  flex-direction: column-reverse;
}
</style>