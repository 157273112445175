<template lang="pug">
div
  .title-style Reviews
  List(disableEdit="true")
</template>

<script>
import List from "@/components/Review/List";

export default {
  components: { List },
};
</script>

<style scoped>
.title-style {
  display: inline;
  font-weight: 500;
  font-size: 24px;
  padding-bottom: 4px;
  border-bottom: solid 3px #FF6D32;
  margin-bottom: 20px
}
</style>
