import _ from 'underscore'

const state = {
  reviews: []
}

const getters = {
  reviews: state => state.reviews
}

const actions = {
  setReviews: ({
    commit
  }, reviews) => {
    commit('setReviews', reviews)
  },
  updateReview: ({
    commit
  }, review) => {
    commit('updateReview', review)
  },
  addReview: ({
    commit
  }, review) => {
    commit('addReview', review)
  }
}

const mutations = {
  setReviews(state, reviews) {
    state.reviews = reviews
  },
  updateReview(state, review) {
    state.reviews = _.map(state.reviews, o => {
      if (o._id === review._id) return review
      return o
    })
  },
  addReview(state, review) {
    state.reviews.unshift(review)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}