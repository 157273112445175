<template lang="pug">
div
  CurrentOrders(:orders="today_orders")
  PastOrders(:orders="past_orders")
  EmptyView(v-if="isEmpty", text="No Orders to Show")
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import CurrentOrders from "./CurrentOrders";
import PastOrders from "./PastOrders";
import moment from "moment";

export default {
  props: ["loading"],
  components: { CurrentOrders, PastOrders },
  computed: {
    ...mapGetters(["orders"]),
    isEmpty() {
      return this.orders.length == 0 && !this.loading;
    },
    cutoff() {
      return moment().startOf("day").unix() * 1000;
    },
    today_orders() {
      return _.filter(this.orders, (o) => o.needed > this.cutoff);
    },
    past_orders() {
      return _.filter(this.orders, (o) => o.needed <= this.cutoff);
    },
  },
};
</script>
