<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Gift Card Activities
    v-card-text
      v-btn-toggle(v-model="selected", dense)
        v-btn(
          v-for="(item, index) in items",
          :key="index",
          :value="item.value",
          small
        ) {{ item.text }}
      Purchases(:purchases="purchases", v-if="selected == 'purchases'")
      Transfers(:transfers="transfers", v-if="selected == 'transfers'")
      ReceivedTransfers(
        :transfers="received_transfers",
        v-if="selected == 'received_transfers'"
      )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Purchases from "./Purchases";
import Transfers from "./Transfers";
import ReceivedTransfers from "./ReceivedTransfers";

export default {
  components: { Purchases, Transfers, ReceivedTransfers },
  data() {
    return {
      dialog: false,
      selected: "",
      items: [
        { text: "Purchases", value: "purchases" },
        { text: "Transfers", value: "transfers" },
        { text: "Received", value: "received_transfers" },
      ],
      purchases: [],
      transfers: [],
      received_transfers: [],
    };
  },
  watch: {
    selected(val) {
      switch (val) {
        case "purchases":
          this.loadPurchases();
          break;
        case "transfers":
          this.loadTransfers();
          break;
        case "received_transfers":
          this.loadReceivedTransfers();
          break;
      }
    },
  },
  methods: {
    open() {
      this.selected = "purchases";
      this.dialog = true;
    },
    async loadPurchases() {
      if (!this.$auth.check()) return;
      const userId = this.$auth.user()._id;
      if (!userId) return;
      const params = {
        criteria: {
          user: userId,
        },
      };
      const res = await this.axios.post("gfbalance/purchase/list", params);
      this.purchases = res.data;
    },
    async loadTransfers() {
      if (!this.$auth.check()) return;
      const userId = this.$auth.user()._id;
      if (!userId) return;
      const params = {
        criteria: {
          user: userId,
        },
      };
      const res = await this.axios.post("gfbalance/transfer/list", params);
      this.transfers = res.data;
    },
    async loadReceivedTransfers() {
      if (!this.$auth.check()) return;
      const userId = this.$auth.user()._id;
      if (!userId) return;
      const params = {
        criteria: {
          recipient_user: userId,
        },
      };
      const res = await this.axios.post("gfbalance/transfer/list", params);
      this.received_transfers = res.data;
    },
  },
  mounted() {
    EventBus.$on("show-gfbalance-activities", this.open);
  },
  destroyed() {
    EventBus.$off("show-gfbalance-activities");
  },
};
</script>