<template lang="pug">
.message(v-if="event") Ends at {{ event.sale_time.end | datetime(time_format) }}
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["event"]),
    time_format() {
      if (!this.event) return "h:mm A";
      const same_date =
        moment(this.event.sale_time.end).format("YYYY-MM-DD") ==
        moment().format("YYYY-MM-DD");
      if (!same_date) {
        return "ddd, MMM D, h:mm A";
      }
      return "h:mm A";
    },
  },
};
</script>

<style scoped>
.message {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  color: #ff6d32;
  position: relative;
}  
</style>