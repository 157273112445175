<template lang="pug">
div
  .d-flex.flex-row.align-center
    v-icon.mr-2(small, color="primary") mdi-phone
    .body-2 Phone
  v-text-field(
    v-model="eventCart.user_phone",
    dense,
    placeholder="Enter Phone",
    hide-details,
    v-mask="phoneMask"
  )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["eventCart"]),
  },
  data() {
    return {
      phoneMask: "(###) ###-####",
    };
  },
};
</script>
