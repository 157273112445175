import _ from 'underscore'

const state = {
  bizmodifiers: []
}

const getters = {
  bizmodifiers: state => state.bizmodifiers
}

const actions = {
  setBizmodifiers: ({
    commit
  }, bizmodifiers) => {
    commit('setBizmodifiers', bizmodifiers)
  },
  updateBizmodifier: ({
    commit
  }, bizmodifier) => {
    commit('updateBizmodifier', bizmodifier)
  },
  addBizmodifier: ({
    commit
  }, bizmodifier) => {
    commit('addBizmodifier', bizmodifier)
  },
  removeBizmodifier: ({
    commit
  }, bizmodifier) => {
    commit('removeBizmodifier', bizmodifier)
  }
}

const mutations = {
  setBizmodifiers(state, bizmodifiers) {
    state.bizmodifiers = bizmodifiers
  },
  updateBizmodifier(state, bizmodifier) {
    state.bizmodifiers = _.map(state.bizmodifiers, o => {
      if (o._id === bizmodifier._id) return bizmodifier
      return o
    })
  },
  addBizmodifier(state, bizmodifier) {
    state.bizmodifiers.push(bizmodifier)
  },
  removeBizmodifier(state, bizmodifier) {
    state.bizmodifiers = _.reject(state.bizmodifiers, o => {
      return (o._id === bizmodifier._id)
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}