import _ from 'underscore'

const state = {
  store_gift_card: null,
  giftCards: []
}

const getters = {
  store_gift_card: state => state.store_gift_card,
  giftCards: state => state.giftCards
}

const actions = {
  setStoreGiftCard: ({
    commit
  }, data) => {
    commit('setStoreGiftCard', data)
  },
  setGiftCards: ({
    commit
  }, giftCards) => {
    commit('setGiftCards', giftCards)
  },
  updateGiftCard: ({
    commit
  }, giftCard) => {
    commit('updateGiftCard', giftCard)
  },
  addGiftCard: ({
    commit
  }, giftCard) => {
    commit('addGiftCard', giftCard)
  },
  removeGiftCard: ({
    commit
  }, giftCard) => {
    commit('removeGiftCard', giftCard)
  }
}

const mutations = {
  setStoreGiftCard(state, data) {
    state.store_gift_card = data
  },
  setGiftCards(state, giftCards) {
    state.giftCards = giftCards
  },
  updateGiftCard(state, giftCard) {
    state.giftCards = _.map(state.giftCards, o => {
      if (o._id === giftCard._id) return giftCard
      return o
    })
  },
  addGiftCard(state, giftCard) {
    state.giftCards.push(giftCard)
  },
  removeGiftCard(state, giftCard) {
    state.giftCards = _.reject(state.giftCards, o => {
      return (o._id === giftCard._id)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}