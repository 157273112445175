<template lang="pug">
v-btn.text-capitalize(
  v-if="promotion",
  block,
  :color="color",
  outlined,
  depressed,
  @click="setPromotion(promotion)"
)
  span.mr-2(v-if="!selected") Coupon
  span(v-if="promotion.type == 'fix'") {{ -promotion.fix | currency }}
  span(v-else) {{ -promotion.pct }}%
  v-icon(right, small, v-if="selected") mdi-check
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["promotion"],
  computed: {
    ...mapGetters(["cartPromotion"]),
    selected() {
      return (
        this.cartPromotion &&
        this.promotion &&
        this.cartPromotion._id === this.promotion._id
      );
    },
    color() {
      /// if is selected promotion, set to primary btn, otherwise use default btn
      if (this.selected) return "success";
      else return "secondary";
    },
  },
  methods: {
    ...mapActions(["setPromotion"]),
  },
};
</script>
