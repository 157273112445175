<template lang="pug">
.coupon-logo
  v-img(:src="logo", height="100%", contain, v-if="logo")
</template>

<script>
export default {
  props: ["promotion"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  computed: {
    logo() {
      if (this.promotion) {
        return this.mediaHost + this.promotion.logo;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.coupon-logo {
  width: 60px;
  height: 60px;
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;
}
</style>
