<template lang="pug">
v-card
  v-toolbar(flat, dense)
    .subtitle-2 Address Form
  v-card-text
    div {{ new_address | address }}
    v-form(@submit.prevent="submit")
      v-text-field(
        label="Address",
        placeholder="",
        v-model="formatted_address",
        required,
        ref="autocomplete",
        @keydown.enter.prevent=""
      )
      v-text-field(label="Apt/Room (optional)", v-model="new_address.line2")
      v-text-field(label="Instruction", v-model="new_address.note")
      v-btn(block, type="submit", color="secondary", :loading="loading") save
    .pl-2.red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
</template>

<script>
import MapUtils from "@/utils/map";
import _ from "underscore";

export default {
  name: "GoogleAddressForm",
  props: ["address", "showName"],
  data() {
    return {
      formatted_address: "", // address in one_line format
      new_address: {},
      line2: "",
      note: "",
      loading: false,
      errors: [],
    };
  },
  watch: {
    address: {
      handler() {
        this.reset();
      },
      deep: true,
    },
  },
  methods: {
    async getTimezone(address) {
      try {
        const res = await MapUtils.getTimeZone(
          address.geometry.lat,
          address.geometry.lng
        );
        return res.data.timeZoneId;
      } catch (err) {
        throw "No corresponding timezone";
      }
    },
    async submit() {
      if (
        !this.new_address.line1 ||
        !this.new_address.city ||
        !this.new_address.geometry ||
        !this.new_address.geometry.lat
      ) {
        this.errors = ["Invalid address"];
        return;
      }
      this.loading = true;
      try {
        const timezone = await this.getTimezone(this.new_address);
        this.new_address.timezone = timezone;
        this.$emit("save", this.new_address);
        this.formatted_address = "";
      } catch (err) {
        this.errors = [err];
      }
      this.loading = false;
    },
    setWithGooglePlace(place) {
      const street_number = this.getComponent(place, "street_number");
      const route = this.getComponent(place, "route");
      const locality = this.getComponent(place, "locality");
      const administrative_area = this.getComponent(
        place,
        "administrative_area_level_1"
      );
      const country = this.getComponent(place, "country");
      const postal_code = this.getComponent(place, "postal_code");
      const line1 = street_number.short_name + " " + route.short_name;
      const city = locality.short_name;
      const state = administrative_area.short_name;
      this.new_address = {
        line1: line1,
        line2: this.new_address.line2,
        city: city,
        state: state,
        country: country.short_name,
        postal_code: postal_code.short_name,
        geometry: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        note: this.new_address.note,
      };
      this.formatted_address = place.formatted_address;
    },
    getComponent(place, name) {
      return _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == name) != undefined;
      });
    },
    reset() {
      const addressFilter = this.$options.filters.address;
      this.formatted_address = addressFilter(this.address);
      this.new_address = this.address;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete.$refs.input
    );
    this.autocomplete.setFields([
      "address_component",
      "formatted_address",
      "geometry",
      "place_id",
    ]);
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.setWithGooglePlace(place);
    });
    this.reset();
  },
};
</script>
