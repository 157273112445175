<template lang="pug">
v-app
  Nav
  v-main(style="background-color: #f0f2f5")
    router-view
  Footer
  GlobalSnackbar
  QRCodeDialog
  Dish
  OrderDialog
  BottomCartSheet
</template>


<script>
import Nav from "./components/Nav/index";
import Footer from "./components/Footer/index";
import GlobalSnackbar from "./components/basic/GlobalSnackbar";
import QRCodeDialog from "@/components/basic/QRCodeDialog";
import Dish from "@/components/Bizs/Menu/Dish/index";
import OrderDialog from "@/components/Order/Dialog";
import BottomCartSheet from "@/components/Cart/BottomCartSheet";

export default {
  name: "App",
  components: {
    Nav,
    Footer,
    GlobalSnackbar,
    QRCodeDialog,
    Dish,
    OrderDialog,
    BottomCartSheet,
  },
};
</script>
