<template lang="pug">
div(v-if="store_gift_card")
  .text-center(v-if="isClaimed")
    .my-2.subtitle-2 Added Successfully
    v-btn(color="secondary", rounded, to="/user/wallet/gift") See in My Wallet
  v-form.my-10(v-else, @submit.prevent="transfer()")
    p Please enter the email address this gift card was originally send to.
    v-text-field(v-model="email", label="Receiver Email Address")
    v-btn(type="submit", color="secondary", block) Claim Gift Card
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
    };
  },
  computed: {
    ...mapGetters(["store_gift_card"]),
    isClaimed() {
      return (
        this.store_gift_card &&
        (!this.store_gift_card.to || !this.store_gift_card.to.length)
      );
    },
    isSameEmail() {
      const email = this.$auth.user().email;
      return email == this.store_gift_card.to;
    },
  },
  methods: {
    ...mapActions(["setStoreGiftCard"]),
    checkClaim() {
      if (!this.isSameEmail) return;
      const email = this.$auth.user().email;
      if (email == this.store_gift_card.to) {
        /// claim this card
      }
    },
    async transfer() {
      if (this.email != this.store_gift_card.to) {
        alert("The entered email is incorrect");
        return;
      }
      const params = {
        email: this.email,
        gcId: this.store_gift_card._id,
        userId: this.$auth.user()._id,
      };
      try {
        const res = await this.axios.post("/giftcard/claim", params);
        this.setStoreGiftCard(res.data);
      } catch (err) {
        alert(err);
      }
    },
  },
  mounted() {
    this.email = this.$auth.user().email;
    /// check to
    this.checkClaim();
  },
};
</script>