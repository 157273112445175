<template lang="pug">
v-container.py-0(v-if="biz")
  .info-box
    .d-flex.flex-row.justify-center(v-if="isNotPublic")
      v-alert.mb-3(dense, type="error") This business is currently not public, you won't be able to place orders with it.
    .d-flex.flex-row.justify-center(v-if="isClosed")
      v-alert(type="error", dense) Temporarily Closed. {{ biz.schedule.temporary_close.reason }}
    Logo(:logo="biz.logo")
    POSNotice(v-if="usePOS")
    h1.title {{ biz.name }}
    .body-2 {{ biz.description }}
    Contact
    .subtitle-2 {{ biz.cates | cates }}
    Schedule(:schedule="biz.schedule")
    OrderType.my-2(:type="biz.orderType")
    DelivTier.my-2
    TopActions
</template>

<script>
import Schedule from "./Schedule";
import { mapGetters } from "vuex";
import Contact from "./Contact";
import OrderType from "./OrderType";
import DelivTier from "./DelivTier";
import Logo from "./Logo";
import TopActions from "./TopActions/index";
import POSNotice from "./POSNotice";

export default {
  components: {
    Logo,
    Contact,
    Schedule,
    OrderType,
    DelivTier,
    TopActions,
    POSNotice,
  },
  data() {
    return {
      distance: 0,
    };
  },
  computed: {
    ...mapGetters(["biz", "bizService"]),
    isNotPublic() {
      return this.biz && this.biz.status && !this.biz.status.byAdmin;
    },
    isClosed() {
      return (
        this.biz &&
        this.biz.schedule &&
        this.biz.schedule.temporary_close &&
        this.biz.schedule.temporary_close.status == true
      );
    },
    hasDelivery() {
      return (
        this.biz &&
        this.biz.orderType &&
        this.biz.orderType.delivery &&
        this.biz.orderType.delivery.status == true
      );
    },
    usePOS() {
      return (
        this.bizService && this.bizService.pos && this.bizService.pos.status
      );
    },
  },
  methods: {
    reserve() {
      this.$router.push("/bizs/" + this.biz.url + "/reserve");
      this.$vuetify.goTo("#bizMainCon");
    },
  },
};
</script>

<style scoped>
.info-box {
  position: relative;
  background-color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 16px;
  padding-top: 66px;
}
</style>
