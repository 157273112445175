<template lang="pug">
.my-2(v-if="visible")
  .d-flex.flex-row.align-center
    span Name: {{ order.customer.name }}
    EditName(:order="order")
  .d-flex.flex-row.align-center
    span Phone: {{ order.customer.phone | phone }}
    EditPhone(:order="order")
  div(v-if="isDelivery")
    div Address: {{ order.customer.address | address }}
    div(v-if="order.customer.address && order.customer.address.note") Delivery Note: {{ order.customer.address.note }}
</template>

<script>
import moment from "moment-timezone";
import EditName from "./EditName";
import EditPhone from "./EditPhone";

export default {
  props: ["order"],
  components: { EditName, EditPhone },
  computed: {
    isDelivery() {
      return this.order.type === "delivery";
    },
    // allow edit name and phone number before order's needed time (with 30 minutes window)
    visible() {
      const diff = moment().diff(moment(this.order.needed), "minutes");
      return diff < 30;
    },
  },
};
</script>