<template lang="pug">
div
  .biz-name {{ biz.name }}
  .d-flex.flex-row.biz-address.flex-nowrap
    .biz-schedule.text-capitalize {{ day | dayschedule }}
    v-spacer.mx-1
    span {{ biz.distance | distance }}
</template>

<script>
import BizUtils from "@/utils/biz.js";

export default {
  name: "BizCellInfoBlock",
  props: ["biz"],
  computed: {
    day() {
      return BizUtils.getDaySchedule(this.biz.schedule);
    },
  },
};
</script>

<style scoped>
.biz-name {
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.biz-schedule {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.biz-address {
  color: rgba(28, 31, 52, 0.6);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 22px;
}
</style>
