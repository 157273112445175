<template lang="pug">
v-btn.text-capitalize(
  rounded,
  depressed,
  small,
  color="#E4E6EB",
  @click="signMember",
  v-if="hasMember"
)
  v-icon(small, left) mdi-wallet-membership
  span Member
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["biz", "bizService"]),
    hasMember() {
      return this.bizService && this.bizService.member > 0;
    },
  },
  methods: {
    signMember() {
      this.$router.push("/bizs/" + this.biz.url + "/member");
      this.$vuetify.goTo("#bizMainCon");
    },
  },
};
</script>
