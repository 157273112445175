<template lang="pug">
v-dialog(v-model="dialog", width="400", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Claim Gift Card
      v-divider
      v-card-text
        v-text-field(label="Recipient Email", v-model="email")
        v-text-field(
          label="Code",
          v-model="code",
          hint="9-digit; sent by email",
          v-mask="\"### ### ###\""
        )
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
        v-btn(color="secondary", block, :loading="loading", type="submit") Submit
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      email: "",
      code: "",
      errors: [],
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.email = this.$auth.user().email;
      this.code = "";
      this.errors = [];
    },
    async submit() {
      /// validate
      this.loading = true;

      const params = {
        email: this.email,
        code: this.code,
      };
      try {
        await this.axios.post("/gfbalance/transfer/claim", params);
        this.dialog = false;
      } catch (err) {
        this.errors = [err.response.data];
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("show-gfbalance-transfer-claim", this.open);
  },
  destroyed() {
    EventBus.$off("show-gfbalance-transfer-claim");
  },
};
</script>