const state = {
  deliv: null,
}

const getters = {
  deliv: state => state.deliv,
}

const actions = {
  setDeliv: ({
    commit
  }, deliv) => {
    commit('setDeliv', deliv)
  }
}

const mutations = {
  setDeliv(state, deliv) {
    state.deliv = deliv
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}