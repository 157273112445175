const state = {
  bizAccounts: []
}

const getters = {
  bizAccounts: state => state.bizAccounts
}

const actions = {
  setBizAccounts: ({
    commit
  }, data) => {
    commit('setBizAccounts', data)
  }
}

const mutations = {
  setBizAccounts(state, data) {
    state.bizAccounts = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}