import _ from "underscore";

const state = {
  promotions: [],
  claims: []
};

const getters = {
  promotions: state => state.promotions,
  claims: state => state.claims
};

const actions = {
  setPromotions: ({
    commit
  }, data) => {
    commit("setPromotions", data);
  },
  addPromotion: ({
    commit
  }, data) => {
    commit("addPromotion", data);
  },
  updatePromotion: ({
    commit
  }, data) => {
    commit("updatePromotion", data);
  },
  setClaims: ({
    commit
  }, data) => {
    commit("setClaims", data);
  },
  addClaim: ({
    commit
  }, data) => {
    commit("addClaim", data);
  },
  updateClaim: ({
    commit
  }, data) => {
    commit("updateClaim", data);
  },
};

const mutations = {
  setPromotions(state, data) {
    state.promotions = data;
  },
  addPromotion(state, data) {
    state.promotions.push(data);
  },
  updatePromotion(state, data) {
    state.promotions = _.map(state.promotions, o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  setClaims(state, data) {
    state.claims = data;
  },
  addClaim(state, data) {
    state.claims.push(data);
  },
  updateClaim(state, data) {
    state.claims = _.map(state.claims, o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};