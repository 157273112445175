import Card from './Card/index.vue'
import Claim from './Claim/index.vue'

export default [{
    path: '/store-gift-card/:id',
    props: true,
    component: Card,
    children: [{
        path: 'claim',
        component: Claim,
        meta: {
            auth: true
        }
    }]
}]