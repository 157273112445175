<template lang="pug">
v-card
  v-card-title
  v-card-text
    v-form(@submit.prevent="submit")
      v-text-field(
        label="Phone Number",
        v-model="number",
        v-mask="\"(###) ###-####\"",
        :error-messages="phoneErrors",
        @blur="$v.number.$touch()",
        autofocus,
        ref="focus"
      )
      v-btn(block, :disabled="$v.$invalid", type="submit", color="secondary") save
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  props: ["phone"],
  mixins: [validationMixin],
  validations: {
    number: {
      required,
      minLength: minLength(10),
    },
  },
  data() {
    return { number: "" };
  },
  watch: {
    phone: {
      handler(val) {
        if (val) this.number = val.number;
        this.$v.$reset();
        this.$nextTick(() => {
          this.$refs.focus.focus();
        });
      },
      deep: true,
    },
  },
  computed: {
    phoneErrors() {
      const errors = [];
      if (!this.$v.number.$dirty) return errors;
      !this.$v.number.required && errors.push("A number is required");
      !this.$v.number.minLength && errors.push("At least 10 digits");
      return errors;
    },
  },
  methods: {
    submit() {
      const number = this.number.replace(/[^\d]/g, "");
      if (number.length != 10) return;
      let data = {
        number: number,
      };
      if (this.phone && this.phone._id) {
        data._id = this.phone._id;
      }
      this.$emit("save", data);
    },
  },
};
</script>
