<template lang="pug">
.coupon-top(@click='toDetail' role='button')
  .coupon-logo
    v-img(:src='mediaHost + logo' height='100%' contain v-if='logo')
  .text-center.pa-2
    .subheading.text-truncate.mb-2 {{ name }}
    .title(v-if='promotion.type==="fix"') {{ -promotion.fix | currency }}
    .title(v-else) {{ -promotion.pct }}%
    .grey--text.text-truncate.mt-2 Expires: {{ promotion.expiry | date2 }}
    .body-2.text-truncate {{ promotion.title }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["promotion", "bizs", "chains"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST
    };
  },
  computed: {
    logo() {
      if (!this.promotion) return "";
      if (this.promotion.chain) {
        const chain = _.find(this.chains, o => o._id == this.promotion.chain);
        if (chain) return chain.logo;
        else return "";
      } else {
        const biz = _.find(this.bizs, o => o._id == this.promotion.biz);
        if (biz && biz.photos && biz.photos.length) return biz.photos[0];
        else return "";
      }
    },
    name() {
      if (!this.promotion) return "";
      if (this.promotion.chain) {
        const chain = _.find(this.chains, o => o._id == this.promotion.chain);
        if (chain) return chain.name;
        else return "";
      } else {
        const biz = _.find(this.bizs, o => o._id == this.promotion.biz);
        if (biz) return biz.name;
        else return "";
      }
    }
  },
  methods: {
    toDetail() {
      if (!this.promotion) return;
      this.$router.push("/couponisland/" + this.promotion.code);
    }
  }
};
</script>

<style scoped>
.coupon-top {
  height: 270px;
  overflow: hidden;
}
.coupon-logo {
  background-color: white;
  text-align: center;
  height: 160px;
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
}
</style>
