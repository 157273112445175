<template lang="pug">
v-container.pb-10
  v-card(v-if="event", outlined)
    Top
    Tickets
    Middle
    Map
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Top from "./Top";
import Tickets from "./Tickets";
import Middle from "./Middle";
import Map from "./Map";

export default {
  props: ["id"],
  components: { Top, Tickets, Middle, Map },
  computed: {
    ...mapGetters(["event"]),
  },
  methods: {
    ...mapActions(["setBiz", "setEvent"]),
    async load() {
      if (!this.id) return;
      const params = { event_id: this.id };
      try {
        const res = await this.axios.post("/event/entries/retrieve", params);
        this.setEvent(res.data);
      } catch (err) {
        this.setEvent();
        this.$router.push("/event");
      }
      this.loadBiz();
    },
    async loadBiz() {
      if (!this.event) return;
      const params = { criteria: { _id: this.event.biz_id } };
      try {
        const res = await this.axios.post("/bizs/retrieve", params);
        this.setBiz(res.data);
      } catch (err) {
        //
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.event-container {
  border-bottom: 1px solid #DBDAE3;
}
</style>