<template lang="pug">
img(:src="logoPath", v-if="logoPath")
</template>

<script>
export default {
  props: ["logo"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  computed: {
    logoPath() {
      if (this.logo) return this.mediaHost + this.logo;
      return null;
    },
  },
};
</script>

<style scoped>
img {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  overflow: hidden;
  object-fit: cover;
}
</style>
