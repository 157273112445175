const _ = require('underscore')

module.exports = function (list, short) {
  const sorted_list = _.sortBy(list)
  if (sorted_list.length == 5 && sorted_list[0] == 1 && sorted_list[4] == 5) return 'Weekdays'
  if (sorted_list.length == 2 && sorted_list[0] == 0 && sorted_list[1] == 6) return 'Weekends'
  if (sorted_list.length == 7) return 'All Week'
  const result = _.map(sorted_list, (o) => { return weekday(o, short) })
  return result.join(' ')
}

function weekday(value, short) {
  if (typeof (value) !== 'number') return value || ''
  let text = ""
  switch (value) {
    case 0: text = 'Sunday'
      break
    case 1: text = 'Monday'
      break
    case 2: text = 'Tuesday'
      break
    case 3: text = 'Wednesday'
      break
    case 4: text = 'Thursday'
      break
    case 5: text = 'Friday'
      break
    case 6: text = 'Saturday'
      break
    default: text = value
  }
  if (short && typeof text == 'string') return text.substring(0, 3)
  return text
}