<template lang="pug">
.sl-card(v-if="order")
  Head(:order="order")
  Items(:order="order")
  Payment(:order="order")
  Reorder(:order="order", v-if="showAction")
  Download.mx-8.mb-3(:order="order")
</template>

<script>
import Head from "./Head";
import Items from "./Items";
import Payment from "./Payment";
import Reorder from "./Reorder";
import Download from "./Download";

export default {
  props: ["order", "showAction"],
  components: { Head, Items, Payment, Reorder, Download },
};
</script>
