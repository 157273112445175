<template lang="pug">
div(v-if="cartDue > 0")
  .body-2.mb-2 Payment
  .d-flex.flex-row.align-center.justify-space-between
    v-menu(v-model="dialog")
      template(v-slot:activator="{ on }")
        .d-flex.flex-row.align-center.mr-5(v-on="on", role="button")
          .font-weight-medium(v-if="selectedCard") {{ selectedCard.brand }} ...{{ selectedCard.last4 }} {{ selectedCard.exp_month }}/{{ selectedCard.exp_year }}
          span(v-else) Select
          v-icon(right) mdi-menu-down
      v-card
        v-list.py-0(v-if="stripeSources && stripeSources.length")
          v-list-item(
            v-for="(item, index) in stripeSources",
            :key="index",
            @click="setCard(item)"
          )
            v-list-item-content
              v-list-item-title {{ item.brand }} ...{{ item.last4 }}
              v-list-item-subtitle {{ item.exp_month }}/{{ item.exp_year }}
            v-list-item-action
              v-icon(
                v-if="selectedCard && item.id == selectedCard.id",
                color="success"
              ) mdi-check
        v-card-text(v-else)
          CreateSource(@saved="saved")
    CreateSource(:roundIcon="true", @saved="saved")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateSource from "@/components/User/Payments/CreateSource";
import _ from "underscore";

export default {
  components: { CreateSource },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["cartOrder", "stripeSources"]),
    cartDue() {
      if (!this.cartOrder || !this.cartOrder.payment) return 0;
      const payment = this.cartOrder.payment;
      const paid = _.reduce(
        payment.charges,
        (memo, item) => {
          return memo + item.amount;
        },
        0
      );
      return Math.round((payment.total - paid) * 100) / 100;
    },
    selectedCard() {
      if (
        !this.cartOrder ||
        !this.cartOrder.payment ||
        !this.cartOrder.payment.card ||
        !this.cartOrder.payment.card.id
      )
        return null;
      const found = _.find(this.stripeSources, (o) => {
        return o.id == this.cartOrder.payment.card.id;
      });
      return found;
    },
  },
  methods: {
    ...mapActions(["setCard", "setStripeSources"]),
    load() {
      const user = this.$auth.user();
      if (!user) return;
      this.axios
        .post("/stripe/sources/list", {
          userId: user._id,
        })
        .then((response) => {
          this.setStripeSources(response.data);
          this.initPayment();
        });
    },
    initPayment() {
      if (this.selectedCard) return;
      const first = _.first(this.stripeSources);
      if (first) this.setCard(first);
    },
    saved(source) {
      this.setCard(source);
    },
  },
  mounted() {
    this.setStripeSources([]);
    this.load();
  },
};
</script>
