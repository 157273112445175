<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between
    .sl-title My Orders
    YearSelector(@select="load")
  v-divider.my-2
  OrderView(:loading="loading")
  ReviewEdit
  DelivTracking
  .text-center.my-10
    FAQ
</template>

<script>
import OrderView from "@/components/Order/index";
import ReviewEdit from "@/components/Review/Edit";
import DelivTracking from "@/components/Order/DelivTracking/index";
import FAQ from "@/components/Order/FAQ";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: { OrderView, ReviewEdit, DelivTracking, FAQ },
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["setOrders"]),
    async load(year) {
      if (!this.$auth.user()) return this.setOrders([]);
      const id = this.$auth.user()._id;
      const begin = moment().year(year).startOf("year").unix() * 1000;
      const end = moment().year(year).endOf("year").unix() * 1000;
      const params = {
        criteria: { "customer.id": id, needed: { $gte: begin, $lt: end } },
        select:
          "seller customer needed status orderNumber payment.total deliv type",
      };
      this.loading = true;
      try {
        const res = await this.axios.post("/orders/list", params);
        this.setOrders(res.data);
      } catch (err) {
        this.setOrders([]);
      }
      this.loading = false;
    },
  },
  destroyed() {
    this.setOrders([]);
  },
};
</script>
