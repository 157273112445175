<template lang="pug">
table.my-3(v-if="eventCart")
  tr
    td Subtotal
    td.text-right {{ eventCart.payment.subtotal | currency }}
  tr
    td Tax
    td.text-right {{ eventCart.payment.tax | currency }}
  tr
    td Total
    td.text-right {{ eventCart.payment.total | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["eventCart"]),
  },
};
</script>

<style scoped>
table {
  width: 100%;
  font-size: 14px;
}
table tr {
  margin-bottom: 4px;
}
</style>