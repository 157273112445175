import AHCNM from './AHCNM-GALA/index.vue'
import AHCNM_Golf from './AHCNM-Golf/index.vue'
import ABC from './ABC/index.vue'
import NHCC from './NHCC/index.vue'
import Fundaxi from './Fundaxi/index.vue'

export default [{
  path: '/ahcnm-gala',
  component: AHCNM
}, {
  path: '/ahcnm-golf',
  component: AHCNM_Golf
}, {
  path: '/abc',
  component: ABC
}, {
  path: '/abc',
  component: ABC
}, {
  path: '/nhcc',
  component: NHCC
}, {
  path: '/fundaxi',
  component: Fundaxi
}]