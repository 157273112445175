import _ from 'underscore'

function modifiers(modifiers) {
  if (!modifiers) return ''
  const arr = []
  modifiers.forEach(o => {
    let formatted = formatChoices(o)
    if (formatted && formatted.length > 0) arr.push(formatted)
  })
  return arr.join('; ')

  function formatChoices(modifier) {
    let choiceNames = _.chain(modifier.choices).filter('selected')
      .map(o => {
        let name = o.name
        if (o.quantity > 1) { name += ' x' + o.quantity }
        return name
      })
      .value()
    let combined = ''
    if (choiceNames && choiceNames.length > 0) {
      combined += choiceNames.join(', ')
      const found = _.find(modifier.choices, o => {
        return o.selected && o.hsc != true;
      });
      if (!found) return combined
      if (!modifier.associatedChoices || modifier.associatedChoices.length === 0) {
        return combined
      }
      var unselDefSubChoices = _.chain(modifier.associatedChoices)
        .filter((o) => {
          return o.isDefault && !o.selected
        })
        .pluck('name')
        .value()
      if (unselDefSubChoices && unselDefSubChoices.length > 0) {
        combined += ' (No ' + unselDefSubChoices.join(', ') + ')'
      }
      var editedDefaults = _.chain(modifier.associatedChoices)
        .filter((o) => {
          return o.isDefault && o.selected && (o.quantity !== 1 || o.halfhalf !== 1)
        })
        .map((o) => {
          return getAssociatedChoiceName(o)
        })
        .value()
      if (editedDefaults && editedDefaults.length > 0) combined += ' (Edit ' + editedDefaults.join(', ') + ')'
      var selectedAssociatedChoices = _.chain(modifier.associatedChoices)
        .filter((o) => {
          return o.isDefault !== true && o.selected === true
        })
        .map((o) => {
          return getAssociatedChoiceName(o)
        }).value()
      if (selectedAssociatedChoices && selectedAssociatedChoices.length > 0) combined += ' (Add ' + selectedAssociatedChoices.join(', ') + ')'
    }
    return combined

    function getAssociatedChoiceName(choice) {
      var parts = []
      if (choice.halfhalf === 0) parts.push('H1-')
      else if (choice.halfhalf === 2) parts.push('H2-')
      if (choice.quantity > 1) {
        parts.push(String(choice.quantity) + 'X')
      }
      parts.push(choice.name)
      return parts.join(' ')
    }
  }
}

export default modifiers