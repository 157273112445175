<template lang="pug">
.links-body.navy
  v-container
    v-row(wrap)
      v-col.text-center.my-5(cols="12", sm="6")
        .title.mb-5 I am a Business Owner
        v-btn(icon, href="https://bizs.selflane.com", target="_blank")
          v-icon(x-large, color="white") mdi-arrow-right-bold-box
      v-col.text-center.my-5(cols="12", sm="6")
        .title.mb-5 I am a Driver
        v-btn(icon, href="https://driver.selflane.com", target="_blank")
          v-icon(x-large, color="white") mdi-arrow-right-bold-box
</template>

<script>
export default {};
</script>

<style scoped>
.links-body {
  color: white;
  padding-top: 80px;
  padding-bottom: 80px;
}
</style>
