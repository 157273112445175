<template lang="pug">
div
  .d-flex.flex-row.align-center
    #tip-field
      .body-2.mb-2 Add Tip to {{ tipTarget }}
      v-text-field(
        color="secondary",
        step="0.01",
        min="0",
        v-model.number="cartOrder.payment.tip",
        hide-details,
        dense,
        prefix="$",
        @change="setTip(cartOrder.payment.tip)"
      )
    v-row(dense)
      v-col(cols="4", v-for="(item, index) in tips", :key="index")
        v-card.pa-2.text-center(@click="setTip(item.value)", outlined)
          .subtitle-2 {{ item.pct }}%
          .subtitle-2 {{ item.value | currency }}
  v-alert.my-3(v-if="hasExcessiveTip", color="warning", dark) You entered an excessive amount of tip. Please correct it if it is not intended.
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["cartOrder"]),
    tipTarget() {
      if (this.cartOrder.type == "delivery") {
        return "Driver";
      } else {
        return "Staff";
      }
    },
    tips() {
      const tippable = this.cartOrder.payment.dish;
      return _.map([10, 15, 20], (o) => {
        return { pct: o, value: Math.round(tippable * o) / 100 };
      });
    },
    hasExcessiveTip() {
      return (
        this.cartOrder.payment.tip > 10 &&
        this.cartOrder.payment.tip > this.cartOrder.payment.dish
      );
    },
  },
  methods: {
    ...mapActions(["setTip"]),
  },
};
</script>

<style scoped>
#tip-field {
  width: 130px;
  padding-right: 20px;
}
</style>
