<template lang="pug">
v-dialog(v-model="dialog", max-width="300px", @keydown.esc="dialog = false")
  template(v-slot:activator="{ on }")
    v-btn(icon, small, color="secondary", v-on="on")
      v-icon(small) mdi-pencil
  PhoneForm(:phone="phone", @save="save")
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      phone: {},
    };
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async save(phone) {
      if (phone.number == this.order.customer.phone) {
        alert("Same phone number");
        return;
      }
      const params = {
        orderId: this.order._id,
        phone: phone.number,
        portal: "customer",
      };
      try {
        const res = await this.axios.post("/orders/editPhone", params);
        this.updateOrder(res.data);
        this.dialog = false;
      } catch (err) {
        alert(err.response && err.response.data);
      }
    },
  },
};
</script>