<template lang="pug">
v-dialog(v-model="dialog", width="300")
  template(v-slot:activator="{ on }")
    v-btn(icon, v-on="on", color="secondary", small, @click="open")
      v-icon(small) mdi-pencil
  v-card
    v-card-title
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          label="Full Name",
          v-model.trim="name",
          :error-messages="nameErrors",
          :counter="30",
          @blur="$v.name.$touch()",
          autofocus,
          ref="focus"
        )
        v-btn(
          block,
          :disabled="$v.$invalid",
          type="submit",
          color="secondary",
          :loading="loading"
        ) save
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  props: ["ticket"],
  mixins: [validationMixin],
  validations: { name: { required, maxLength: maxLength(30) } },
  data() {
    return {
      dialog: false,
      name: "",
      loading: false,
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Required");
      !this.$v.name.maxLength && errors.push("No more than 30 charactors");
      return errors;
    },
  },
  methods: {
    ...mapActions(["updateEventTicket"]),
    open() {
      this.name = this.ticket.user.name || "";
      this.$v.$reset();
    },
    async submit() {
      if (!this.ticket) return;
      this.loading = true;
      const params = {
        ticket_id: this.ticket._id,
        action: { $set: { "user.name": this.name } },
      };
      try {
        const res = await this.axios.post("/event/tickets/update", params);
        this.updateEventTicket(res.data);
        this.dialog = false;
      } catch (err) {
        //
      }
      this.loading = false;
    },
  },
};
</script>
