const state = {
  bizService: null
}

const getters = {
  bizService: state => state.bizService,
}

const actions = {
  setBizService: ({
    commit
  }, data) => {
    commit('setBizService', data)
  }
}

const mutations = {
  setBizService(state, data) {
    state.bizService = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}