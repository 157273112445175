import moment from 'moment-timezone'

function datetime(unix, format, timezone) {
  if (typeof (unix) !== 'number') return unix
  if (!unix) return ''
  let time = moment(unix)
  if (timezone) time = time.tz(timezone)
  if (format) return time.format(format)
  else return time.format('M/D/YYYY h:mm A')
}

export default datetime