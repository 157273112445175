<template lang="pug">
v-container
  v-row(justify="center", v-if="isAccessible")
    v-card(width="95%", max-width="500")
      v-img(:src="icon")
      v-card-text
        div {{ store_gift_card.message }}
        .text-center.my-10(v-if="!isClaimPath")
          div(v-if="!isClaimed")
            v-btn(color="secondary", rounded, :to="claimPath") Add to My Wallet
            .my-2(v-if="!$auth.check()") You would need to sign in/up first.
          v-btn(v-else, color="secondary", rounded, to="/user/wallet/gift") See in My Wallet
        router-view 
        .headline.my-3 {{ store_gift_card.amount | currency }}
        .d-flex.flex-row(role="button", @click="toBiz()")
          div
            .subtitle {{ store_gift_card.name }}
            .subtitle {{ store_gift_card.address | address }}
          v-spacer
          v-icon mdi-chevron-right
  .my-15(v-else)
    .text-center You Don't Have Access to This Card
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      icon:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/gift_card%2Fgolden-box.png?alt=media&token=12032433-cbd1-43e1-b2f6-90c6647e91dc",
    };
  },
  computed: {
    ...mapGetters(["store_gift_card"]),
    userId() {
      if (this.$auth.check()) return this.$auth.user()._id;
      else return null;
    },
    isClaimPath() {
      return this.$router.history.current.path.indexOf("/claim") != -1;
    },
    isClaimed() {
      return (
        this.store_gift_card &&
        (!this.store_gift_card.to || !this.store_gift_card.to.length)
      );
    },
    isAccessible() {
      if (this.isClaimed) {
        return this.store_gift_card.owner == this.userId;
      }
      return this.store_gift_card != null;
    },
    claimPath() {
      if (!this.store_gift_card) return "";
      return "/store-gift-card/" + this.store_gift_card._id + "/claim";
    },
  },
  methods: {
    ...mapActions(["setStoreGiftCard"]),
    async load() {
      if (!this.id) return;
      const params = {
        criteria: {
          _id: this.id,
        },
      };
      const res = await this.axios.post("/giftcard/retrieve", params);
      this.setStoreGiftCard(res.data);
    },
    handleAddToWallet() {
      if (!this.$auth.check()) {
        this.$router.push("/login");
      }
    },
    async toBiz() {
      if (!this.store_gift_card || !this.store_gift_card.issuer) return;
      const params = {
        criteria: { _id: this.store_gift_card.issuer },
        select: "url",
      };
      const res = await this.axios.post("/bizs/retrieve", params);
      const biz = res.data;
      this.$router.push("/bizs/" + biz.url);
    },
  },
  mounted() {
    this.load();
  },
};
</script>