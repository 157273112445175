<template lang="pug">
div
  vue-headful(title="Gift Cards | Selflane", keywords="Selflane, Gift Cards")
  Head
  Cart.my-5
  FAQs.my-5
  // dialogs
  Activities
  Transfer
  ClaimTransfer
</template>

<script>
import Head from "./Head";
import Cart from "./Cart/index";
import FAQs from "./FAQs/index";
import Activities from "./Activities/index";
import Transfer from "./Transfer/index";
import ClaimTransfer from "./ClaimTransfer";

export default {
  components: { Head, Cart, FAQs, Activities, Transfer, ClaimTransfer },
};
</script>