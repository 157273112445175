<template lang="pug">
div(v-if="type")
  .order-type(v-if="type.pickup.status") Pick up / {{ type.pickup.beforehand | leadtime }}
  .order-type(v-if="type.curbside && type.curbside.status") Curbside / {{ type.curbside.beforehand | leadtime }}
  .order-type(v-if="type.delivery.status") Deliver / {{ type.delivery.beforehand | leadtime }}
</template>

<script>
export default {
  props: ["type"],
};
</script>

<style scoped>
.order-type {
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  border-bottom: 2px solid;
  margin-right: 20px;
}
</style>
