<template lang="pug">
.bottom-actions
  CartTotal
  button.bottom-btn(@click="checkout") Checkout
</template>

<script>
import CartTotal from "./../Cart/CartTotal";
import { mapGetters } from "vuex";

export default {
  components: { CartTotal },
  computed: {
    ...mapGetters(["biz", "cartUrl"]),
  },
  methods: {
    checkout() {
      if (this.biz && this.biz.status && this.biz.status.byAdmin != 1) {
        confirm(
          "This restaurant is not public. Please consider ordering from other public restaurants."
        ) && this.handleNopublic();
        return;
      }
      const url = this.cartUrl + "/checkout";
      this.$router.push(url);
    },
    handleNopublic() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.bottom-actions {
  margin-top: -10px;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  font-size: 17px;
  font-weight: 500;
  color: #fdf9f4;
  height: 58px;
}
.bottom-btn {
  flex: 1 0;
  background: #FF6D32;
}
</style>