<template lang="pug">
v-dialog(
  v-model="dialog",
  width="500",
  v-if="memberAccounts && memberAccounts.length"
)
  template(v-slot:activator="{ on }")
    v-btn.text-notransform(v-on="on", outlined, rounded, color="secondary") Manage Email Subscription
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Manage Member Email Subscription
    v-simple-table
      thead
        tr
          th Biz
          th Balance
          th.text-right Subscription
      tbody
        tr(v-for="(item, index) in memberAccounts", :key="index")
          td {{ bizName(item) }}
          td {{ item.balance }}
          td.text-right
            v-btn.text-notransform(
              small,
              rounded,
              depressed,
              v-if="item.subscribe",
              @click="unsubscribe(item)"
            ) unsubscribe
            v-btn(small, rounded, depressed, v-else, @click="subscribe(item)") subscribe
    v-card-text
      .caption Control email subscription for member's benefits.
</template>

<script>
import _ from "underscore";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["memberAccounts", "bizAccounts"]),
  },
  methods: {
    ...mapActions(["updateMemberAccount"]),
    bizName(member) {
      const bizAccounts = this.bizAccounts;
      const biz = _.find(bizAccounts, (o) => o._id == member.biz);
      if (biz) return biz.name;
      else return "Biz";
    },
    unsubscribe(member) {
      if (!member) return;
      const data = {
        memberId: member._id,
      };
      this.axios
        .post("/member/unsubscribe", data)
        .then((response) => {
          this.updateMemberAccount(response.data);
        })
        .catch((err) => {
          if (err && err.response) alert(err.response.data);
        });
    },
    subscribe(member) {
      if (!member) return;
      const data = {
        memberId: member._id,
      };
      this.axios
        .post("/member/subscribe", data)
        .then((response) => {
          this.updateMemberAccount(response.data);
        })
        .catch((err) => {
          if (err && err.response) alert(err.response.data);
        });
    },
  },
};
</script>