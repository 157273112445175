<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between
    .sl-title Reservations
    YearSelector(@select="setYear")
  v-divider.my-2
  List
</template>

<script>
import List from "@/components/Reservation/List";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: { List },
  data() {
    return {
      year: null,
    };
  },
  methods: {
    ...mapActions(["setReservations"]),
    setYear(year) {
      if (year) this.year = year;
      this.load();
    },
    load() {
      if (!this.$auth.user()) return null;
      if (!this.year) return;
      const id = this.$auth.user()._id;
      const begin = moment().year(this.year).startOf("year").unix() * 1000;
      const end = moment().year(this.year).endOf("year").unix() * 1000;
      const params = {
        criteria: {
          "customer.id": id,
          needed: {
            $gte: begin,
            $lt: end,
          },
        },
        select: "seller created needed status tableId total_people",
        limit: 50,
      };
      this.axios
        .post("/reservations/list", params)
        .then((response) => {
          this.setReservations(response.data);
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.setReservations([]);
  },
};
</script>
