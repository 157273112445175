<template lang="pug">
.company-body
  vue-headful(
    title="Selflane Company",
    keywords="Selflane,Restaurants,Online Food,Food Delivery,Online Reservation,Point of Sale"
  )
  About
  Links
  Content
</template>

<script>
import About from "./About";
import Links from "./Links";
import Content from "./Content";

export default {
  components: { About, Links, Content },
};
</script>

<style scoped>
.company-body {
  background-color: white;
  height: 100%;
}
</style>
