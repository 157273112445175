<template lang="pug">
v-dialog(v-model='dialog' width="400")
  v-card(v-if='card')
    v-card-text
      div(v-for='d in card.debits' :key='d._id')
        .item(@click='showOrder(d.order)')
          v-flex
            .item-name Debit
            .item-detail {{ d.order | objectIdDate }}
          div {{ -d.amount | currency }}
            span(v-if='d.void').red--text.ml-2 Canceled
        .sl-dash-divider
      div(v-for='c in card.credits' :key='c._id')
        .item(@click='showOrder(c.order)')
          v-flex
            .item-name Credit
            .item-detail {{ c.order | objectIdDate }}
          div {{ c.amount | currency }}
            span(v-if='c.void').red--text.ml-2 Canceled
        .sl-dash-divider
    v-card-text.text-center(v-if='qrcode')
      QrcodeVue(:value='qrcode' size='150' level='H')
      span Use this QR Code for purchase.
    EmailForm(:card='card' :dialog='dialog' @transferred='dialog=false')
</template>

<script>
import QrcodeVue from "qrcode.vue";
import EmailForm from "./EmailForm";
import { EventBus } from "@/event-bus.js";

export default {
  name: "GiftDetail",
  components: { QrcodeVue, EmailForm },
  data() {
    return {
      dialog: false,
      card: null
    };
  },
  computed: {
    qrcode() {
      if (this.card) return "sl:gc:" + this.card._id;
      else return null;
    }
  },
  methods: {
    show(id) {
      if (!id) return;
      this.dialog = true;
      const options = {
        criteria: { _id: id }
      };
      this.axios
        .post("/giftCard/retrieve", options)
        .then(response => {
          this.card = response.data;
        })
        .catch(err => {
          if (err.response) alert(err.response.data);
        });
    },
    showOrder(orderId) {
      EventBus.$emit("show-order-detail", orderId);
    }
  },
  mounted() {
    EventBus.$on("show-gift-card", this.show);
  },
  destroyed() {
    EventBus.$off("show-gift-card");
  }
};
</script>

<style scoped>
.items-container {
  padding-left: 40px;
  padding-right: 40px;
}
.item {
  display: flex;
  align-items: center;
  height: 54px;
  cursor: pointer;
}
.item-name {
  font-size: 15px;
}
.item-detail {
  font-size: 14px;
  color: rgba(28, 31, 52, 0.6);
}
</style>
