<template lang="pug">
v-btn(
  rounded,
  depressed,
  small,
  color="#E4E6EB",
  @click="toggle()",
  :loading="loading",
  v-if="biz"
)
  v-icon(small, left, :color="color") mdi-heart
  span {{ biz.stats.fav }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    isFavored() {
      if (!this.$auth.check()) return false;
      if (!this.biz) return false;
      const preference = this.$auth.user().preference;
      return preference.myfavs.indexOf(this.biz._id) !== -1;
    },
    color() {
      if (this.biz && this.isFavored) return "red";
      return "grey";
    },
  },
  methods: {
    async toggle() {
      if (!this.biz) return;
      if (!this.$auth.check()) {
        this.$router.push("/login");
        return;
      }
      this.loading = true;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      let bizData = {
        criteria: { _id: bizId },
        action: { $inc: { "stats.fav": 1 } },
      };
      let userData = {
        criteria: { _id: userId },
        action: { $addToSet: { "preference.myfavs": bizId } },
      };
      if (this.isFavored) {
        bizData.action = { $inc: { "stats.fav": -1 } };
        userData.action = { $pull: { "preference.myfavs": bizId } };
      }
      const bizRes = await this.axios.post("/bizs/update", bizData);
      if (bizRes && bizRes.data) {
        this.biz.stats = bizRes.data.stats;
      }
      const userRes = await this.axios.post("/users/update", userData);
      this.$auth.user(userRes.data);
      this.loading = false;
    },
  },
};
</script>
