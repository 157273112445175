<template lang="pug">
v-container
  vue-headful(
    title="Selflane",
    keywords="Selflane,Restaurants,Online Food,Food Delivery,Online Reservation,Point of Sale"
  )
  v-row.mb-10(justify="center")
    v-card(flat, max-width="500", width="95%", color="transparent")
      Name
      AddressBook
      PhoneBook
      Cards
      Password
      v-divider
      DeleteAccount
</template>

<script>
import Name from "./name/index";
import AddressBook from "./AddressBook/index";
import PhoneBook from "./PhoneBook";
import Cards from "./Cards/index";
import Password from "./Password/index";
import DeleteAccount from "./DeleteAccount/index";

export default {
  components: { Name, AddressBook, PhoneBook, Cards, Password, DeleteAccount },
};
</script>
