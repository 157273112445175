import _ from 'underscore'
import moment from 'moment-timezone'
import BizUtils from './../../utils/biz';

const state = {
  weekly_specials: [],
  geoService: {},
  cart: {
    giftProgram: {},
    biz: {},
    order: {
      seller: {},
      customer: {},
      orderer: {
        type: 0
      },
      dishes: [],
      gifts: [],
      deliv: undefined,
      payment: {
        dish: 0,
        tax: 0,
        fee: 0,
        gift: 0,
        tip: 0,
        deduction: {
          total: 0,
          items: []
        },
        selflane: {
          total: 0,
          items: []
        },
        total: 0,
        card: {},
        charges: []
      },
      summary: [],
      type: undefined,
      needed: 0,
      note: '',
      proxy: 'Vue',
      promo: null
    },
    promotion: null,
  },
  cartAddressResult: null,
  due: 0,
  restaurantTotal: 0,
  serviceFeeRate: 0,
  minServiceFee: 0,
  inclusiveRange: 0,
  maxRange: 0,
  unitFee: 0,
  deliveryFee: 2.99
}

const getters = {
  cartGeoService: state => state.geoService,
  weekly_specials: state => state.weekly_specials,
  cartOrder: state => state.cart.order,
  cartAddressResult: state => state.cartAddressResult,
  cartBiz: state => state.cart.biz,
  cartPromotion: state => state.cart.promotion,
  cartCount: (state) => {
    const dishCount = _.reduce(state.cart.order.dishes, (memo, item) => {
      return memo + item.quantity
    }, 0)
    const giftCount = state.cart.order.gifts.length
    return dishCount + giftCount
  },
  /// return the home page url for a business
  cartUrl: (state) => {
    if (state.cart.biz.url) return '/bizs/' + state.cart.biz.url
    else return ''
  },
  cartDue: state => state.due,
  cartRestaurantTotal: state => state.restaurantTotal,
  cartServiceFeeRate: state => state.serviceFeeRate,
  cartMinServiceFee: state => state.minServiceFee,
  cartInclusiveRange: state => state.inclusiveRange,
  cartMaxRange: state => state.maxRange,
  cartUnitFee: state => state.unitFee
}

const actions = {
  setWeeklySpecials: ({
    commit
  }, data) => {
    commit('setWeeklySpecials', data)
  },
  addDishToCart: ({
    commit
  }, data) => {
    commit('addDishToCart', data)
  },
  removeDishAt: ({
    commit
  }, data) => {
    commit('removeDishAt', data)
  },
  editDishAt: ({
    commit
  }, data) => {
    commit('editDishAt', data)
  },
  addGiftToCart: ({
    commit
  }, data) => {
    commit('addGiftToCart', data)
  },
  removeGiftAt: ({
    commit
  }, data) => {
    commit('removeGiftAt', data)
  },
  validateAddress: ({
    commit,
  }) => {
    commit('validateAddress')
  },
  setFeeStructure: ({
    commit,
  }) => {
    commit('setFeeStructure')
  },
  updateCartPayment: ({
    commit,
    rootState
  }) => {
    commit('updateCartPayment', rootState)
  },
  resetCart: ({
    commit
  }) => {
    commit('resetCart')
  },
  prepareCheckout: ({
    commit
  }, data) => {
    commit('prepareCheckout', data)
  },
  prepareCartNeeded: ({
    commit
  }, data) => {
    commit('prepareCartNeeded', data)
  },
  setTip: ({
    commit
  }, data) => {
    commit('setTip', data)
  },
  setPhone: ({
    commit
  }, data) => {
    commit('setPhone', data)
  },
  setAddress: ({
    commit
  }, data) => {
    commit('setAddress', data)
  },
  setCard: ({
    commit
  }, data) => {
    commit('setCard', data)
  },
  setNote: ({
    commit
  }, data) => {
    commit('setNote', data)
  },
  setPromotion: ({
    commit
  }, data) => {
    commit('setPromotion', data)
  },
  applyGC: ({
    commit
  }, data) => {
    commit('applyGC', data)
  },
  removeGC: ({
    commit
  }, data) => {
    commit('removeGC', data)
  },
  setCartBiz: ({
    commit
  }, data) => {
    commit('setCartBiz', data)
  },
  setCartGeoService: ({
    commit
  }, data) => {
    commit('setCartGeoService', data)
  },
  submitCart: ({
    commit,
    state
  }) => {
    return new Promise((resolve, reject) => {
      // validate cart
      let errors = []
      const order = state.cart.order
      const biz = state.cart.biz
      const hasAlcohol = _.find(order.summary, o => o.classification == 3) != undefined
      if (!hasSeller(order.seller)) errors.push('Invalid seller')
      if (!hasCustomer(order.customer)) errors.push('Name is required')
      if (!hasCustomerPhone(order.customer)) errors.push('Phone is required')
      if (!hasType(order.type)) errors.push('Invalid type')
      if (order.type === 'delivery') {
        if (!state.cartAddressResult || !state.cartAddressResult.status) {
          errors.push(state.cartAddressResult.message)
        }
        if (!order.deliv)
          if (!hasMinimumCharge(order.payment)) errors.push('Delivery order requires a minimum subtotal of $15 before tax and tips. Please either add more selections or change to pick up')
        if (hasAlcohol) {
          const minimumTip = Math.round(order.payment.dish * 10) / 100
          if (order.payment.tip < minimumTip) {
            errors.push('A minimum 10% ($' + minimumTip + ') tip on an alcohol delivery order is required')
          }
        }
      }
      if (!hasCharge(order.payment)) errors.push('Requires a minimum of $5 order')
      if (!hasPaymentMethod(order.payment)) errors.push('Please provide a credit card payment method')
      if (!hasItems(order)) errors.push('Empty cart')
      if (errors.length) {
        reject(errors)
        return
      }
      if (order.dishes && order.dishes.length) {
        const result = BizUtils.validateTypeTime(order.type, order.needed, biz, state.geoService)
        if (result.status === false) errors.push(result.error)
        else {
          errors = errors.concat(validateDishes(order, biz))
        }
      }
      if (errors.length) {
        reject(errors)
        return
      }
      if (order.type !== 'delivery') {
        order.customer.address = {}
      }
      if (state.cart.promotion && state.cart.promotion._id) {
        order.promo = state.cart.promotion._id
      } else {
        order.promo = null
      }
      const timezone = order?.seller?.address?.timezone || moment.tz.guess()
      order.neededStr = moment.tz(order.needed, timezone).format('YYYY-MM-DD HH:mm')
      window.axios.post('/orders/createOnline', order).then(response => {
        resolve(response)
        commit('resetCart')
      }).catch(err => {
        if (err.response) reject(err.response.data)
        else reject('Failed to pass server validation')
      })
    })

    function isEmptyStr(str) {
      return !str || str.length === 0
    }

    function hasEmptyStr(list) {
      if (!list || list.length === 0) return true
      let hasEmptry = false
      _.each(list, str => {
        if (isEmptyStr(str)) hasEmptry = true
      })
      return hasEmptry
    }

    function hasSeller(seller) {
      return !hasEmptyStr([seller.id, seller.url, seller.name])
    }

    function hasCustomer(customer) {
      return !hasEmptyStr([customer.id, customer.name, customer.email])
    }

    function hasCustomerPhone(customer) {
      return !isEmptyStr(customer.phone)
    }

    function hasItems(order) {
      if (order.dishes && order.dishes.length) return true
      if (order.gifts && order.gifts.length) return true
      return false
    }

    function hasCharge(payment) {
      return (payment && payment.total && payment.total > 5)
    }

    function hasMinimumCharge(payment) {
      return (payment && payment.dish && payment.dish >= 15)
    }

    function hasPaymentMethod(payment) {
      const gcPaid = _.reduce(payment.charges, (memo, c) => {
        return memo + c.amount;
      }, 0);
      const due = payment.total - gcPaid;
      if (due > 0) {
        return (payment && payment.card && !hasEmptyStr([payment.card.id, payment.card.last4, payment.card.brand]) && payment.card.type !== undefined)
      }
      return true;
    }

    function hasType(type) {
      return _.contains(['pickup', 'delivery', 'dinein', 'curbside'], type)
    }

    function validateDishes(order, biz) {
      const errors = []
      const availableMenus = []
      _.each(biz.menus, menu => {
        if (!menu.selectedTime) {
          availableMenus.push(menu._id)
        } else {
          const valid = validateMenu(menu, moment(order.needed))
          if (valid) availableMenus.push(menu._id)
        }
      })
      _.each(order.dishes, dish => {
        const crosscheck = _.intersection(availableMenus, dish.menus)
        if (crosscheck === undefined || crosscheck.length === 0) {
          errors.push(dish.name + ' is not available at the selected time')
        }
      })
      return errors
    }
  }
}

// validate both in today and the day before
// menu is Biz.Menu object
// time is moment object
function validateMenu(menu, time) {
  let minute = time.hour() * 60 + time.minute()
  let day = time.day()
  let validDay = _.contains(menu.date, day)
  let validMinute = BizUtils.validateSchedule(minute, menu.schedule)
  if (validDay && validMinute) return true
  day -= 1
  if (day < 0) day = 6
  minute += 24 * 60
  validDay = _.contains(menu.date, day)
  validMinute = BizUtils.validateSchedule(minute, menu.schedule)
  return (validDay && validMinute)
}

const mutations = {
  setWeeklySpecials(state, data) {
    state.weekly_specials = data
  },
  addDishToCart(state, data) {
    const dish = data.dish
    const biz = data.biz
    const hasBiz = state.cart && state.cart.biz && state.cart.biz._id
    const isSameBiz = hasBiz && (state.cart.biz._id === biz._id)

    if (!hasBiz) {
      this.dispatch('setCartBiz', biz)
    } else if (!isSameBiz) {
      this.dispatch('resetCart')
      this.dispatch('addDishToCart', data)
      return
    }
    state.cart.order.dishes.push(dish)
    this.dispatch('updateCartPayment')
  },
  removeDishAt(state, index) {
    if (index < state.cart.order.dishes.length) {
      state.cart.order.dishes.splice(index, 1)
      this.dispatch('updateCartPayment')
    }
  },
  editDishAt(state, data) {
    if (data.index < state.cart.order.dishes.length) {
      state.cart.order.dishes[data.index] = data.dish
      state.cart.order.dishes = JSON.parse(JSON.stringify(state.cart.order.dishes))
      this.dispatch('updateCartPayment')
    }
  },
  addGiftToCart(state, data) {
    const value = data.value
    const biz = data.biz
    if (!value || !biz) return
    const hasBiz = state.cart && state.cart.biz && state.cart.biz._id
    const isSameBiz = hasBiz && (state.cart.biz._id === biz._id)
    state.cart.giftProgram = data.giftProgram

    if (!hasBiz) {
      this.dispatch('setCartBiz', biz)
    } else if (!isSameBiz) {
      this.dispatch('resetCart')
      this.dispatch('addGiftToCart', data)
      return
    }
    if (!state.cart.order.gifts) {
      state.cart.order.gifts = [{
        amount: value
      }];
    } else {
      state.cart.order.gifts.push({
        amount: value
      });
    }
    this.dispatch('updateCartPayment')
  },
  removeGiftAt(state, index) {
    if (state.cart.order.gifts && state.cart.order.gifts.length > index) {
      state.cart.order.gifts.splice(index, 1);
      this.dispatch('updateCartPayment')
    }
  },
  validateAddress(state) {
    const order = state.cart.order
    if (order.type != 'delivery') {
      state.cartAddressResult = {
        status: true,
        message: ''
      }
      return
    }
    if (!order.customer.address || !order.customer.address.geometry || !order.customer.address.geometry.lat) {
      state.cartAddressResult = {
        status: false,
        message: 'Missing address information'
      }
      return
    }

    const biz = state.cart.biz
    let country = 'US'
    if (biz && biz.address && biz.address.country) {
      country = biz.address.country.trim()
    }
    let distanceUnit = 'mi.'
    if (country != "US" && country != "United States") distanceUnit = 'km'
    const result = BizUtils.geoDistance2(
      order.seller.address.geometry,
      order.customer.address.geometry,
      distanceUnit
    );

    const is3rdDelivery = biz.orderType.delivery && biz.orderType.delivery.deliv
    if (!is3rdDelivery) {
      const zoneStatus = biz.zone && biz.zone.status
      if (zoneStatus) {
        const status = BizUtils.insidePolygon(order.customer.address.geometry, biz.zone.polygon)
        state.cartAddressResult = {
          status: status,
          distance: result.value,
          distanceUnit: distanceUnit,
          message: status ? "" : 'Outside of delivery zone'
        }
        return
      }
    }

    let message = ''
    const distance = result.value
    if (distance > state.maxRange) {
      message = 'Delivery address should be in ' + state.maxRange + ' ' + distanceUnit + ' radius. Yours is ' + result.value + ' ' + distanceUnit
    } else if (distance > state.inclusiveRange) {
      message = 'Radius beyond ' + state.inclusiveRange + ' ' + distanceUnit + ' will have extra charge'
    }
    state.cartAddressResult = {
      status: result.value < state.maxRange,
      distance: distance,
      distanceUnit: distanceUnit,
      message: message
    }
  },
  updateCartPayment(state, rootState) {
    this.dispatch('setFeeStructure')
    this.dispatch('validateAddress')

    let dishTotal = 0
    if (state.cart.order.dishes && state.cart.order.dishes.length) {
      dishTotal = _.reduce(state.cart.order.dishes, (memo, dish) => {
        let pctOff = 0
        if (dish.pctOff && dish.pctOff > 0 && dish.pctOff <= 100) {
          pctOff = dish.pctOff
        }
        const dishValue = Math.round(dish.unitPrice * (100 - pctOff) * dish.quantity) / 100
        return memo + dishValue
      }, 0)
    }
    state.cart.order.payment.dish = Math.round(dishTotal * 100) / 100

    /**
     * Selflane Fees
     */
    state.cart.order.payment.selflane = {
      total: 0,
      items: []
    }
    const isDelivery = state.cart.order.type == 'delivery'
    const is3rdDelivery = isDelivery && state.cart.biz.orderType.delivery && state.cart.biz.orderType.delivery.deliv
    /// 1. set service fee
    let serviceFee = 0
    if (is3rdDelivery) {
      serviceFee = Math.max(Math.round(dishTotal * state.serviceFeeRate) / 100, state.minServiceFee)
    } else {
      const payment = state.cart.order.payment
      const totalForServiceFee = payment.dish + payment.tip + payment.tax
      serviceFee = Math.max(Math.round(totalForServiceFee * state.serviceFeeRate) / 100, state.minServiceFee)
    }
    state.cart.order.payment.selflane.items.push({
      name: 'service_fee',
      value: serviceFee
    })

    /// 2. set delivery fee
    state.cart.order.payment.fee = 0
    if (is3rdDelivery) {
      state.cart.order.payment.selflane.items.push({
        name: 'delivery_fee',
        value: state.deliveryFee
      })
    } else if (isDelivery) {
      state.cart.order.payment.fee = state.deliveryFee
    }

    /// 3. set extra mile fee
    if (is3rdDelivery && state.cart.order.seller.address && state.cart.order.customer.address) {
      let distance = state.cartAddressResult.distance
      if (distance > state.inclusiveRange) {
        const distanceSurcharge = Math.ceil(distance - state.inclusiveRange) * state.unitFee
        state.cart.order.payment.selflane.items.push({
          name: 'distance_surcharge',
          value: distanceSurcharge
        })
      }
    }
    /**
     * Calculate payment deduction
     */
    state.cart.order.payment.deduction = {
      total: 0,
      items: []
    }
    /// 1. delivery discount
    if (state.cart.order.type == 'delivery' && state.cart.biz && state.cart.biz.delivTiers) {
      let copyList = JSON.parse(JSON.stringify(state.cart.biz.delivTiers))
      const found = _.find(copyList.reverse(), tier => {
        return dishTotal >= tier.threshold
      })
      if (found) {
        state.cart.order.payment.deduction.items.push({
          name: 'Delivery discount',
          value: Math.min(found.deduction, state.deliveryFee)
        })
      }
    }
    /// 2. gift card discount
    state.cart.order.payment.gift = _.reduce(state.cart.order.gifts, (memo, item) => {
      return memo + item.amount
    }, 0)
    if (state.cart.order.payment.gift > 0) {
      if (state.cart.giftProgram && state.cart.giftProgram.discount && state.cart.giftProgram.discount.status && state.cart.giftProgram.discount.minimum <= state.cart.order.payment.gift) {
        const value = Math.round(state.cart.order.payment.gift * state.cart.giftProgram.discount.pct) / 100
        state.cart.order.payment.deduction.items.push({
          name: 'Gift card discount',
          value: value
        })
      }
    }
    /// weekly special
    if (state.weekly_specials && state.weekly_specials.length && state.cart.order.needed) {
      const needed_in_day = moment(state.cart.order.needed).day()
      // find first qualified special
      const found = _.find(state.weekly_specials, o => {
        if (o.biz != state.cart.biz._id) return false
        const validDay = _.find(o.days, d => d == needed_in_day)
        if (validDay) return true
        else return false
      })
      if (found && found.pct > 0 && found.pct < 100) {
        const value = Math.round(found.pct * state.cart.order.payment.dish) / 100
        if (value > 0) {
          state.cart.order.payment.deduction.items.push({
            name: found.name + " " + found.pct + '% off',
            value: value
          })
        }
      }
    }
    /// check influencer
    let influencerModule = rootState.influencer
    let result = BizUtils.getInfluencerPlan(influencerModule.influencerPlans, influencerModule.influencers, influencerModule.influencerCode)
    state.cart.order.influencer = null
    let influencerDeduction = 0
    if (result.plan && state.cart.order.payment.dish > result.plan.minimum) {
      if (result.plan.type == 'pct') {
        influencerDeduction = Math.min(state.cart.order.payment.dish, result.plan.limit) * result.plan.pct / 100
      } else {
        influencerDeduction = result.plan.fix
      }
      let name = 'Influencer Discount'
      if (!result.plan.is_open) {
        name = 'Influencer ' + influencerModule.influencerCode
      }
      state.cart.order.payment.deduction.items.push({
        name: name,
        value: Math.round(influencerDeduction * 100) / 100
      })
      state.cart.order.influencer = {
        account: result.influencer._id,
        code: result.influencer.code,
        plan: result.plan._id
      }
    }

    state.cart.order.payment.deduction.total = _.reduce(state.cart.order.payment.deduction.items, (memo, item) => {
      return memo + item.value
    }, 0)
    state.cart.order.payment.selflane.total = _.reduce(state.cart.order.payment.selflane.items, (memo, item) => {
      return memo + item.value
    }, 0)
    state.cart.order.payment.tax_items = getTaxItems(state.cart.order.dishes, state.cart.biz.tax_items)
    state.cart.order.summary = getSummary(state.cart.order.dishes, state.cart.biz)
    state.cart.order.payment.tax = _.reduce(state.cart.order.payment.tax_items, (memo, item) => {
      if (item.type == 0) {
        return memo + item.value
      } else {
        return memo
      }
    }, 0)
    state.cart.order.payment.tax = Math.round(state.cart.order.payment.tax * 100) / 100
    state.cart.order.payment.total = Math.round((state.cart.order.payment.dish +
      state.cart.order.payment.tax + state.cart.order.payment.fee + state.cart.order.payment.gift +
      state.cart.order.payment.tip - state.cart.order.payment.deduction.total +
      state.cart.order.payment.selflane.total) * 100) / 100
    const paid = _.reduce(state.cart.order.payment.charges, (memo, item) => {
      return memo + item.amount
    }, 0);
    state.due = Math.round((state.cart.order.payment.total - paid) * 100) / 100;
    state.restaurantTotal = Math.round((state.cart.order.payment.total - state.cart.order.payment.selflane.total) * 100) / 100;
  },
  setFeeStructure(state) {
    state.serviceFeeRate = 4 // basic fee rate. apply to any orders
    state.minServiceFee = 0 // minimum fee if the percentage result is too little
    state.deliveryFee = 2.99
    state.inclusiveRange = 5
    state.maxRange = 5
    state.unitFee = 1.5

    const order = state.cart.order
    const hasAlcohol = _.find(order.summary, o => o.classification == 3) != undefined
    const isDelivery = order.type == 'delivery'
    const is3rdDelivery = isDelivery && state.cart.biz.orderType.delivery && state.cart.biz.orderType.delivery.deliv
    if (is3rdDelivery) {
      if (state.geoService && state.geoService.delivery) {
        state.serviceFeeRate = state.geoService.delivery.rate
        state.minServiceFee = state.geoService.delivery.minService
        state.deliveryFee = state.geoService.delivery.fee
        state.inclusiveRange = state.geoService.delivery.inclusiveRange
        state.maxRange = state.geoService.delivery.maxRange
        state.unitFee = state.geoService.delivery.unitFee
      }
    } else {
      if (state.geoService && state.geoService.basicRate != undefined) {
        state.serviceFeeRate = state.geoService.basicRate
        state.minServiceFee = state.geoService.minBasic || 0
      }
      // self delivery has no extended range
      if (state.cart.biz.orderType) {
        state.inclusiveRange = state.cart.biz.orderType.delivery.radius
        state.deliveryFee = state.cart.biz.orderType.delivery.price
      }
      state.maxRange = state.inclusiveRange
    }
    if (hasAlcohol) {
      state.serviceFeeRate += 4
    }
  },
  resetCart(state) {
    state.cart = {
      giftProgram: {},
      biz: {},
      order: {
        seller: {},
        customer: {},
        orderer: {
          type: 0
        },
        dishes: [],
        gifts: [],
        deliv: undefined,
        payment: {
          dish: 0,
          tax_items: [],
          tax: 0,
          fee: 0,
          gift: 0,
          tip: 0,
          deduction: {
            total: 0,
            items: []
          },
          selflane: {
            total: 0,
            items: []
          },
          total: 0,
          card: {},
          charges: []
        },
        summary: [],
        type: undefined,
        needed: 0,
        note: '',
        proxy: 'Vue',
        promo: null
      },
      promotion: null,
    }
  },
  prepareCheckout(state, user) {
    if (!user) return
    if (!state.cart.order.customer || state.cart.order.customer.id != user._id) {
      const userPhone = (user.phones && user.phones.length) ? user.phones[0].number : ''
      state.cart.order.customer = {
        id: user._id,
        name: user.name.preferred || user.name.first,
        email: user.email,
        phone: userPhone,
        address: _.first(user.addresses)
      }
    }
    // set type and needed time
    if (!state.cart.order.dishes || !state.cart.order.dishes.length) {
      // gift card only
      state.cart.order.type = 'pickup'
    } else if (!state.cart.order.type) {
      presetType()
    }
    this.dispatch('prepareCartNeeded')

    function presetType() {
      const types = state.cart.biz.orderType
      if (types.pickup.status) {
        state.cart.order.type = 'pickup'
      } else if (types.curbside.status) {
        state.cart.order.type = 'curbside'
      } else if (types.delivery.status) {
        state.cart.order.type = 'delivery'
      }
    }
    if (state.cart.order.payment.dish == 0) {
      state.cart.order.payment.tip = 0
    }
    if (state.cart.order.payment.tip == 0) {
      state.cart.order.payment.tip = Math.round(state.cart.order.payment.dish * 10) / 100
    }
  },
  prepareCartNeeded(state) {
    const biz = state.cart.biz
    const isDelivery = state.cart.order.type == 'delivery'
    const is3rdDelivery = isDelivery && state.cart.biz.orderType.delivery && state.cart.biz.orderType.delivery.deliv
    let leadtime = 20
    if (state.cart.order.type) {
      const type = _.property(state.cart.order.type)(state.cart.biz.orderType)
      if (type && type.beforehand != null) {
        leadtime = type.beforehand
      }
    }
    // in minutes
    let earliestTime = getEarliestMinutes(biz.schedule, leadtime, isDelivery, is3rdDelivery)
    // in case of daylight saving date, shift the time by an hour.
    // if (moment().isDST()) {
    //   if (moment().month() == 2) {
    //     earliestTime -= 60
    //   } else if (moment().month() == 10) {
    //     earliestTime += 60
    //   }
    // }
    let earliestTimeUnix = moment().startOf('day').add(earliestTime, 'minutes').unix() * 1000

    if (state.cart.order.needed) {
      const currentNeeded = moment(state.cart.order.needed).unix() * 1000
      const two_hour = 10800000 // 3 * 60 * 60 * 1000
      if (currentNeeded < earliestTimeUnix + two_hour) state.cart.order.needed = earliestTimeUnix
    } else {
      state.cart.order.needed = earliestTimeUnix
    }
    this.dispatch('updateCartPayment')
  },
  setTip(state, tip) {
    if (tip < 0) tip = 0
    state.cart.order.payment.tip = Math.round(tip * 100) / 100
    this.dispatch('updateCartPayment')
  },
  setPhone(state, number) {
    state.cart.order.customer.phone = number
  },
  setAddress(state, address) {
    state.cart.order.customer.address = address
    this.dispatch('updateCartPayment')
  },
  setCard(state, card) {
    if (!card || !card.id) {
      state.cart.order.payment.card = {}
    } else {
      state.cart.order.payment.card = {
        id: card.id,
        last4: card.last4,
        brand: card.brand,
        type: 0
      }
    }
  },
  setNote(state, note) {
    state.cart.order.note = note
  },
  setCartBiz(state, biz) {
    state.cart.biz = biz
    const sellerPhone = (biz.phones && biz.phones.length) ? biz.phones[0].number : ''
    state.cart.order.seller = {
      id: biz._id,
      url: biz.url,
      name: biz.name,
      industry: biz.industry,
      phone: sellerPhone,
      address: biz.address,
      taxRate: biz.taxRate
    }
  },
  setCartGeoService(state, data) {
    if (state.geoService == undefined) {
      state.cart = _.extend(state.cart, {
        geoService: {}
      })
    }
    state.geoService = data
  },
  setPromotion(state, promotion) {
    if (!promotion) return
    if (state.cart.order.payment.dish < promotion.minimum) {
      alert('Minimum purchase of $' + promotion.minimum + ' is required')
      return
    }
    if (state.cart.promotion && promotion && state.cart.promotion._id == promotion._id) state.cart.promotion = null
    else state.cart.promotion = promotion
    state.cart.order.payment.charges = []
    this.dispatch('updateCartPayment')
  },
  applyGC(state, gc) {
    state.cart.promotion = null
    if (state.due <= 0) {
      alert('Order has no amount due')
      return;
    }
    const found = _.find(state.cart.order.payment.charges, o => o.transId === gc._id);
    if (found) {
      const message = 'A same gift card is found in the payment. If you want to apply this gift card again after cart is edited, you need to remove this card first.'
      confirm(message) && this.dispatch('removeGC', gc._id);
      return;
    }
    const amount = Math.min(state.due, gc.balance);
    const charge = {
      base: amount,
      amount: amount,
      method: 'gift',
      transId: gc._id
    };
    state.cart.order.payment.charges.push(charge);
    this.dispatch('updateCartPayment')
  },
  removeGC(state, id) {
    state.cart.order.payment.charges = _.reject(state.cart.order.payment.charges, o =>
      o.transId === id
    );
    this.dispatch('updateCartPayment')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

/**
 * get earliest minutes available for a biz
 * @param {*} schedule 
 * @param {*} leadtime 
 * @param {*} isDelivery 
 * @param {*} is3rdDelivery 
 */
function getEarliestMinutes(schedule, leadtime, isDelivery, is3rdDelivery) {
  const today = BizUtils.getDaySchedule(schedule, moment())

  let minutes = moment().hour() * 60 + moment().minute() + leadtime + 5
  // limit 3rd party delivery to 8:30-22; 510-1320
  if (is3rdDelivery && isDelivery && minutes < 510) {
    minutes = 510
  }

  // if business is closed on the selected date, simply return the current earliest time
  if (!today.status) {
    return minutes
  }

  let opening_leadtime = 10
  if (isDelivery) {
    opening_leadtime = 30
  }

  // if earliest time is in time window
  const found = _.find(today.schedule, o => {
    if (o.range && o.range.length >= 2) {
      return o.range[0] + opening_leadtime <= minutes && o.range[1] >= minutes
    }
    return false
  })
  if (found) {
    return minutes
  }
  let found_block = _.find(today.schedule, o => {
    if (o.range && o.range.length == 2) {
      return o.range[0] + opening_leadtime > minutes
    }
  })
  if (found_block) {
    return found_block.range[0] + opening_leadtime
  }
  return minutes
}

function getSummary(dishes, biz) {
  return _.chain(dishes)
    .filter(o => o.course)
    .groupBy('course')
    .map((list, key) => {
      const found = _.find(biz.courses, course => course._id == key)
      let classification = 0
      if (found) classification = found.classification || 0
      return {
        course: key,
        classification: classification,
        amount: _.reduce(list, (memo, dish) => {
          return memo + getDishValue(dish)
        }, 0)
      }
    })
    .groupBy('classification')
    .map((list, key) => {
      return {
        classification: key,
        amount: _.reduce(list, (memo, item) => {
          return memo + item.amount
        }, 0)
      }
    })
    .value()
}

function getTaxItems(dishes_input, tax_items_input) {
  // filter inactive items
  const tax_items = _.reject(tax_items_input, o => !o.status)
  // courses with special tax
  const special_courses = _.chain(tax_items).pluck('courses').flatten().uniq().compact().value()
  const dishes = _.map(dishes_input, o => {
    return {
      name: o.name,
      value: getDishValue(o),
      course: o.course
    }
  })
  const regular_dishes = []
  const special_dishes = []
  _.each(dishes, o => {
    const found = _.find(special_courses, course => course == o.course)
    if (found) {
      special_dishes.push(o)
    } else {
      regular_dishes.push(o)
    }
  })
  const result = _.map(tax_items, o => {
    let passed = []
    if (o.courses && o.courses.length > 0) {
      passed = _.filter(special_dishes, dish => {
        const found = _.find(o.courses, course => course == dish.course)
        return found != null
      })
    } else {
      passed = regular_dishes
    }
    const total = _.reduce(passed, (memo, item) => {
      return memo + item.value
    }, 0)
    let value = 0
    if (o.type == 0) {
      value = Math.round(total * o.percentage) / 100
    } else {
      // equation for inclusive tax calculation
      value = Math.round(total / (1 + o.percentage / 100) * o.percentage) / 100
    }
    return {
      name: o.name,
      value: value,
      percentage: o.percentage,
      type: o.type
    }
  })
  return _.reject(result, o => !o.value)
}

function getDishValue(dish) {
  let pctOff = 0
  if (dish.pctOff && dish.pctOff > 0 && dish.pctOff <= 100) {
    pctOff = dish.pctOff
  }
  let div = dish.div
  if (!div || div < 1) div = 1
  if (dish.uom && dish.uom.length > 1) {
    return Math.round(dish.unitPrice * (100 - pctOff) * dish.wom) / 100
  } else {
    return Math.round(dish.unitPrice * (100 - pctOff) * dish.quantity / div) / 100
  }
}