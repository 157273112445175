/// present suggested biz in the biz home page
/// find close biz in parameter (0.1 rad)
/// rank by distance to user
/// only present first three

<template lang="pug">
div(v-if="bizs && bizs.length")
  .title-style Nearby Restaurants
  BizList(:bizs="bizs", :loading="loading")
</template>

<script>
import _ from "underscore";
import BizList from "./../List";
import BizUtils from "@/utils/biz.js";
import { mapGetters } from "vuex";

export default {
  components: { BizList },
  data() {
    return {
      bizs: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    bizId() {
      if (this.biz) return this.biz._id;
      return null;
    },
  },
  watch: {
    bizId(val, oVal) {
      if (val != oVal) {
        this.load();
      }
    },
  },
  methods: {
    async load() {
      if (!this.bizId || !this.biz.address || !this.biz.address.geometry)
        return;
      if (!navigator || !navigator.geolocation) return;
      const params = {
        criteria: {
          _id: { $ne: this.bizId },
          "address.geometry.lat": {
            $gte: this.biz.address.geometry.lat - 0.1,
            $lte: this.biz.address.geometry.lat + 0.1,
          },
          "address.geometry.lng": {
            $gte: this.biz.address.geometry.lng - 0.1,
            $lte: this.biz.address.geometry.lng + 0.1,
          },
          "status.byAdmin": 1,
          "status.byUser": 1,
        },
        select: "name logo url address cates schedule stats orderType",
      };
      const res = await this.axios.post("/bizs/list", params);
      // get distance and assign 3 closest locations
      this.loading = false;
      navigator.geolocation.getCurrentPosition((position) => {
        const data = _.map(res.data, (o) => {
          o.distance = BizUtils.geoDistance(o.address.geometry, {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          return o;
        });
        this.bizs = _.chain(data).sortBy("distance").first(3).value();
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.title-style {
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  padding-bottom: 4px;
  border-bottom: solid 3px #FF6D32;
  margin-bottom: 20px
}
</style>
