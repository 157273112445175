function addressStreet(address) {
  if (!address) return ''
  const lines = []
  if (address.line1) {
    lines.push(address.line1)
  }
  if (address.line2) {
    lines.push(address.line2)
  }
  return lines.join(' ')
}

export default addressStreet