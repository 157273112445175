<template lang="pug">
tr
  td
    span(v-if="service_fee_value > 0")
      span Tax and fees
      v-dialog(width="400")
        template(v-slot:activator="{ on }")
          v-icon.ml-2(v-on="on", small, color="grey") mdi-information
        v-card
          v-toolbar(flat)
            .subtitle-2 Tax and fees
          v-simple-table
            tbody
              tr
                td Taxes
                td.text-right {{ cartOrder.payment.tax | currency }}
              tr(v-if="service_fee_value > 0")
                td
                  div Service fee
                  .caption.text--secondary Charged by Selflane to facilitate the payment and service.
                td.text-right {{ service_fee_value | currency }}

    span(v-else) Tax
  td.text-right {{ taxNService | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["cartOrder", "cartServiceFeeRate", "cartMinServiceFee"]),
    service_fee_value() {
      if (!this.cartOrder) return 0;
      const found = _.find(
        this.cartOrder.payment.selflane.items,
        (o) => o.name == "service_fee"
      );
      if (found) return found.value;
      return 0;
    },
    taxNService() {
      if (!this.cartOrder) return 0;
      return this.cartOrder.payment.tax + this.service_fee_value;
    },
  },
};
</script>
