<template lang="pug">
v-card(v-if="dish", @click="select", outlined, height="100%")
  .d-flex.flex-row.align-center 
    .dish-info
      .dish-name {{ dish.name }}
      .dish-description {{ dish.description }}
      .d-flex.flex-row.align-center 
        .dish-name {{ dish.price + dish.onlineUpcharge | currency }}
        .dish-name.red--text.ml-3(v-if="dish.pctOff > 0") {{ dish.pctOff }}% off
        DishTags(:item="dish")
        .dish-description.ml-3(v-if="dish.servings && dish.servings > 1") Serves {{ dish.servings }}
    .dish-image(v-if="!!dish.picLink")
      v-img(:src="mediaHost + dish.picLink", height="100%")
</template>

<script>
import DishTags from "./DishTags";

export default {
  name: "MenuDishCell",
  props: ["dish"],
  components: { DishTags },
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  methods: {
    select() {
      this.$emit("select");
    },
  },
};
</script>

<style scoped>
.dish-info {
  flex: 1;
  overflow: hidden;
  padding: 12px 12px 12px 12px;
}
.dish-image {
  flex: 0 0 95px;
  height: 95px;
  overflow: hidden;
}
.dish-name {
  font-weight: 500;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dish-description {
  font-size: 14px;
  color: rgba(28, 31, 52, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
  margin-bottom: 2px;
}
</style>
