import _ from 'underscore'

const state = {
  punchcards: []
}

const getters = {
  punchcards: state => state.punchcards
}

const actions = {
  setPunchcards: ({
    commit
  }, punchcards) => {
    commit('setPunchcards', punchcards)
  },
  updatePunchcard: ({
    commit
  }, punchcard) => {
    commit('updatePunchcard', punchcard)
  },
  resetPunchcards: ({
    commit
  }) => {
    commit('resetPunchcards')
  }
}

const mutations = {
  setPunchcards(state, punchcards) {
    state.punchcards = punchcards
  },
  updatePunchcard(state, punchcard) {
    state.punchcards = _.map(state.punchcards, o => {
      if (o._id === punchcard._id) return punchcard
      return o
    })
  },
  resetPunchcards(state) {
    state.punchcards = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}