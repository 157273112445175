import _ from 'underscore'

const state = {
  reservations: []
}

const getters = {
  reservations: state => state.reservations
}

const actions = {
  setReservations: ({ commit }, reservations) => { commit('setReservations', reservations) },
  updateReservation: ({ commit }, reservation) => { commit('updateReservation', reservation) }
}

const mutations = {
  setReservations (state, reservations) {
    state.reservations = reservations
  },
  updateReservation (state, reservation) {
    state.reservations = _.map(state.reservations, o => {
      if (o._id === reservation._id) return reservation
      return o
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
