<template lang="pug">
div
  List(:cards="openCards")
  div
    .title.my-3(v-if="sentCards && sentCards.length") To be claimed cards
    List(:cards="sentCards")
  GiftCardDetail
  EmptyView(v-if="isEmpty", text="Gift Card Wallet is Empty")
</template>

<script>
import _ from "underscore";
import List from "./List";
import GiftCardDetail from "./Detail/index";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { List, GiftCardDetail },
  data() {
    return { bizs: [] };
  },
  computed: {
    ...mapGetters(["giftCards"]),
    isEmpty() {
      return !this.giftCards || this.giftCards.length == 0;
    },
    openCards() {
      return _.filter(this.giftCards, (o) => !o.to);
    },
    sentCards() {
      return _.filter(this.giftCards, (o) => o.to && o.to.length);
    },
  },
  methods: {
    ...mapActions(["setGiftCards", "setBizAccounts"]),
    async loadBizAccounts(ids) {
      if (!ids || !ids.length) return;
      const params = { criteria: { _id: { $in: ids } }, select: "name url" };
      const res = await this.axios.post("/bizs/list", params);
      this.setBizAccounts(res.data);
    },
    async load() {
      this.setGiftCards([]);
      if (!this.$auth.user()) return;
      const userId = this.$auth.user()._id;
      const params = { criteria: { owner: userId, balance: { $gt: 0 } } };
      const res = await this.axios.post("/giftCard/list", params);
      const ids = _.chain(res.data).pluck("issuer").uniq().value();
      this.loadBizAccounts(ids);
      this.setGiftCards(res.data);
    },
  },
  mounted() {
    this.load();
  },
};
</script>