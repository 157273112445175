<template lang="pug">
v-dialog(v-model="dialog", width="300")
  template(v-slot:activator="{ on }")
    v-btn(icon, v-on="on", color="secondary", small, @click="open")
      v-icon(small) mdi-pencil
  v-card
    v-card-title
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          label="Phone",
          v-model="phone",
          :counter="30",
          autofocus,
          ref="focus"
        )
        v-btn(
          block,
          type="submit",
          color="secondary",
          :loading="loading",
          v-mask="phoneMask"
        ) save
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["ticket"],
  data() {
    return {
      dialog: false,
      phone: "",
      loading: false,
      phoneMask: "(###) ###-####",
    };
  },
  methods: {
    ...mapActions(["updateEventTicket"]),
    open() {
      this.phone = this.ticket.user.phone || "";
    },
    async submit() {
      if (!this.ticket) return;
      this.loading = true;
      const params = {
        ticket_id: this.ticket._id,
        action: { $set: { "user.phone": this.phone } },
      };
      try {
        const res = await this.axios.post("/event/tickets/update", params);
        this.updateEventTicket(res.data);
        this.dialog = false;
      } catch (err) {
        //
      }
      this.loading = false;
    },
  },
};
</script>
