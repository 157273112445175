<template lang="pug">
.main-container
  RaisedCountUp(v-if="event")
  TimeCountDown
  Message
  QRCode
</template>

<script>
import TimeCountDown from "./TimeCountDown";
import RaisedCountUp from "./RaisedCountUp";
import Message from "./Message.vue";
import QRCode from "./QRCode";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { TimeCountDown, RaisedCountUp, Message, QRCode },
  data() {
    return {
      event_id: "6273cec75fb61ad63bd1f6c5",
    };
  },
  computed: {
    ...mapGetters(["event"]),
  },
  methods: {
    ...mapActions(["setEvent"]),
    async loadEvent() {
      if (!this.event_id) return;
      const params = { event_id: this.event_id };
      try {
        const res = await this.axios.post("/event/entries/retrieve", params);
        this.setEvent(res.data);
      } catch (err) {
        //
      }
    },
  },
  mounted() {
    this.loadEvent();
  },
};
</script>

<style scoped>
.main-container {
  position: relative; 
  height: 100%;
}
.main-container::before {
  content: "";
  background-image: url("https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2FHispanoChamber%2FIMG_0559.jpeg?alt=media&token=2e9b1c86-131c-4a93-8f29-40da3e6a2d3f");
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.9;
}
</style>