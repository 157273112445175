<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between.flex-wrap
    .sl-title Wallet
    v-spacer
    v-btn.text-capitalize.mx-3(
      v-for="(item, index) in items",
      :key="index",
      small,
      active-class="accent",
      :active="item.url === $route.path",
      :to="item.url",
      rounded,
      depressed
    ) {{ item.title }}
  v-divider.my-2
  router-view
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Member", url: "/user/wallet/member" },
        { title: "Coupon", url: "/user/wallet/coupon" },
        { title: "Gift Card", url: "/user/wallet/gift" },
        { title: "Event", url: "/user/wallet/event" },
      ],
    };
  },
};
</script>
