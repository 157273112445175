<template lang="pug">
div
  .mb-3(v-for="(item, index) in items", :key="item._id")
    Cell(:review="item", :disableEdit="disableEdit")
    .sl-dash-divider(v-if="index + 1 != items.length")
  .text-center(v-if="length && length > 1")
    v-pagination(
      :length="length",
      v-model="page",
      v-if="length",
      circle,
      color="grey"
    )
  EmptyView(v-if="reviews.length == 0", text="No Reviews Yet")
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import LinearProgress from "./../basic/LinearProgress";
import Cell from "./Cell";

export default {
  components: { LinearProgress, Cell },
  props: ["disableEdit"],
  data() {
    return {
      page: 1,
      pageMax: 5,
    };
  },
  computed: {
    ...mapGetters(["reviews"]),
    items() {
      const start = (this.page - 1) * this.pageMax;
      return _.chain(this.reviews).rest(start).first(this.pageMax).value();
    },
    length() {
      return Math.ceil(this.reviews.length / this.pageMax);
    },
  },
};
</script>
