<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between
    .subtitle-2 Select Payment Method
    CreateSource
  v-simple-table.my-3(v-if="stripeSources && stripeSources.length")
    tbody
      tr(
        v-for="(item, index) in stripeSources",
        :key="index",
        @click="select(item)",
        role="button"
      )
        td
          v-icon.mr-2(
            v-if="selected && selected.id == item.id",
            color="secondary"
          ) mdi-check-circle-outline
          span {{ item.brand }} ...{{ item.last4 }}
        td {{ item.name }}
        td.text-right {{ item.exp_month }}/{{ item.exp_year }}
</template>

<script>
import CreateSource from "@/components/User/Payments/CreateSource";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { CreateSource },
  data() {
    return {
      dialog: false,
      selected: null,
    };
  },
  computed: {
    ...mapGetters(["stripeSources"]),
  },
  methods: {
    ...mapActions(["setStripeSources"]),
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      const params = {
        userId: user._id,
      };
      const res = await this.axios.post("/stripe/sources/list", params);
      this.setStripeSources(res.data);
    },
    select(item) {
      this.selected = item;
      this.$emit("select", item);
    },
  },
  mounted() {
    this.setStripeSources([]);
    this.load();
  },
};
</script>
