import { render, staticRenderFns } from "./InfoBlock.vue?vue&type=template&id=36fb6eee&scoped=true&lang=pug&"
import script from "./InfoBlock.vue?vue&type=script&lang=js&"
export * from "./InfoBlock.vue?vue&type=script&lang=js&"
import style0 from "./InfoBlock.vue?vue&type=style&index=0&id=36fb6eee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36fb6eee",
  null
  
)

export default component.exports