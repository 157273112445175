<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  Detail(:order="order", :showAction="showAction")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Detail from "./Detail/index";

export default {
  components: { Detail },
  data() {
    return {
      order: null,
      dialog: false,
      loading: false,
      showAction: true,
    };
  },
  methods: {
    async load(orderId) {
      if (!orderId) return;
      this.dialog = true;
      const params = { criteria: { _id: orderId } };
      try {
        const res = await this.axios.post("/orders/retrieve", params);
        this.order = res.data;
      } catch (err) {
        alert(err.response.data);
      }
    },
  },
  mounted() {
    EventBus.$on("show-order-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-order-detail");
  },
};
</script>
