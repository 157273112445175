<template lang="pug">
v-container
  Detail(:order="order")
</template>

<script>
import Detail from "@/components/Order/Detail/index";

export default {
  props: ["id"],
  components: { Detail },
  data() {
    return {
      order: null,
      loading: false,
    };
  },
  methods: {
    async load() {
      if (!this.id) return;
      this.loading = true;
      const params = { criteria: { _id: this.id } };
      try {
        const res = await this.axios.post("/orders/retrieve", params);
        this.order = res.data;
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
  mounted() {
    this.load();
  },
};
</script>