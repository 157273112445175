<template lang="pug">
div(v-if="hasPlan")
  .d-flex.flex-row.align-center
    #influencer-code
      v-form(@submit.prevent="lookup()")
        v-text-field(v-model="code", label="Promo Code", @blur="lookup()")
    .success--text {{ planDescription }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BizUtils from "@/utils/biz.js";

export default {
  data() {
    return { code: "" };
  },
  computed: {
    ...mapGetters([
      "cartOrder",
      "biz",
      "influencerPlans",
      "influencers",
      "influencerCode",
    ]),
    hasPlan() {
      return this.influencerPlans && this.influencerPlans.length;
    },
    qualifiedPlan() {
      let result = BizUtils.getInfluencerPlan(
        this.influencerPlans,
        this.influencers,
        this.influencerCode
      );
      return result.plan;
    },
    planDescription() {
      if (!this.qualifiedPlan) return null;
      let text = "";
      if (this.qualifiedPlan.type == "pct") {
        text += "Enjoy " + this.qualifiedPlan.pct + "% off ";
      } else {
        text += "Enjoy $" + this.qualifiedPlan.fix + " off ";
      }
      if (this.qualifiedPlan.minimum) {
        text += " with a min. purchase of $" + this.qualifiedPlan.minimum;
      }
      if (this.qualifiedPlan.type == "pct" && this.qualifiedPlan.limit) {
        text += " up to $" + this.qualifiedPlan.limit;
      }
      text += ".";
      return text;
    },
  },
  methods: {
    ...mapActions([
      "setInfluencerPlans",
      "setInfluencers",
      "setInfluencerCode",
      "updateCartPayment",
    ]),
    async loadPlans() {
      this.setInfluencerPlans();
      if (!this.biz) return;
      let params = { criteria: { biz: this.biz._id } };
      const res = await this.axios.post("/influencer/plans/list", params);
      this.setInfluencerPlans(res.data);
      this.lookup();
    },
    async lookup() {
      if (
        this.code &&
        this.code.length > 0 &&
        this.code == this.influencerCode
      ) {
        return;
      }
      this.setInfluencerCode(this.code);
      this.setInfluencers();
      if (!this.code) {
        this.updateCartPayment();
        return;
      }
      const params = {
        criteria: {
          code: { $regex: "^" + this.code + "$", $options: "i" },
          verified: true,
        },
        select: "name code verified",
      };
      const res = await this.axios.post("/influencer/accounts/list", params);
      this.setInfluencers(res.data);
      this.updateCartPayment();
    },
  },
  mounted() {
    this.loadPlans();
    this.code = this.influencerCode;
  },
};
</script>

<style scoped>
#influencer-code {
  width: 130px;
  padding-right: 20px;
}
</style>
