<template lang="pug">
v-alert.my-3(v-if="hasAlcohol", type="warning", dense) A person with a valid ID and age over 21 must present in receiving the order.
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["cartOrder"]),
    hasAlcohol() {
      const found = _.find(
        this.cartOrder.summary,
        (o) => o.classification == 3
      );
      return found != undefined;
    },
  },
};
</script>
