<template lang="pug">
.text-center.my-3
  div Reservation should be made {{ leadtime | leadtime }} ahead
  span.caption Set by the business
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz"]),
    leadtime() {
      return (
        this.biz &&
        this.biz.orderType &&
        this.biz.orderType.reservation &&
        this.biz.orderType.reservation.beforehand
      );
    },
  },
};
</script>
