<template lang="pug">
v-card
  LinearProgress(:on="loading")
  table(v-if="summary", width="100%")
    thead.blue.lighten-4
      tr
        th.blue--text.py-2
          .d-flex.flex-row.align-center.justify-space-between
            v-btn(icon, small, @click="handlePrevious()")
              v-icon mdi-chevron-left
            span {{ calendarDate }}
            v-btn(icon, small, @click="handleNext()")
              v-icon mdi-chevron-right
        th
          span Mon
          br
          .hour {{ summary.mon }} Hrs
        th
          span Tue
          br
          .hour {{ summary.tue }} Hrs
        th
          span Wed
          br
          .hour {{ summary.wed }} Hrs
        th
          span Thu
          br
          .hour {{ summary.thu }} Hrs
        th
          span Fri
          br
          .hour {{ summary.fri }} Hrs
        th
          span Sat
          br
          .hour {{ summary.sat }} Hrs
        th
          span Sun
          br
          .hour {{ summary.sun }} Hrs
    tbody
      tr(v-for="(item, index) in weekly", :key="index")
        td
          v-col(align="center")
            .body-2 {{ item.name }}
            .hour {{ item.total }} Hrs
        td
          v-col
            v-btn(
              dark,
              depressed,
              small,
              color="blue",
              v-for="(o, oindex) in item.mon",
              :key="oindex"
            ) {{ o.startHH | minutesToTime }} - {{ o.endHH | minutesToTime }}
        td
          v-col
            v-btn(
              dark,
              depressed,
              small,
              color="blue",
              v-for="(o, oindex) in item.tue",
              :key="oindex"
            ) {{ o.startHH | minutesToTime }} - {{ o.endHH | minutesToTime }}
        td
          v-col
            v-btn(
              dark,
              depressed,
              small,
              color="blue",
              v-for="(o, oindex) in item.wed",
              :key="oindex"
            ) {{ o.startHH | minutesToTime }} - {{ o.endHH | minutesToTime }}
        td
          v-col
            v-btn(
              dark,
              depressed,
              small,
              color="blue",
              v-for="(o, oindex) in item.thu",
              :key="oindex"
            ) {{ o.startHH | minutesToTime }} - {{ o.endHH | minutesToTime }}
        td
          v-col
            v-btn(
              dark,
              depressed,
              small,
              color="blue",
              v-for="(o, oindex) in item.fri",
              :key="oindex"
            ) {{ o.startHH | minutesToTime }} - {{ o.endHH | minutesToTime }}
        td
          v-col
            v-btn(
              dark,
              depressed,
              small,
              color="blue",
              v-for="(o, oindex) in item.sat",
              :key="oindex"
            ) {{ o.startHH | minutesToTime }} - {{ o.endHH | minutesToTime }}
        td
          v-col
            v-btn(
              dark,
              depressed,
              small,
              color="blue",
              v-for="(o, oindex) in item.sun",
              :key="oindex"
            ) {{ o.startHH | minutesToTime }} - {{ o.endHH | minutesToTime }}
    tfoot.blue.lighten-4
      tr
        th.py-2.hour(colspan="8") {{ summary.total }} Hrs total
</template>

<script>
import LinearProgress from "./../basic/LinearProgress";
import moment from "moment";
import _ from "underscore";

export default {
  components: { LinearProgress },
  data() {
    return {
      loading: false,
      calendarStart: parseInt(moment().startOf("isoWeek").format("YYYYMMDD")),
      weekly: null,
      summary: null,
    };
  },
  computed: {
    calendarDate() {
      if (this.calendarStart)
        return moment(this.calendarStart, "YYYYMMDD").format("MMM D");
      else return "";
    },
  },
  methods: {
    handleNext() {
      this.calendarStart = moment(this.calendarStart, "YYYYMMDD")
        .add(1, "w")
        .format("YYYYMMDD");
      this.load();
    },
    handlePrevious() {
      this.calendarStart = moment(this.calendarStart, "YYYYMMDD")
        .subtract(1, "w")
        .format("YYYYMMDD");
      this.load();
    },
    summarizeWeekly() {
      const data = this.weekly;
      this.summary = {
        mon: getTotalHrs(data, "mon"),
        tue: getTotalHrs(data, "tue"),
        wed: getTotalHrs(data, "wed"),
        thu: getTotalHrs(data, "thu"),
        fri: getTotalHrs(data, "fri"),
        sat: getTotalHrs(data, "sat"),
        sun: getTotalHrs(data, "sun"),
      };
      this.summary.total =
        this.summary.mon +
        this.summary.tue +
        this.summary.wed +
        this.summary.thu +
        this.summary.fri +
        this.summary.sat +
        this.summary.sun;
      function getTotalHrs(data, day) {
        const result = _.chain(data)
          .pluck(day)
          .flatten()
          .reduce((memo, o) => {
            return memo + (o.endHH - o.startHH);
          }, 0)
          .value();
        return Math.round((result / 60) * 10) / 10;
      }
    },
    load() {
      const user = this.$auth.user();
      if (!user || !user.email) return;
      this.loading = true;
      const options = {
        email: user.email,
        startDay: this.calendarStart,
      };
      this.axios
        .post("/serverShift/weeklyByServer", options)
        .then((response) => {
          this.loading = false;
          this.weekly = response.data;
          this.summarizeWeekly();
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}
tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
tbody button {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 1px;
  margin-right: 1px;
}
.hour {
  font-weight: 500;
  color: #757575;
}
</style>