<template lang="pug">
v-dialog(v-model="dialog", persistent, width="450", max-width="100%")
  v-card
    v-toolbar(color="success", flat, dark)
      .subtitle-2 Thanks You!
      v-spacer
      v-btn.text-capitalize(outlined, to="/user/orders", rounded) Go to My Orders
    v-card-text.text-center
      div(v-if="!isFavored")
        .subtitle-2.my-3 Like Me
        v-btn.mb-3.text-capitalize(
          @click="toggleLike()",
          color="grey darken-2",
          outlined,
          rounded,
          small
        )
          v-icon(left, small) mdi-heart
          span {{ biz.stats.fav }} likes
        .body-2.mb-3 Add this restaurant to your favorites to find it faster.
      div
        .subtitle-2.my-3 Share Me
        .my-3
          ShareNetwork.ma-3(
            network="facebook",
            :url="url",
            :title="title",
            hashtags="#selflane"
          )
            i.fab.fah.fa-facebook.fa-2x(style="color: #3b5998")
          ShareNetwork.ma-3(
            network="twitter",
            :url="url",
            :title="title",
            hashtags="#selflane"
          )
            i.fab.fah.fa-twitter.fa-2x(style="color: #38a1f3")
        .body-2.mb-3 Share this restaurant with your friends. Let them explore the food you enjoy.
        .subtitle-2.my-3 Review Me
        .body-2 Write up a review. Our system requires all reviews with associated fulfilled orders. Let everyone know your mouthwatering stories.
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    url() {
      if (!this.biz) return "";
      return "https://selflane.com/bizs/" + this.biz.url;
    },
    title() {
      if (!this.biz) return "";
      return this.biz.name;
    },
    isFavored() {
      if (!this.$auth.check() || !this.biz) return false;
      const bizId = this.biz._id;
      const preference = this.$auth.user().preference;
      return preference.myfavs.indexOf(bizId) !== -1;
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    toggleLike() {
      if (!this.biz) return;
      if (!this.$auth.check()) return;
      if (this.isFavored) return;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      let bizData = {
        criteria: { _id: bizId },
        action: { $inc: { "stats.fav": 1 } },
      };
      let userData = {
        criteria: { _id: userId },
        action: { $addToSet: { "preference.myfavs": bizId } },
      };
      this.axios.post("/bizs/update", bizData).then((response) => {
        this.biz.stats = response.data.stats;
      });
      this.axios.post("/users/update", userData).then((response) => {
        this.$auth.user(response.data);
      });
    },
  },
  mounted() {
    EventBus.$on("show-order-submit-success", this.open);
  },
  destroyed() {
    EventBus.$off("show-order-submit-success");
  },
};
</script>
