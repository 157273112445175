<template lang="pug">
v-container
  v-row(justify="center")
    v-card.text-center(max-width="500", width="90%", outlined)
      v-card-text
        v-icon(color="success", x-large) mdi-check-circle-outline
        .sl-title Member Benefits Unsubscribe Successful
        v-btn.text-capitalize.mt-10(
          to="/user/wallet/member",
          text,
          color="secondary"
        ) Manage Membership
</template>

<script>
export default {
  props: ["member_id"],
  data() {
    return {
      member: null,
    };
  },
  methods: {
    async unsubscribe() {
      if (!this.member_id) return;
      const params = {
        memberId: this.member_id,
      };
      const res = await this.axios.post("/member/unsubscribe", params);
      this.member = res.response;
    },
  },
  mounted() {
    this.unsubscribe();
  },
};
</script>