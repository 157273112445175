<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-toolbar(flat, dense) 
      .subtitle-2 Recent Member Activities
    v-divider
    v-tabs
      v-tab Redeem Activities
      v-tab Earn Activities
      v-tab-item
        Table(:items="redeems")
      v-tab-item
        Table(:items="earns")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Table from "./Table";

export default {
  components: { Table },
  data() {
    return {
      dialog: false,
      redeems: [],
      earns: [],
    };
  },
  methods: {
    load(memberId) {
      if (!memberId) return;
      this.dialog = true;
      const options = {
        criteria: {
          member: memberId,
        },
        select: "amount",
        limit: 10,
      };
      this.axios.post("/pointredeem/list", options).then((response) => {
        this.redeems = response.data;
      });
      this.axios.post("/pointearn/list", options).then((response) => {
        this.earns = response.data;
      });
    },
  },
  mounted() {
    EventBus.$on("show-member-activities", this.load);
  },
  destroyed() {
    EventBus.$off("show-member-activities");
  },
};
</script>
