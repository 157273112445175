<template lang="pug">
.event-container
  v-row(wrap, no-gutters)
    v-col(cols="12", md="8", order="2", order-md="1")
      v-card-text.detail-section
        div(v-html="event.detail")
    v-col(cols="12", md="4", order="1", order-md="2")
      v-card-text.detail-section-right
        .section-title Date and time
        .section-description(v-for="(item, index) in time_items", :key="index") {{ item }}
        .mt-5
        .section-title Location
        .section-description {{ event.address.name }}
        .section-description {{ event.address | address }}
        .mt-5
        .section-title Refund Policy
        .section-description No Refunds
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz", "event"]),
    begin_date() {
      return moment(this.event.event_time.begin).format("ddd, MMM DD, YYYY");
    },
    begin_time() {
      return moment(this.event.event_time.begin).format("h:mm A");
    },
    end_date() {
      return moment(this.event.event_time.end).format("ddd, MMM DD, YYYY");
    },
    end_time() {
      return moment(this.event.event_time.end).format("h:mm A");
    },
    time_items() {
      if (this.begin_date == this.end_date)
        return [
          this.begin_date + " " + this.begin_time + " - " + this.end_time,
        ];
      return [
        this.begin_date + " " + this.begin_time,
        "to",
        this.end_date + " " + this.end_time,
      ];
    },
  },
};
</script>

<style scoped>
.detail-section {
  padding: 20px;
}
.detail-section-right {
  background-color: rgba(238, 237, 237,0.7);
  padding: 20px;
  height: 100%;
}
.section-title {
  font-size: 15px;
  font-weight: 600;
  color: #402F5A;
}
.section-description {
  font-size: 14px;
  font-weight: 400;
  color: #402F5A;
}
</style>