import _ from 'underscore'

const state = {
  memberAccounts: []
}

const getters = {
  memberAccounts: state => state.memberAccounts
}

const actions = {
  setMemberAccounts: ({
    commit
  }, data) => {
    commit('setMemberAccounts', data)
  },
  updateMemberAccount: ({
    commit
  }, data) => {
    commit('updateMemberAccount', data)
  },
  addMemberAccount: ({
    commit
  }, data) => {
    commit('addMemberAccount', data)
  },
  removeMemberAccount: ({
    commit
  }, data) => {
    commit('removeMemberAccount', data)
  }
}

const mutations = {
  setMemberAccounts(state, data) {
    state.memberAccounts = data
  },
  updateMemberAccount(state, data) {
    state.memberAccounts = _.map(state.memberAccounts, o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addMemberAccount(state, data) {
    state.memberAccounts.push(data)
  },
  removeMemberAccount(state, data) {
    state.memberAccounts = _.reject(state.memberAccounts, o => {
      return (o._id === data._id)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}