
<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between
    .sl-title Reviews
    YearSelector(@select="load")
  v-divider.my-2
  List
  EditReview
</template>

<script>
import List from "@/components/Review/List";
import EditReview from "@/components/Review/Edit";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: { List, EditReview },
  methods: {
    ...mapActions(["setReviews"]),
    async load(year) {
      const user = this.$auth.user();
      if (!user || !year) return;
      const begin = moment().year(year).startOf("year").unix() * 1000;
      const end = moment().year(year).endOf("year").unix() * 1000;
      const params = {
        criteria: { "author.id": user._id, created: { $gte: begin, $lt: end } },
      };
      try {
        const res = await this.axios.post("/reviews/list", params);
        this.setReviews(res.data);
      } catch (err) {
        this.setReviews([]);
      }
    },
  },
  mounted() {
    this.setReviews([]);
  },
};
</script>
