<template lang="pug">
div
  Label(icon="mdi-account", title="Name")
  v-text-field(
    v-model="cartOrder.customer.name",
    dense,
    placeholder="Enter Name",
    hide-details
  )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["cartOrder"]),
  },
};
</script>
