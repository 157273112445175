<template lang="pug">
v-container
  TopBar
  v-col(align="center")
    v-card(v-if="canReserve", max-width="700")
      v-card-text
        v-form(@submit.prevent="findTables")
          v-row(wrap)
            v-col(cols="6", sm="3")
              v-menu(
                transition="scale-transition",
                offset-y,
                :nudge-right="40",
                max-width="290px",
                min-width="290px"
              )
                template(v-slot:activator="{ on }")
                  v-text-field(
                    v-model="date",
                    label="Date",
                    readonly,
                    hide-details,
                    v-on="on"
                  )
                v-date-picker(v-model="date", :min="minDate", :max="maxDate")
            v-col(cols="6", sm="3")
              v-text-field(label="Name", v-model="name")
            v-col(cols="6", sm="3")
              v-text-field(
                label="Phone",
                v-model="phone",
                v-mask="\"(###) ###-####\""
              )
            v-col(cols="6", sm="3")
              v-select(
                label="Party Size",
                v-model="people",
                :items="peopleRange"
              )
          v-btn.text-notransform(
            block,
            color="primary",
            type="submit",
            :loading="finding",
            :disabled="$v.$invalid"
          ) Find a Table
    v-card#reserveTimeSlot.my-5(
      v-if="timeslots && timeslots.length",
      max-width="700"
    )
      v-card-text
        .text-center Please select a time to reserve a table.
        v-form.ma-3(@submit.prevent="save")
          v-row.mb-3(wrap, justify-center)
            v-col(
              cols="6",
              sm="3",
              v-for="(item, index) in timeslots",
              :key="index"
            )
              v-btn(
                text,
                :class="{ secondary: selectedMinute === item }",
                @click="selectedMinute = item"
              ) {{ item | minutesToTime }}
          v-col(align="center")
            div We can copy the reservation email to the included participants.
            v-card.my-3(
              width="300",
              v-if="participants && participants.length"
            )
              v-list(dense)
                v-list-item(
                  v-for="(item, index) in participants",
                  :key="index"
                )
                  v-list-item-content {{ item }}
                  v-list-item-action
                    v-icon(@click.stop="removeEmail(index)", small) mdi-delete
            v-btn(outlined, color="grey darken-3", @click.stop="openEmail")
              v-icon(left) mdi-plus
              span.text-lowercase participants
          v-btn.text-notransform(
            block,
            color="secondary",
            type="submit",
            :loading="submitting",
            :disabled="!selectedMinute || $v.$invalid"
          ) Submit Reservation
  BottomNote
  EmailForm(:open="dialog", @save="addEmail", @close="handleCloseEmail")
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "underscore";
import TopBar from "./TopBar";
import BottomNote from "./BottomNote";
import EmailForm from "@/components/basic/EmailForm";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  components: { TopBar, BottomNote, EmailForm },
  mixins: [validationMixin],
  validations: {
    name: { required },
    phone: { required },
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      minDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().add(1, "months").format("YYYY-MM-DD"),
      peopleRange: [2],
      name: "",
      phone: "",
      people: 2,
      finding: false,
      submitting: false,
      timeslots: [],
      selectedMinute: null,
      rsvt: null,
      dialog: false,
      inputEmail: "",
      participants: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    canReserve() {
      return (
        this.biz &&
        this.biz.orderType &&
        this.biz.orderType.reservation &&
        this.biz.orderType.reservation.status
      );
    },
  },
  methods: {
    openEmail() {
      this.dialog = true;
    },
    handleCloseEmail() {
      this.dialog = false;
    },
    addEmail(email) {
      this.dialog = false;
      this.participants.push(email);
    },
    removeEmail(index) {
      this.participants.splice(index, 1);
    },
    setup() {
      let minCapacity = 2;
      minCapacity =
        _.chain(this.biz.tables).pluck("min_capacity").min().value() || 2;
      minCapacity = Math.max(2, minCapacity);
      let maxCapacity = 2;
      maxCapacity =
        _.chain(this.biz.tables).pluck("max_capacity").max().value() || 2;
      this.peopleRange = _.range(minCapacity, maxCapacity, 1);
      const user = this.$auth.user();
      this.name = user.name.first + " " + user.name.last;
      const firstPhone = _.first(user.phones);
      if (firstPhone) this.phone = firstPhone.number;
    },
    async findTables() {
      this.finding = true;
      this.selectedMinute = null;
      const data = {
        bizId: this.biz._id,
        date: moment(this.date).format("YYYY/M/D"),
        total_people: this.people,
        duration: 1,
      };
      try {
        const res = await this.axios.post("/reservations/findTables", data);
        this.timeslots = res.data.timeslots;
        if (!this.timeslots || this.timeslots.length === 0)
          alert("No table is found for the selected date.");
        else this.$vuetify.goTo("reserveTimeSlot");
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.finding = false;
    },
    async save() {
      this.submitting = true;
      if (!this.biz) return;
      if (!this.$auth.user()) return;
      const seller = {
        id: this.biz._id,
        url: this.biz.url,
        name: this.biz.name,
        address: this.biz.address,
      };
      const user = this.$auth.user();
      const customer = {
        id: user._id,
        name: this.name,
        email: user.email,
        phone: this.phone.replace(/[^\d]/g, ""),
        participants: _.compact(this.participants),
      };
      const orderer = { type: 0 };
      const data = {
        seller: seller,
        customer: customer,
        orderer: orderer,
        total_people: this.people,
        duration: 1,
        selectedTime: {
          date: this.date,
          minute: this.selectedMinute,
        },
      };
      try {
        await this.axios.post("/reservations/create", data);
        this.$router.push("/user/reservations");
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.submitting = false;
    },
  },
  mounted() {
    this.setup();
  },
};
</script>
