<template lang="pug">
v-list-item(:to="'/bizs/' + item.url", @mouseover="handleHover(item)")
  v-list-item-content
    v-list-item-title {{ name }}
    v-list-item-subtitle
      span(v-if="item.address") 
        span.mr-1(v-if="item.industry != 4") {{ item.address.line1 }},
        span {{ item.address.city }}
      span.ml-3(v-if="distance") {{ distance | readableDistance(unit) }}
  v-list-item-action
    v-icon mdi-chevron-right
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChainBizItem",
  props: ["item", "distance"],
  computed: {
    ...mapGetters(["biz"]),
    name() {
      if (this.item.short_name && this.item.short_name.length)
        return this.item.short_name;
      else return this.item.name;
    },
    unit() {
      if (this.item && this.item.address && this.item.address.country == "CA") {
        return "km";
      }
      return "mi.";
    },
  },
  methods: {
    handleHover() {
      this.$emit("hover", this.item);
    },
  },
};
</script>
